import React, { Component } from 'react'
class Alert extends Component {
    componentWillUnmount() {
        this.props.onClose()
    }
    render() {
        const { children, onClose, sucessMessage, failureMessage } = this.props

        const className = failureMessage ? 'alert__failure fadeinup' : 'alert__success fadeinup'
        if (!children && !failureMessage && !sucessMessage) return null
        setTimeout(onClose, 8000)
        return (
            <div className={className} style={{ animationDelay: '0.1s' }}>
                <span className="alert__close" onClick={onClose}>
                    &times;
                </span>
                <h3 className="alert__title">{children || failureMessage || sucessMessage}</h3>
                <p className="alert__message" />
            </div>
        )
    }
}
export default Alert
