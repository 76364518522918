import React from 'react'
import { Provider } from 'react-redux'
import throttle from 'lodash/throttle'
import { createBrowserHistory } from 'history'
import './assets/css/admin.css'
import './assets/css/alert.css'
import './assets/css/form.css'
import './assets/css/header.css'
import './assets/css/index.css'
import './assets/css/main.css'
import './assets/css/modal.css'
import './assets/css/reset.css'
import './assets/css/tab.css'
import './assets/css/table.css'
import './assets/css/questionsTable.css'
import 'react-table/react-table.css'
import './assets/css/home.css'
import './assets/css/card.css'
import './assets/css/sortableTable.css'

import Routes from './Routes'

import { saveState } from './localStorage'
import { configureStore } from './store/configReducer'

const historyRoutes = createBrowserHistory()

export const store = configureStore(historyRoutes)
store.subscribe(
    throttle(() => {
        saveState()
    }, 1000)
)

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Routes history={historyRoutes} />
            </Provider>
        )
    }
}

export default App
