import * as Api from './api'

export const actionsTypes = {

    RESET_INSPECTION_ATTACHMENTS_LIST: 'RESET_INSPECTION_ATTACHMENTS_LIST',

    FETCH_INSPECTION_ATTACHMENTS_REQUEST: 'FETCH_INSPECTION_ATTACHMENTS_REQUEST',
    FETCH_INSPECTION_ATTACHMENTS_SUCCESS: 'FETCH_INSPECTION_ATTACHMENTS_SUCCESS',
    FETCH_INSPECTION_ATTACHMENTS_FAILURE: 'FETCH_INSPECTION_ATTACHMENTS_FAILURE',

    DELETE_INSPECTION_ATTACHMENT_REQUEST: 'DELETE_INSPECTION_ATTACHMENT_REQUEST',
    DELETE_INSPECTION_ATTACHMENT_SUCCESS: 'DELETE_INSPECTION_ATTACHMENT_SUCCESS',
    DELETE_INSPECTION_ATTACHMENT_FAILURE: 'DELETE_INSPECTION_ATTACHMENT_FAILURE',

    UPLOAD_INSPECTION_ATTACHMENT_REQUEST: 'UPLOAD_INSPECTION_ATTACHMENT_REQUEST',
    UPLOAD_INSPECTION_ATTACHMENT_SUCCESS: 'UPLOAD_INSPECTION_ATTACHMENT_SUCCESS',
    UPLOAD_INSPECTION_ATTACHMENT_FAILURE: 'UPLOAD_INSPECTION_ATTACHMENT_FAILURE'
}

export const fetchInspectionAttachments = inspectionId => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.FETCH_INSPECTION_ATTACHMENTS_REQUEST,
        inspectionId
    })

    return Api.fetchInspectionAttachments(inspectionId)
        .then(response => {
            dispatch({ type: actionsTypes.FETCH_INSPECTION_ATTACHMENTS_SUCCESS, attachments: response.data.inspectionAttachments })
        })
        .catch(error => {
            dispatch({ type: actionsTypes.FETCH_INSPECTION_ATTACHMENTS_FAILURE })
        })
}

export const deleteInspectionAttachment = (inspectionId, attachmentId) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.DELETE_INSPECTION_ATTACHMENT_REQUEST
    })
    return Api.deleteInspectionAttachment(inspectionId, attachmentId)
        .then(response => {
            dispatch({ type: actionsTypes.DELETE_INSPECTION_ATTACHMENT_SUCCESS })
            dispatch(fetchInspectionAttachments(inspectionId))
        })
        .catch(error => {
            dispatch({ type: actionsTypes.DELETE_INSPECTION_ATTACHMENT_FAILURE })
        })
}

export const uploadInspectionAttachment = (inspectionId, file, description) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.UPLOAD_INSPECTION_ATTACHMENT_REQUEST,
        inspectionId,
        description,
        file
    })
    return Api.uploadInspectionAttachment(inspectionId, file, description)
        .then(response => {
            dispatch({ type: actionsTypes.UPLOAD_INSPECTION_ATTACHMENT_SUCCESS })
            dispatch(fetchInspectionAttachments(inspectionId))
        })
        .catch(error => {
            let errorMsg = 'No se pudo subir el documento'
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = Object.values(error.response.data.errors)
                errorMsg = ''
                errors.map(err => {
                    errorMsg += err + '\n'
                    return err
                })
            }

            dispatch({ type: actionsTypes.UPLOAD_INSPECTION_ATTACHMENT_FAILURE, error: errorMsg })
        })
}

export const resetInspectionAttachmentsList = () => (dispatch) => {
    dispatch({ type: actionsTypes.RESET_INSPECTION_ATTACHMENTS_LIST })
}
