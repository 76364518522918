import { actionsTypes as actionsAssessmentQuestionnaireManagement } from './actions'
import { arrayMove } from 'react-sortable-hoc'

const initState = {
    loading: false,
    errors: null,
    failureMessage: null,
    assessmentQuestionnaireData: null,
    items: [],
    scores: []
}

export const questionnaireTypes = {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL'
}

const assessmentQuestionnaireManagement = (state = initState, action) => {
    switch (action.type) {
        case actionsAssessmentQuestionnaireManagement.RESET_ASSESSMENT_QUESTIONNAIRE:
        case actionsAssessmentQuestionnaireManagement.DELETE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS:
            return { ...initState }
        case actionsAssessmentQuestionnaireManagement.FETCH_QUESTIONNAIRE_DATA_REQUEST:
        case actionsAssessmentQuestionnaireManagement.CREATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST:
        case actionsAssessmentQuestionnaireManagement.UPDATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST:
        case actionsAssessmentQuestionnaireManagement.DELETE_ASSESSMENT_QUESTIONNNAIRE_REQUEST:
        case actionsAssessmentQuestionnaireManagement.SAVE_QUESTIONNNAIRE_ITEMS_REQUEST:
        case actionsAssessmentQuestionnaireManagement.DELETE_QUESTIONNNAIRE_ITEM_REQUEST:
        case actionsAssessmentQuestionnaireManagement.SAVE_QUESTIONNNAIRE_ITEM_SCORES_REQUEST:
        case actionsAssessmentQuestionnaireManagement.DELETE_QUESTIONNNAIRE_ITEM_SCORE_REQUEST:
            return { ...state, loading: true, errors: null }
        case actionsAssessmentQuestionnaireManagement.FETCH_QUESTIONNAIRE_DATA_SUCCESS:
            return { ...state, loading: false, items: action.items, scores: action.scores, assessmentQuestionnaireData: action.questionnaireData }
        case actionsAssessmentQuestionnaireManagement.CREATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS:
            return { ...state, loading: false, errors: null, assessmentQuestionnaireData: action.newQuestionnaireCreated }
        // case actionsAssessmentQuestionnaireManagement.FETCH_QUESTIONNAIRE_DATA_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         errors: null,
        //         assessmentQuestionnaireData: action.questionnaireData,
        //         items: action.items ? action.items : [],
        //         scores: action.scores ? action.scores : []
        //     }
        case actionsAssessmentQuestionnaireManagement.ADD_QUESTIONNAIRE_ITEM_REQUEST:
            return {
                ...state,
                items: state.items.concat({ id: null, order: state.items.length + 1, title: action.title, description: action.description })
            }
        case actionsAssessmentQuestionnaireManagement.EDIT_QUESTIONNAIRE_ITEM_REQUEST:
            const itemsUpdated = state.items.map((item, index) => {
                if (index === action.indexItem) {
                    return {
                        ...item,
                        title: action.title,
                        description: action.description
                    }
                }
                return item
            })
            return {
                ...state,
                items: itemsUpdated
            }
        case actionsAssessmentQuestionnaireManagement.EDIT_QUESTIONNAIRE_ITEM_POSITION_REQUEST:
            if (state.evaluationComponent !== null && action.newPosition !== undefined && action.oldPosition !== undefined && action.oldPosition !== action.newPosition) {
                let newItems = arrayMove(state.items, action.oldPosition, action.newPosition).map((item, index) => {
                    item.order = index + 1
                    return item
                })

                return { ...state, items: newItems }
            }
            return { ...state }

        case actionsAssessmentQuestionnaireManagement.ADD_QUESTIONNAIRE_ITEM_SCORE_REQUEST:
            return {
                ...state,
                scores: state.scores.concat({ 
                    id: null, 
                    order: state.scores.length + 1, 
                    title: action.title, 
                    description: action.description, 
                    score: action.score })
            }
        case actionsAssessmentQuestionnaireManagement.EDIT_QUESTIONNAIRE_ITEM_SCORE_REQUEST:
            const scoresUpdated = state.scores.map((score, index) => {
                if (index === action.indexItemScore) {
                    return {
                        ...score,
                        title: action.title,
                        description: action.description,
                        score: action.score
                    }
                }
                return score
            })
            return {
                ...state,
                scores: scoresUpdated
            }
        case actionsAssessmentQuestionnaireManagement.EDIT_QUESTIONNAIRE_ITEM_SCORE_POSITION_REQUEST:
            if (state.evaluationComponent !== null && action.newPosition !== undefined && action.oldPosition !== undefined && action.oldPosition !== action.newPosition) {
                let newScores = arrayMove(state.scores, action.oldPosition, action.newPosition).map((score, index) => {
                    score.order = index + 1
                    return score
                })

                return { ...state, scores: newScores }
            }
            return { ...state }

        case actionsAssessmentQuestionnaireManagement.SAVE_QUESTIONNNAIRE_ITEMS_SUCCESS:
        case actionsAssessmentQuestionnaireManagement.DELETE_QUESTIONNNAIRE_ITEM_SUCCESS:
            return { ...state, loading: false, items: action.items ? action.items : [] }
        case actionsAssessmentQuestionnaireManagement.SAVE_QUESTIONNNAIRE_ITEM_SCORES_SUCCESS:
        case actionsAssessmentQuestionnaireManagement.DELETE_QUESTIONNNAIRE_ITEM_SCORE_SUCCESS:
            return { ...state, loading: false, scores: action.scores ? action.scores : [] }

        case actionsAssessmentQuestionnaireManagement.UPDATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS:
            return { ...state, loading: false, assessmentQuestionnaireData: action.questionnaireData }
        case actionsAssessmentQuestionnaireManagement.CREATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE:
        case actionsAssessmentQuestionnaireManagement.UPDATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE:
        case actionsAssessmentQuestionnaireManagement.SAVE_QUESTIONNNAIRE_ITEMS_FAILURE:
        case actionsAssessmentQuestionnaireManagement.DELETE_QUESTIONNNAIRE_ITEM_FAILURE:
        case actionsAssessmentQuestionnaireManagement.FETCH_QUESTIONNAIRE_DATA_FAILURE:
            return { ...state, loading: false, errors: action.errors }

        default:
            return state
    }
}

export const isLoadingAssessmentQuestionnnaireManagement = state => {
    return state.assessmentQuestionnaireManagement.loading
}

export const getAssessmentQuestionnaireData = state => {
    return state.assessmentQuestionnaireManagement.assessmentQuestionnaireData
}

export const getAssessmentQuestionnaireItems = state => {
    return state.assessmentQuestionnaireManagement.items
}

export const getAssessmentQuestionnaireScores = state => {
    return state.assessmentQuestionnaireManagement.scores
}

export const getAssessmentQuestionnaireErrors = state => {
    return state.assessmentQuestionnaireManagement.errors
}

export default assessmentQuestionnaireManagement
