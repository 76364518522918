import { fetchGet } from '../helperApi'

export const fetchHistoryInspectionFiltersApi = (companyId, customerId, contractId, serviceId, inspectedId, inspectionDateFrom, inspectionDateUntil, assessmentType) => {
    const params = {
        companyId,
        customerId,
        contractId,
        serviceId,
        inspectedId,
        inspectionDateFrom,
        inspectionDateUntil,
        assessmentType
    }
    return fetchGet(`inspections/filters`, params)
}
