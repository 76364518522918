import React from 'react'
import debounce from 'lodash/debounce'
import { ObjectKeyValue } from '../Types'

export interface InputNumberPropTypes {
    label: string | undefined
    name: any
    value: number | undefined
    change: (newValue: number) => void
    size?: any
    min: number
    max: number
    step: number
    readOnly?: boolean
    required?: boolean
    errors?: Array<ObjectKeyValue>
}

let InputNumber = (props: InputNumberPropTypes) => {
    const { label, name, value, min, max, change, readOnly = false, step = 1, required = false, errors = null } = props

    let input: HTMLInputElement

    const changeValue = (val: number): number => {
        let newValue: number = val

        if (val % step !== 0) newValue = step * (val / step)
        if (val < min) newValue = min
        if (isNaN(val)) newValue = step
        if (change !== undefined) {
            change(newValue)
        }

        return newValue
    }

    const inputCssClass = `${readOnly ? 'form__input--readonly' : 'form__input'} input__number`

    return (
        <div className="form__group">
            <label className="label__input">
                {label} {required ? '*' : ''}: {errors && errors[name] ? <span className="form__error"> {errors[name]}</span> : null}
            </label>

            <div className={inputCssClass}>
                <div
                    className="spin__minus"
                    onClick={() => {
                        if (input.value) {
                            input.value = changeValue(+input.value - step) + ''
                        }
                    }}
                >
                    -
                </div>
                <div className="input__container">
                    <input
                        ref={(node: HTMLInputElement) => (input = node)}
                        data-cy="global__input_number"
                        defaultValue={value + ''}
                        key={value}
                        type="number"
                        readOnly={readOnly}
                        min={min}
                        max={max}
                        required={required}
                        onChange={debounce(() => {
                            if (!input) return
                            input.value = changeValue(parseInt(input.value, 10)) + ''
                        }, 500)}
                    />
                </div>
                <div
                    className="spin__plus"
                    onClick={() => {
                        input.value = changeValue(+input.value + step) + ''
                    }}
                >
                    +
                </div>
            </div>

            <span className="focus-border" />
        </div>
    )
}

export default InputNumber
