import React from 'react'
import PackageSelector from '../../components/InspectionsSelectorsModules/PackageSelector'
import { InspectionDetail } from '../../components/InspectionDetail/InspectionDetail'

import AssessmentModule from '../../components/assesmentModule/AssessmentModule'
import Spinner from '../../shared/Spinner'
import { inspectionStatusTypes, inspectionSelectorTypes } from '../../store/inspection/reducers'
import WorkerSelector from '../../components/InspectionsSelectorsModules/WorkerSelector'

export const inspectionModulesDefinition = {
    packageSelector: {
        key: inspectionSelectorTypes.packageSelector,
        title: 'Selección del paquete',
        component: PackageSelector,
        deleteSelectors: [inspectionSelectorTypes.packageSelector],
    },
    workerSelector: {
        key: 'workerSelector',
        title: 'Selección del colaborador',
        deleteSelectors: [inspectionSelectorTypes.workerSelector],
        component: WorkerSelector,
    },
    assesmentModule: {
        key: 'assesmentModule',
        title: 'Realización de la Evaluación de estructura',
        component: AssessmentModule,
    },
}

class StructureAssessmentScreen extends React.Component {
    refDiv = null
    constructor(props) {
        super(props)

        this.refDiv = React.createRef()
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    getPropsByCurrentStep = (currentStep) => {
        if (currentStep === inspectionModulesDefinition.packageSelector.key) {
            return {
                packageType: 'STRUCTURE',
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.packageSelector,
                            id: newData.packageId,
                            name: newData.packageName,
                        },
                    ])
                    this.handleNextStep()
                },
            }
        } else if (currentStep === inspectionModulesDefinition.workerSelector.key) {
            return {
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.workerSelector,
                            id: newData.workerId,
                            name: newData.workerName,
                        },
                    ])
                    this.handleNextStep()
                },
            }
        }

        return {}
    }

    handleNextStep = () => {
        let newStep = this.props.currentStep
        if (this.props.currentStep === inspectionModulesDefinition.packageSelector.key) {
            if (this.props.inspectionSelectors.packageSelector.id) {
                newStep = inspectionModulesDefinition.workerSelector.key
            }
        } else if (this.props.currentStep === inspectionModulesDefinition.workerSelector.key) {
            if (this.props.inspectionSelectors.workerSelector.id) {
                const {
                    packageSelector: { id: packageId },
                    workerSelector: { id: workerId },
                } = this.props.inspectionSelectors

                const inpsectionId = this.props.inspectionData.id
                if (inpsectionId) {
                    this.props.updateInspectionSelectorsRequest(inpsectionId, packageId, workerId)
                } else {
                    this.props.createNewStructureAssessment(packageId, workerId)
                }
            }
        }

        if (newStep !== this.props.currentStep) {
            this.props.changeStep(newStep)
        }
    }

    handleDeleteSelector = (selectorKey) => {
        const fieldsToDelete = inspectionModulesDefinition[selectorKey].deleteSelectors
        this.props.resetAssessmentPackage()
        this.props.resetInspectionSelectorsByTypes(fieldsToDelete)
        this.props.changeStep(selectorKey)
    }

    canDeleteSelectors = () => {
        return this.props.inspectionData.inspectionStatus !== inspectionStatusTypes.FINISHED
    }
    getCompanyName = (companies) => {
        const companyId = this.state.inspectionIdentificationSelectorValues.companyId
        if (!companies) return null
        const company = companies.find((company) => company.id === companyId)

        return company ? company.name : null
    }

    render() {
        const moduleSelected = inspectionModulesDefinition[this.props.currentStep]
        if (!moduleSelected) return null
        const ModuleSelectedComponent = moduleSelected.component

        return (
            <div>
                {this.props.isLoading && <Spinner />}

                <section className="admin__head">
                    <div className="admin__profile">
                        <div className="profile__data">
                            <p className="data__title">{moduleSelected.title}</p>
                        </div>
                    </div>
                </section>

                <InspectionDetail
                    inspectionModulesDefinition={inspectionModulesDefinition}
                    inspectionSelectors={this.props.inspectionSelectors}
                    inspectionData={this.props.inspectionData}
                    canDeleteSelectors={this.canDeleteSelectors()}
                    onHandleDeleteModule={this.handleDeleteSelector}
                />

                <div ref={this.refDiv}>
                    {moduleSelected && (
                        <ModuleSelectedComponent
                            companies={this.props.companies}
                            inspectionSelectors={this.props.inspectionSelectors}
                            {...this.getPropsByCurrentStep(this.props.currentStep)}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default StructureAssessmentScreen
