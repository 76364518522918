import React from 'react'
import InspectionScreen from './InspectionScreen'
import { connect } from 'react-redux'
import { getCompanies, isLoadingGeneral } from '../../store/general/reducers'
import { fetchCompanies } from '../../store/general/actions'
import {
    resetInspection,
    resetInspectionSelectorsByTypes,
    setInspectionSelectorByTypes,
    changeInspectionStep,
    createInspection,
    updateInspectionSelectors,
} from '../../store/inspection/actions'
import { getInspectionData, getInspectionSelectors, getCurrentStep, isLoadingInspection } from '../../store/inspection/reducers'
import { resetCustomers } from '../../store/customers/actions'
import { resetWatchman } from '../../store/watchmen/actions'
import { resetTicket } from '../../store/inspectionTickets/actions'
import { resetAssessmentPackage } from '../../store/assessmentData/actions'


const mapStateToProps = state => ({
    currentStep: getCurrentStep(state),
    companies: getCompanies(state),
    inspectionData: getInspectionData(state),
    inspectionSelectors: getInspectionSelectors(state),
    isLoading: isLoadingGeneral(state) || isLoadingInspection(state),
})

const mapDispatchToProps = dispatch => ({
    fetchCompanies: () => {
        dispatch(fetchCompanies())
    },
    resetInspection: () => {
        dispatch(resetInspection())
    },
    resetSelectorsFilters: () => {
        dispatch(resetCustomers())
        dispatch(resetWatchman())
    },
    resetTicket: () => {
        dispatch(resetTicket())
    },
    setInspectionSelectorByTypes: inspectionSelectorsValues => {
        dispatch(setInspectionSelectorByTypes(inspectionSelectorsValues))
    },
    resetInspectionSelectorsByTypes: inspectionSelectorsToReset => {
        dispatch(resetInspectionSelectorsByTypes(inspectionSelectorsToReset))
    },
    changeStep: newStep => {
        dispatch(changeInspectionStep(newStep))
    },
    createNewInpsection: (companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId) => {
        dispatch(createInspection(companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId))
    },
    updateInspectionSelectorsRequest: (inspectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId) => {
        dispatch(updateInspectionSelectors(inspectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId))
    },
    resetAssessmentPackage: () => {
        dispatch(resetAssessmentPackage())
    },
})

class CreateInspectionContainer extends React.Component {
    componentDidMount() {
        this.props.resetInspection();
        this.props.resetTicket();

    }
    componentWillUnmount() {
        this.props.resetSelectorsFilters();
    }
    render() {
        return <InspectionScreen {...this.props} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInspectionContainer)
