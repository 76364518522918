import moment from 'moment'

export const formatDate = dateTime => {
    return moment(dateTime).format('DD/MM/YYYY')
}
export const formatDateTime = dateTime => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm')
}

export const formatDateTimeDb = dateTime => {
    return moment(dateTime).format('YYYY/MM/DD HH:mm:ss')
}
export const formatDateDb = dateTime => {
    return moment(dateTime).format('YYYY/MM/DD')
}

export const formatDateApiPoseidon = dateTime => {
    return moment(dateTime).format('YYYY-MM-DD')
}

export const formateDateTimeNow = () => {
    const now = moment()
    return now.format('YYYY/MM/DD HH:mm:ss')
}

export const getDateTimeTodayFirstHour = () => {
    let m = moment().utcOffset(0)
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    m.toISOString()
    m.format()
    return m
}

export const getDateTimeTodayLastHour = () => {
    let m = moment().utcOffset(0)
    m.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    m.toISOString()
    m.format()
    return m
}
