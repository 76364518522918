import { fetchGet } from '../helperApi'

export const fetchWatchmenApi = (companyId, params) => {
    return fetchGet(`company/${companyId}/watchmen`, params)
}
export const fetchWatchmenLiveSearchApi = (params) => {
    return fetchGet(`inspections/filters/live/inspected`, params)
}

export const fetchWorkersApi = (params) => {
    return fetchGet(`workers`, params)
}
