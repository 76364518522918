import { fetchGet, runPost, runDelete } from '../helperApi'

export const fetchInspectionAttachments = inspectionId => {
    return fetchGet(`inspection/${inspectionId}/attachments`)
}

export const deleteInspectionAttachment = (inspectionId, inspectionAttachmentId) => {
    return runDelete(`inspection/${inspectionId}/attachments/${inspectionAttachmentId}`)
}

export const uploadInspectionAttachment = (inspectionId, file, description) => {
    console.log('file', file)
    let data = new FormData()
    data.append('file', file)
    // data.append('type', type)
    data.append('description', description)

    console.log(data)

    return runPost(`inspection/${inspectionId}/attachments`, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}
