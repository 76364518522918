import React from 'react'
import InspectionScreen from './InspectionScreen'
import { connect } from 'react-redux'
import { getCompanies, isLoadingGeneral } from '../../store/general/reducers'
import { fetchCompanies } from '../../store/general/actions'
import { resetInspection, resetInspectionSelectorsByTypes, setInspectionSelectorByTypes, changeInspectionStep, updateInspectionSelectors } from '../../store/inspection/actions'
import { getInspectionData, getInspectionSelectors, getCurrentStep, isLoadingInspection } from '../../store/inspection/reducers'
import { routesConst } from '../../Routes'
import { resetAssessmentPackage } from '../../store/assessmentData/actions'

const mapStateToProps = state => ({
    currentStep: getCurrentStep(state),
    companies: getCompanies(state),
    inspectionData: getInspectionData(state),
    inspectionSelectors: getInspectionSelectors(state),
    isLoading: isLoadingGeneral(state) || isLoadingInspection(state),
})

const mapDispatchToProps = dispatch => ({
    fetchCompanies: () => {
        dispatch(fetchCompanies())
    },
    resetInspection: () => {
        dispatch(resetInspection())
    },
    setInspectionSelectorByTypes: inspectionSelectorsValues => {
        dispatch(setInspectionSelectorByTypes(inspectionSelectorsValues))
    },
    resetInspectionSelectorsByTypes: inspectionSelectorsToReset => {
        dispatch(resetInspectionSelectorsByTypes(inspectionSelectorsToReset))
    },
    changeStep: newStep => {
        dispatch(changeInspectionStep(newStep))
    },
    updateInspectionSelectorsRequest: (inspectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId) => {
        dispatch(updateInspectionSelectors(inspectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId))
    },
    resetAssessmentPackage: () => {
        dispatch(resetAssessmentPackage())
    },
})

class UpdateInspectionContainer extends React.Component {
    componentDidMount() {
        if (!this.props.inspectionData.id) {
            this.props.history.push(routesConst.home)
        }
    }
    render() {
        return <InspectionScreen {...this.props} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInspectionContainer)
