import React from 'react'
import { translateWords } from '../../Messages'
import TableNormal from '../../shared/Table/TableNormal'
import { routesConst, setVariableToRoute } from '../../Routes'
import Spinner from '../../shared/Spinner'

const screenTypes = {
    mobile: 'mobile',
    tablet: 'tablet',
    pc: 'pc'
}

const questionnairesTypesFilter = {
    all: 'ALL',
    internal: 'INTERNAL',
    external: 'EXTERNAL'
}

const questionnairesEnableFilter = {
    all: 'all',
    yes: 'yes',
    no: 'no'
}

const calculateScreenType = screenWith => {
    if (screenWith > 800) return screenTypes.pc
    if (screenWith > 450 && screenWith < 801) return screenTypes.tablet
    return screenTypes.mobile
}

export default class AssessmentQuestionnairesScreen extends React.Component {
    state = {
        screenType: null,
        filtered: []
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        window.addEventListener('resize', this.updateSize)
        this.setState({
            screenType: calculateScreenType(window.innerWidth)
        })
        this.props.fetchQuestionnaires()
    }

    updateSize = () => {
        const currentScreenType = calculateScreenType(window.innerWidth)

        if (this.state.screenType !== currentScreenType) {
            this.setState({
                screenType: currentScreenType
            })
        }
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered
        let insertNewFilter = true
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1)
                    else filter["value"] = value
                    insertNewFilter = false
                }
            })
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value })
        }

        this.setState({ filtered: filtered })
    }

    render() {
        const { isLoading, assessmentQuestionnaires } = this.props

        return (
            <div>
                {isLoading && <Spinner />}
                <section className="admin__head">
                    <div className="admin__profile">
                        <div className="profile__data">
                            <p className="data__title">Cuestionarios</p>
                            {/* <p className="data__name">{this.props.auth ? this.props.auth.username : '-'}</p> */}
                        </div>
                        <div className="profile__actions">
                            <button
                                onClick={() => {
                                    this.props.history.push(routesConst.assessmentQuestionaireManagementCreate)
                                }}
                                className="button__submit"
                            >
                                Nuevo Cuestionario
                            </button>
                        </div>
                    </div>
                </section>

                <section className="admin__body">
                    <div className="admin__panel">
                        {/* <h1 className="panel__title">Cuestionarios</h1> */}
                        <div className="panel__body">
                            <TableNormal
                                data={assessmentQuestionnaires}
                                // manual={true}
                                clearFilters={true}
                                collapseOnDataChange={false}
                                // loading={isLoading}
                                filterable
                                filtered={this.state.filtered}
                                onFilteredChange={(filtered, column, value) => {
                                  this.onFilteredChangeCustom(value, column.id || column.accessor)
                                }}
                                defaultFilterMethod={(filter, row, column) => {
                                  const id = filter.pivotId || filter.id
                                  if (typeof filter.value === "object") {
                                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true
                                  } else {
                                    return row[id] !== undefined ? String(row[id]).indexOf(filter.value) > -1 : true
                                  }
                                }}
                                columns={[
                                    {
                                        Header: 'Nombre del cuestionario',
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: true,
                                        accessor: 'title'
                                    },
                                    {
                                        Header: 'Asociado a',
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: true,
                                        accessor: 'packages',
                                        Cell: ({original}) => {
                                            let packageTitles = []
                                            original.packages.forEach((assessmentPackage) => {
                                                if (!packageTitles.includes(assessmentPackage.title)){
                                                    packageTitles.push(assessmentPackage.title)
                                                }
                                            })
                                            let packageTitleString = packageTitles.join(', ')
                                            return <span>{packageTitleString}</span>
                                        },
                                        filterMethod: (filter, row) => {
                                            let packageTitles = []
                                            row.packages.forEach((assessmentPackage) => {
                                                if (assessmentPackage.title.includes(filter.value)){
                                                    packageTitles.push(assessmentPackage.title)
                                                }
                                            })
                                            let packageTitleString = packageTitles.join(', ')
                                            return packageTitleString
                                        }
                                    },
                                    {
                                        Header: 'Mínima puntuación satisfactoria',
                                        filterable: true,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: true,
                                        accessor: 'minScoreSuccess'
                                    },
                                    {
                                        Header: 'Tipo',
                                        filterable: true,
                                        resizable: true,
                                        maxWidth: 100,

                                        style: { whiteSpace: 'unset' },
                                        sortable: true,
                                        accessor: 'type',
                                        Cell: ({ original }) => {
                                            return <span>{translateWords(original.type)}</span>
                                        },
                                        filterMethod: (filter, row) => {
                                            if (filter.value.indexOf(questionnairesTypesFilter.all) > -1) {
                                              return row
                                            }
                                            if (filter.value.indexOf(questionnairesTypesFilter.internal) > -1) {
                                              return row[filter.id] === filter.value
                                            }
                                            return row[filter.id] === filter.value
                                          },
                                        Filter: ({ filter }) => {
                                            return (
                                                <select
                                                onChange={event => {
                                                    this.onFilteredChangeCustom(event.target.value, "type")
                                                }}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : questionnairesTypesFilter.all}
                                                multiple={false}
                                                >
                                                <option value={questionnairesTypesFilter.all}>Todos</option>
                                                <option value={questionnairesTypesFilter.internal}>Interna</option>
                                                <option value={questionnairesTypesFilter.external}>Cliente</option>
                                                </select>
                                            )
                                        }
                                    },

                                    {
                                        Header: 'Habilitado',
                                        filterable: true,
                                        maxWidth: 125,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: true,
                                        accessor: 'enabled',
                                        Cell: ({ original }) => {
                                            return <span>{original.enabled ? 'Sí' : 'No'}</span>
                                        },
                                        filterMethod: (filter, row) => {
                                            if (filter.value.indexOf(questionnairesEnableFilter.all) > -1) {
                                              return row
                                            }
                                            if (filter.value.indexOf(questionnairesEnableFilter.yes) > -1) {
                                              return row[filter.id] === (filter.value === questionnairesEnableFilter.yes)
                                            }
                                            return row[filter.id] === false
                                        },
                                        Filter: ({ filter }) => {
                                            return (
                                                <select
                                                onChange={event => {
                                                    this.onFilteredChangeCustom(event.target.value, "enabled")
                                                }}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : questionnairesEnableFilter.all}
                                                multiple={false}
                                                >
                                                <option value={questionnairesEnableFilter.all}>Todos</option>
                                                <option value={questionnairesEnableFilter.yes}>Si</option>
                                                <option value={questionnairesEnableFilter.no}>No</option>
                                                </select>
                                            )
                                        }
                                    }
                                ]}
                                getTrProps={(state, rowInfo) => {
                                    let cursor = rowInfo ? 'pointer' : 'default'
                                    return {
                                        onClick: () => {
                                            if (!rowInfo || !rowInfo.original) return
                                            const questionnaireId = rowInfo.original.id

                                            this.props.history.push(setVariableToRoute(routesConst.assessmentQuestionaireManagementEdit, questionnaireId))
                                        },
                                        style: { cursor: cursor }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
