import * as Api from './api'
import { errorHandler } from '../helperApi'
import { arrayToObject } from '../HelperActions'

export const actionsTypes = {
    FETCH_CUSTOMER_SERVICES_REQUEST: 'FETCH_CUSTOMER_SERVICES_REQUEST',
    FETCH_CUSTOMER_SERVICES_SUCCESS: 'FETCH_CUSTOMER_SERVICES_SUCCESS',
    FETCH_CUSTOMER_SERVICES_FAILURE: 'FETCH_CUSTOMER_SERVICES_FAILURE',

    FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_REQUEST:
        'FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_REQUEST',
    FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_SUCCESS:
        'FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_SUCCESS',
    FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_FAILURE:
        'FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_FAILURE',
}

export const fetchContractServices =
    (contractId, reactTableState) => (dispatch, getState) => {
        const filtered =
            reactTableState && reactTableState.filtered
                ? arrayToObject(reactTableState.filtered)
                : null
        const sorted =
            reactTableState && reactTableState.sorted
                ? reactTableState.sorted
                : null
        const pageSize =
            reactTableState && reactTableState.pageSize
                ? reactTableState.pageSize
                : 5
        const page = reactTableState ? parseInt(reactTableState.page) + 1 : 1

        const params = {
            page,
            sorted,
            ...filtered,
            pageSize,
        }

        dispatch({ type: actionsTypes.FETCH_CUSTOMER_SERVICES_REQUEST, params })

        return Api.fetchContractServicesApi(contractId, params)
            .then((response) => {
                dispatch({
                    type: actionsTypes.FETCH_CUSTOMER_SERVICES_SUCCESS,
                    customerServices: response.data.customerServices.data,
                    pages: Math.ceil(
                        response.data.customerServices.total /
                            response.data.customerServices.pageSize
                    ),
                })
            })
            .catch((err) => {
                const actionError = (error) => {
                    dispatch({
                        type: actionsTypes.FETCH_CUSTOMER_SERVICES_FAILURE,
                        error: 'No se pudieron obtener los servicios del cliente',
                    })
                }

                errorHandler(dispatch, err.response, err, actionError)
            })
    }

export const fetchContractsServicesLiveSearch =
    (contractId, serviceNameFilter) => (dispatch, getState) => {
        const params = {
            serviceNameFilter,
            contractId,
        }
        dispatch({
            type: actionsTypes.FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_REQUEST,
            params,
            contractId,
        })

        return Api.fetchContractServicesLiveSearchApi(contractId, params)
            .then((response) => {
                const services = response.data
                dispatch({
                    type: actionsTypes.FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_SUCCESS,
                    services,
                })

                return Promise.resolve(services)
            })
            .catch((err) => {
                const actionError = (error) => {
                    dispatch({
                        type: actionsTypes.FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_FAILURE,
                        error: 'No se pudieron obtener los servicios',
                    })
                }

                errorHandler(dispatch, err.response, err, actionError)
                return Promise.reject([])
            })
    }
