export const closeMessage = () => {
    return { type: 'CLOSE_MESSAGE' }
}

export const setCustomSuccessNotificationMessage = message => (dispatch, getState) => {
    dispatch({ type: 'CUSTOM_SUCCESS_MESSAGE', succesMessage: message })
}

export const setCustomFailureNotificationMessage = message => (dispatch, getState) => {
    dispatch({ type: 'CUSTOM_FAILURE_MESSAGE', failureMessage: message })
}

export const setError = errorMessage => (dispatch, getState) => {
    dispatch({ type: 'SET_ERROR_MESSAGE', errorMessage })
}

export const clearErrorMessage = () => (dispatch, getState) => {
    dispatch({ type: 'CLEAR_ERROR_MESSAGE' })
}
