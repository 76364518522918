import * as Api from './api'
import { errorHandler } from '../helperApi'

export const actionsTypes = {
    FETCH_COMPANIES_REQUEST: 'FETCH_COMPANIES_REQUEST',
    FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
    FETCH_COMPANIES_FAILURE: 'FETCH_COMPANIES_FAILURE',

    FETCH_COMPANY_PACKAGES_REQUEST: 'FETCH_COMPANY_PACKAGES_REQUEST',
    FETCH_COMPANY_PACKAGES_SUCCESS: 'FETCH_COMPANY_PACKAGES_SUCCESS',
    FETCH_COMPANY_PACKAGES_FAILURE: 'FETCH_COMPANY_PACKAGES_FAILURE',

    FETCH_STRUCTURE_ASSESSMENT_PACKAGES_REQUEST: 'FETCH_STRUCTURE_ASSESSMENT_PACKAGES_REQUEST',
    FETCH_STRUCTURE_ASSESSMENT_PACKAGES_SUCCESS: 'FETCH_STRUCTURE_ASSESSMENT_PACKAGES_SUCCESS',
    FETCH_STRUCTURE_ASSESSMENT_PACKAGES_FAILURE: 'FETCH_STRUCTURE_ASSESSMENT_PACKAGES_FAILURE',
}

export const fetchCompanies = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.FETCH_COMPANIES_REQUEST })
    Api.fetchCompaniesApi()
        .then((response) => {
            dispatch({
                type: actionsTypes.FETCH_COMPANIES_SUCCESS,
                companies: response.data.companies,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.FETCH_COMPANIES_FAILURE, error: 'No se pudo obtener las empresas' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchCompanyPackages = (companyId) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.FETCH_COMPANY_PACKAGES_REQUEST })

    Api.fetchCompanyPackages(companyId)
        .then((response) => {
            dispatch({
                type: actionsTypes.FETCH_COMPANY_PACKAGES_SUCCESS,
                companyPackages: response.data.companyPackages,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.FETCH_COMPANY_PACKAGES_FAILURE, error: 'No se pudo obtener los paquetes de empresas' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchStructureAssessmentPackages = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.FETCH_STRUCTURE_ASSESSMENT_PACKAGES_REQUEST })

    Api.fetchStructureAssessmentPackagesApi()
        .then((response) => {
            dispatch({
                type: actionsTypes.FETCH_STRUCTURE_ASSESSMENT_PACKAGES_SUCCESS,
                companyPackages: response.data.assessmentPackage,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.FETCH_STRUCTURE_ASSESSMENT_PACKAGES_FAILURE, error: 'No se pudo obtener los paquetes' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}
