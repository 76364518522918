import { actionsTypes as actionsTypesAssessmentQuestionnaires } from './actions'

const initState = {
    loading: false,
    assessmentQuestionnaires: [],
    failureMessage: null
}

const assessmentQuestionnaires = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesAssessmentQuestionnaires.FETCH_ASSESSMENT_QUESTIONNAIRES_REQUEST:
            return { ...state, loading: true }
        case actionsTypesAssessmentQuestionnaires.FETCH_ASSESSMENT_QUESTIONNAIRES_SUCCESS:
            return { ...state, loading: false, assessmentQuestionnaires: action.assessmentQuestionnaires }

        default:
            return state
    }
}

export const getIsLoadingAssessmentQuestionnaires = state => {
    return state.assessmentQuestionnaires.loading
}

export const getAssessmentsQuestionnaires = state => {
    return state.assessmentQuestionnaires.assessmentQuestionnaires
}

export default assessmentQuestionnaires
