import React from 'react'
import { connect } from 'react-redux'
import {
    isLoadingInspection,
    getInspectionBlockScores,
    getBlockScoreAbstract,
    getInspectionBlockResult,
    blockResultTypes,
    getInspectionData,
    inspectionAssessmentTypes,
    getInspectionSignatures,
} from '../../store/inspection/reducers'
import { getTicket } from '../../store/inspectionTickets/reducers'
import { updateInspectionItemResponse } from '../../store/inspection/actions'
import { updateTicket } from '../../store/inspectionTickets/actions'
import { getAssessmentBlockTotalScore } from '../../store/assessmentData/reducers'
import TicketForm from '../assessmentQuestionnaireTabs/TicketForm'
import Modal from '../../shared/Modal'

const mapStateToProps = (state, ownProps) => {
    const blockId = ownProps.assessmentBlock ? ownProps.assessmentBlock.blockId : null
    return {
        blockScores: getInspectionBlockScores(state, blockId),
        blocksScoreAbstract: getBlockScoreAbstract(state, blockId),
        inspectionBlockResult: getInspectionBlockResult(state, blockId),
        blockMaxScore: getAssessmentBlockTotalScore(state, blockId),
        isLoading: isLoadingInspection(state),
        ticket: getTicket(state),
        inspectionData: getInspectionData(state),
        signatures: getInspectionSignatures(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectBlockItemScore: (assessmentBlockId, assessmentBlockItemId, assessmentBlockItemScoreId) => {
            dispatch(updateInspectionItemResponse(assessmentBlockId, assessmentBlockItemId, assessmentBlockItemScoreId))
        },
        onUpdatePetition: (itemIndex, itemName, role, expirationDate, toDo) => {
            dispatch(updateTicket(itemIndex, itemName, role, expirationDate, toDo))
        },
    }
}

class AssessmentBlock extends React.Component {
    state = {
        maxScore: 0,
        currentScore: 0,
        inpsectionResultScores: {},
        itemDetailSelected: null,
        showItemModal: false,
        taskIndexToEdit: null,
        taskNameToEdit: null,
        showScoreDescriptionModal: false,
        scoreItemToShow:null,
    }

    handleOnClickAnswer = (blockId, itemId, scoreItemId) => {
        if (!this.props.signatures.inspectorSignature){
            this.props.selectBlockItemScore(blockId, itemId, scoreItemId)
        }
    }

    handleItemDetailClicked = (itemId) => {
        const itemDetailSelected = this.state.itemDetailSelected === itemId ? null : itemId
        this.setState({
            itemDetailSelected,
        })
    }

    isSelectedItemScore = (itemId, scoreItemId) => {
        if (!this.props.blockScores) return false
        return this.props.blockScores[itemId] ? this.props.blockScores[itemId].scoreItemId === scoreItemId : false
    }

    // Comprueba si ya se ha creado un ticket para este item
    // Para mostrar el boton en verde o rojo
    isCreatedTicketForItem = (itemId) => {
        let pintaBoton = true
        this.props.ticket.forEach((element) => {
            if (element.itemIndex === itemId) pintaBoton = false
        })
        return pintaBoton
    }

    // Si se realiza click en el boton de crear ticket
    handleOnClickTicketButton = (itemId, itemName) => {
        // Se muestra la venta modal
        // Se indica el itemId que se
        this.setState({
            showItemModal: true,
            taskIndexToEdit: itemId,
            taskNameToEdit: itemName,
        })
    }

    closeModal = () => {
        this.setState({
            showItemModal: false,
            taskIndexToEdit: null,
        })
    }
    // Si se realiza click en el boton de crear ticket
    handleOnClickScoreDescriptionButton = (scoreItem) => {
        // Se muestra la venta modal
        // Se indica el itemId que se
        console.log(scoreItem);
        this.setState({
            showScoreDescriptionModal: true,
            scoreItemToShow:scoreItem,
        })
    }

    closeScoreDescriptionModal = () => {
        this.setState({
            showScoreDescriptionModal: false,
            scoreItemToShow: null,
        })
    }

    render() {
        const { assessmentBlock, blockMaxScore, blocksScoreAbstract, inspectionBlockResult } = this.props
        if (!assessmentBlock) return null
        const blockId = assessmentBlock.blockId
        const inspectionBlockCurrentScore = blocksScoreAbstract ? blocksScoreAbstract.totalScore : 0

        return (
            <>
                {this.state.showItemModal && (
                    // Muestra el formulario de ticket
                    <Modal title="Tarea a realizar" footer="" onClose={this.closeModal}>
                        <TicketForm
                            taskId={this.state.taskIndexToEdit}
                            tasks={this.props.ticket}
                            onSubmit={(role, expirationDate, toDo) => {
                                this.props.onUpdatePetition(this.state.taskIndexToEdit, this.state.taskNameToEdit, role, expirationDate, toDo)
                                this.closeModal()
                            }}
                        />
                    </Modal>
                )}
                {this.state.showScoreDescriptionModal && (
                    // Muestrala descripción de un score
                    <Modal title={` Valoración : ${this.state.scoreItemToShow.name}`} footer="" onClose={this.closeScoreDescriptionModal}>
                        <div className="block_table">
                            <div className="block_table__row block_table__header">
                                <span className="item_title">{this.state.scoreItemToShow.description}</span>
                            </div>
                        </div>
                    </Modal>
                )}
                <div className="questions_table">
                    <h2>
                        Cuestionario {assessmentBlock.type === 'INTERNAL' ? ' Interno' : ''}: {assessmentBlock.title}
                    </h2>

                    <div className="block_table">
                        <div className="block_table__row block_table__header">
                            <span className="item_title">REFERENCIA</span>
                            {assessmentBlock.scores &&
                                assessmentBlock.scores.map((item, index) => {
                                    //return <span key={index}>{option.name} - {option.description}</span>
                                    return <span key={item.id}>
                                        <React.Fragment >
                                            <div  className="item__title--row">
                                                <label>{item.name}</label>
                                                {(item.description ? item.description.trim() : false) && (
                                                    <i
                                                        title={item.description}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            this.handleOnClickScoreDescriptionButton(item)
                                                        }}
                                                        className="button__action mini fas fa-info"
                                                    ></i>
                                                )}
                                            </div>
                                            {this.state.itemDetailSelected === item.id && (
                                                <div className="item__description--row">
                                                    <label>{item.description}</label>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    </span> 
                                    
                                            
                                })}
                            <span>Peticiones</span>
                        </div>

                        {assessmentBlock.items &&
                            assessmentBlock.items.map((item, index) => {
                                return (
                                    <div key={index} className="block_table__row block_table__body">
                                        <span className="item_title">
                                            <React.Fragment>
                                                <div className="item__title--row">
                                                    <label>{item.name}</label>
                                                    {(item.description ? item.description.trim() : false) && (
                                                        <i
                                                            title="Descripción del item"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                this.handleItemDetailClicked(item.id)
                                                            }}
                                                            className={`fas ${
                                                                this.state.itemDetailSelected === item.id ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'
                                                            } tooltip__info`}
                                                        ></i>
                                                    )}
                                                </div>
                                                {this.state.itemDetailSelected === item.id && (
                                                    <div className="item__description--row">
                                                        <label>{item.description}</label>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        </span>
                                        {assessmentBlock.scores &&
                                            assessmentBlock.scores.map((score, index) => {
                                                const isSelectedItemScore = this.isSelectedItemScore(item.id, score.id)
                                                return (
                                                    <span
                                                        key={index}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            this.handleOnClickAnswer(blockId, item.id, score.id)
                                                        }}
                                                    >
                                                        <div style={{ width: '100%' }}>
                                                            <label className="question_answer_button">{isSelectedItemScore ? <i className="fa fa-check"></i> : '-'}</label>
                                                        </div>
                                                    </span>
                                                )
                                            })}
                                        <span>
                                            {this.isCreatedTicketForItem(item.id) && (
                                                <button
                                                    className="button__submit mini fas fa-plus"
                                                    onClick={() => {
                                                        this.handleOnClickTicketButton(item.id, item.name)
                                                    }}
                                                />
                                            )}
                                            {!this.isCreatedTicketForItem(item.id) && (
                                                <button
                                                    className="button__success mini fas fa-check"
                                                    onClick={() => {
                                                        this.handleOnClickTicketButton(item.id, item.name)
                                                    }}
                                                />
                                            )}
                                        </span>
                                    </div>
                                )
                            })}

                        {this.props.inspectionData.assessmentType === inspectionAssessmentTypes.OPERATIONAL && (
                            <div className="block_table__row block_table__footer">
                                <div className="block_score_row">
                                    <span className="title">Resultado de la evaluación</span>
                                    <span>{`${inspectionBlockCurrentScore}/${blockMaxScore}`}</span>
                                </div>
                                {inspectionBlockResult && inspectionBlockResult !== blockResultTypes.INCOMPLETE && (
                                    <div className="block_inspection_result">
                                        {inspectionBlockResult === blockResultTypes.SUCCESSFUL ? (
                                            <div className="block__answer--successful">EVALUACIÓN SATISFACTORIA</div>
                                        ) : (
                                            <div className="block__answer--unsuccessful">EVALUACIÓN NO SATISFACTORIA</div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentBlock)
