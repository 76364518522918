import React from 'react'
import Button from '../../shared/Button'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import Modal from '../../shared/Modal'
import { ItemForm } from './ItemForm'

const ItemsTable = SortableContainer(props => {
    const { items, onHandleEditItem, onHandleDeleteItem, onHandleNewItem } = props
    if (!items || !Array.isArray(items)) return <ul></ul>
    return (
        <ul className="sortable__container">
            <section className="admin__body">
                <div className="items__container" style={{ position: 'relative' }}>
                    <div className="admin__panel">
                        <div className="panel__body">
                            <ul className="form__body">
                                <div className="form__body--row-start sortable__header">
                                    <span className="body__column header__tab header__tab--first">Título de la pregunta</span>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {items.map((item, index) => {
                return (
                    <SortableItem
                        key={index}
                        index={index}
                        indexPosition={index}
                        itemData={item}
                        sortIndex={item.orderItem}
                        onHandleEditItem={onHandleEditItem}
                        onHandleDeleteItem={onHandleDeleteItem}
                    />
                )
            })}
            <div className="panel__add">
                <span
                    title="Añade un nuevo item"
                    onClick={e => {
                        e.preventDefault()
                        onHandleNewItem()
                    }}
                    className="fa_plus--add"
                >
                    <i className="fas fa-plus-circle" />
                </span>
                <div className="line-horizontal--center-vertical" />
            </div>
        </ul>
    )
})

const DragHandle = SortableHandle(() => (
    <span>
        <i style={{ fontSize: '1.3em', lineHeight: '2.5em' }} className="row__column_handler fas fa-arrows-alt" />
    </span>
))

const SortableItem = SortableElement(props => {
    const { itemData, indexPosition, onHandleEditItem, onHandleDeleteItem } = props
    return (
        <section
            className="admin__body--clickable"
            onClick={e => {
                e.preventDefault()
                onHandleEditItem(indexPosition)
            }}
        >
            <div className="items__container" style={{ position: 'relative' }}>
                <div className="admin__panel">
                    <div className="panel__body">
                        <ul className="form__body">
                            <div className="form__body--row-start">
                                <DragHandle />
                                <span className="body__column">{itemData.title}</span>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <span
                className="button__delete--top"
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onHandleDeleteItem(indexPosition)
                }}
            >
                <i className="fas fa-times-circle" />
            </span>
        </section>
    )
})

export default class QuestionnaireItems extends React.Component {
    state = {
        showItemModal: false,
        itemIndexToEdit: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    closeModal = () => {
        this.setState({
            showItemModal: false,
            itemIndexToEdit: null
        })
    }

    onSortEnd = pos => {
        this.props.changeItemPosition(pos.oldIndex, pos.newIndex)
    }

    render() {
        const { items, addItem, editItem, onSubmitQuestionnaireData, deleteQuestionnaireItem } = this.props

        return (
            <div style={{ marginTop: 35 }}>
                <h2 style={{ marginLeft: 40 }}>Preguntas posibles</h2>
                {this.state.showItemModal && (
                    <Modal
                        title={this.state.itemIndexToEdit === null ? 'Añadir nuevo item' : `Edición del item ${items[this.state.itemIndexToEdit].title}`}
                        footer=""
                        onClose={this.closeModal}
                    >
                        <ItemForm
                            onSubmit={(title, description) => {
                                if (this.state.itemIndexToEdit !== undefined && this.state.itemIndexToEdit !== null) {
                                    editItem(this.state.itemIndexToEdit, title, description)
                                } else {
                                    addItem(title, description)
                                }
                                this.closeModal()
                            }}
                            editItem={editItem}
                            addItem={addItem}
                            // isLoading={this.props.isLoading}
                            item={this.state.itemIndexToEdit !== null ? items[this.state.itemIndexToEdit] : null}
                        />
                    </Modal>
                )}
                <div>
                    <ItemsTable
                        items={items}
                        useDragHandle
                        onHandleEditItem={indexToEdit => {
                            this.setState({
                                itemIndexToEdit: indexToEdit,
                                showItemModal: true
                            })
                        }}
                        onHandleDeleteItem={itemIndexToDelete => {
                            deleteQuestionnaireItem(itemIndexToDelete)
                        }}
                        onSortEnd={this.onSortEnd}
                        onHandleNewItem={() => {
                            this.setState({
                                showItemModal: true,
                                itemIndexToEdit: null
                            })
                        }}
                    />
                    <Button
                        type="mini"
                        onClick={e => {
                            e.preventDefault()
                            onSubmitQuestionnaireData()
                        }}
                    >
                        Guardar items
                    </Button>
                </div>
            </div>
        )
    }
}
