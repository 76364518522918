import React from 'react'

export const Checkbox = ({ label, required, errors, onChecked, onNoChecked, defaultChecked = false, value, name, disabled }) => {
    return (
        <div className="form__line--row">
            <label className="label__input">
                {label} {required ? '*' : ''}: {errors && errors[name] ? <span className="form__error"> {errors[name]}</span> : null}
            </label>
            <label className="checkbox__container--background_white">
                <input
                    type="checkbox"
                    name={name}
                    defaultChecked
                    value={value}
                    onChange={e => {
                        if (!e.target.checked && onChecked) {
                            onNoChecked()
                        } else if (onNoChecked) {
                            onChecked()
                        }
                    }}
                    disabled={disabled}
                />
                <span className="checkbox__checkmarck" />
            </label>
        </div>
    )
}
