import * as Api from './api'
import { errorHandler } from '../helperApi'

export const actionsTypes = {
    FETCH_ASSESSMENT_PACKAGES_REQUEST: 'FETCH_ASSESSMENT_PACKAGES_REQUEST',
    FETCH_ASSESSMENT_PACKAGES_SUCCESS: 'FETCH_ASSESSMENT_PACKAGES_SUCCESS',
    FETCH_ASSESSMENT_PACKAGES_FAILURE: 'FETCH_ASSESSMENT_PACKAGES_FAILURE'
}

export const fetchAssessmentPackages = reactTableState => (dispatch, getState) => {
    // const sorted = reactTableState && reactTableState.sorted ? reactTableState.sorted : null
    // const pageSize = reactTableState && reactTableState.pageSize ? reactTableState.pageSize : 5
    // const page = reactTableState ? parseInt(reactTableState.page) + 1 : 1
    // const filtered = reactTableState && reactTableState.filtered ? arrayToObject(reactTableState.filtered) : null

    // const params = {
    //     page,
    //     sorted,
    //     pageSize,
    //     ...filtered
    // }

    dispatch({ type: actionsTypes.FETCH_ASSESSMENT_PACKAGES_REQUEST })

    Api.fetchAssessmentPackagesApi()
        .then(response => {
            dispatch({
                type: actionsTypes.FETCH_ASSESSMENT_PACKAGES_SUCCESS,
                assessmentPackages: response.data
            })
        })
        .catch(err => {
            dispatch({
                type: actionsTypes.FETCH_ASSESSMENT_PACKAGES_FAILURE
            })
            errorHandler(dispatch, err.response, err)
        })
}
