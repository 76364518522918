import axios from 'axios'
import { push } from 'react-router-redux'

import { getToken } from './auth/reducers'
import { deleteCookie } from '../shared/Helper'
import { translateWords } from '../Messages'
import { setCustomFailureNotificationMessage, setError } from './ui/actions'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.timeout = 20 * 1000

const ApiLocation = process.env.REACT_APP_HOST_API

function createAxiosAuthMiddleware() {
    return ({ getState }) => next => action => {
        const token = getToken(getState())
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

        return next(action)
    }
}

const axiosAuth = createAxiosAuthMiddleware()

export default axiosAuth

export const fetchGet = (url, params) => {
    return axios.get(ApiLocation + url, {
        params
    })
}

export const runPost = (url, body) => {
    return axios.post(ApiLocation + url, body)
}

export const runPut = (url, body) => {
    return axios.put(ApiLocation + url, body)
}

export const runDelete = (url, params) => {
    return axios.delete(ApiLocation + url, {
        params
    })
}

export const errorHandler = (dispatch, errorResponse, error, callbackFnError400 = null) => {
    if (!errorResponse) {
        dispatch(setError(translateWords('NETWORK_ERROR')))
        dispatch(push('/error'))
    } else {
        if (errorResponse.status === 403) {
            //  dispatch(logout(true))
            deleteCookie(process.env.REACT_APP_COOKIE_AUTH_NAME)
            localStorage.clear()
            //dispatch({ type: 'LOGIN_REQUIRED', payload: { statusText: error.data.err || error.response } })
            // window.location.reload()
        } else if (errorResponse.status === 400 || errorResponse.status === 500 || errorResponse.status === 404) {
            console.log(error)
            const errorCode = errorResponse.data.error_code
            console.log('el error', JSON.stringify(error))
            if (errorResponse.status === 400) {
                dispatch(setCustomFailureNotificationMessage(errorResponse.data.error))
            } else {
                dispatch(setCustomFailureNotificationMessage(translateWords(errorCode)))
            }
            if (errorResponse.status === 500) {
                dispatch(push('/error'))
                localStorage.clear()
                return
            }
            if (callbackFnError400) {
                callbackFnError400(errorResponse.data.errors)
            }
        } else {
            // dispatch(push('/error'))
        }
    }
}

export const setTokenAuthParam = (token, url) => {
    return `${url}?code=${token}`
}
