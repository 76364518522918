import React from 'react'
import { NavLink } from 'react-router-dom'
import { routesConst } from '../Routes'
import { canDoInspection, canDoInspectionStructureAssessment, canViewHistoryInspections, isAuthenticated, canAdminQuestionnairePackages } from '../store/auth/reducers'
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({
    userIsAuthenticated: isAuthenticated(state),
    userCanDoInspection: canDoInspection(state),
    userCanDoInspectionStructureAssessment: canDoInspectionStructureAssessment(state),
    userCanViewHistoryInspections: canViewHistoryInspections(state),
    userCanAdminQuestionnairePackages: canAdminQuestionnairePackages(state),
})

const mapDispatchToProps = (dispatch) => ({})

class SidebarMenu extends React.Component {
    isActive = (path, match, location) => !!(match || path === location.pathname)

    render() {
        const {
            isOpenMenu,
            handleOpenMenu,
            userIsAuthenticated,
            userCanDoInspection,
            userCanDoInspectionStructureAssessment,
            userCanAdminQuestionnairePackages,
        } = this.props
        return (
            <div className={isOpenMenu ? 'sidebar sidebar-open' : 'sidebar'}>
                <ul className={isOpenMenu ? 'nav__navigation navigation-open' : 'nav__navigation'}>
                    {userIsAuthenticated && (
                        <li className="nav__item">
                            <NavLink exact to={routesConst.home} onClick={handleOpenMenu} title="Inicio">
                                <i className="fas fa-home" />
                                <span className="nav__item__label">Inicio</span>
                            </NavLink>
                        </li>
                    )}
                    {userIsAuthenticated && userCanDoInspection && (
                        <li className="nav__item">
                            <NavLink
                                to={routesConst.createInspection}
                                isActive={(match, location) => {
                                    if (location && location.pathname.includes('/inspection/')) {
                                        return true
                                    }
                                    return false
                                }}
                                onClick={handleOpenMenu}
                                title="Evaluación para personal de operativa"
                            >
                                <i className="fas fa-plus" />
                                <span className="nav__item__label">Evaluación operativa</span>
                            </NavLink>
                        </li>
                    )}
                    {userIsAuthenticated && userCanDoInspectionStructureAssessment && (
                        <li className="nav__item">
                            <NavLink
                                to={routesConst.createStructureAssessment}
                                isActive={(match, location) => {
                                    if (location && location.pathname.includes('/structure/')) {
                                        return true
                                    }
                                    return false
                                }}
                                onClick={handleOpenMenu}
                                title="Evaluación para personal de estructura"
                            >
                                <i className="fas fa-search-plus" />
                                <span className="nav__item__label">Evaluación estructura</span>
                            </NavLink>
                        </li>
                    )}

                    {userIsAuthenticated && (
                        <li className="nav__item">
                            <NavLink to={routesConst.historyInspections} onClick={handleOpenMenu} title="Histórico de evaluaciones">
                                <i className="fas fa-history" />

                                <span className="nav__item__label">Histórico de evaluaciones</span>
                            </NavLink>
                        </li>
                    )}
                    {userCanAdminQuestionnairePackages && (
                        <li className="nav__item">
                            <NavLink to={routesConst.assessmentQuestionaires} onClick={handleOpenMenu} title="Configuración de cuestionarios">
                                <i className="fas fa-tasks" />
                                <span className="nav__item__label">Configuración de cuestionarios</span>
                            </NavLink>
                        </li>
                    )}
                    {userCanAdminQuestionnairePackages && (
                        <li className="nav__item">
                            <NavLink to={routesConst.assessmentPackages} onClick={handleOpenMenu} title="Configuración de paquetes">
                                <i className="fas fa-toolbox" />

                                <span className="nav__item__label">Configuración de paquetes</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
