import { getSuccessMessage, getFailureMessage } from '../store/ui/reducers'
import { closeMessage } from '../store/ui/actions'
import { connect } from 'react-redux'
import Alert from './Alert'

const mapStateToProps = (state, { match }) => {
    return {
        sucessMessage: getSuccessMessage(state),
        failureMessage: getFailureMessage(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => {
            dispatch(closeMessage())
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Alert)
