import * as Api from './api'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { errorHandler } from '../helperApi'
import { push } from 'react-router-redux'
import { routesConst } from '../../Routes'

export const actionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',

    FETCH_GRANT_REQUEST: 'FETCH_GRANT_REQUEST',
    FETCH_GRANT_SUCCESS: 'FETCH_GRANT_SUCCESS',
    FETCH_GRANT_FAILURE: 'FETCH_GRANT_FAILURE'
}

const getPoseidonToken = () => {
    const token = Cookies.get(process.env.REACT_APP_COOKIE_AUTH_NAME)
    return token
}
const urlLogin = process.env.REACT_APP_URL_BASE_POSEIDON

export const executeLogin = () => (dispatch, getState) => {
    const token = getPoseidonToken()
    dispatch({ type: actionTypes.LOGIN_REQUEST, token })

    if (!token) {
        const redirectLogin = urlLogin + '?redirect=' + window.location.href

        window.location.replace(`${redirectLogin}`)
        return
    }
    const decoded = jwtDecode(token)

    dispatch({ type: actionTypes.LOGIN_SUCCESS, token, userName: decoded.user_name })
    dispatch(fetchGrants())
}

export const fetchGrants = () => (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_GRANT_REQUEST })

    return Api.fetchGrants()
        .then(response => {
            const grants = response.data.grants

            dispatch({ type: actionTypes.FETCH_GRANT_SUCCESS, grants, userPoseidonId: response.data.userPoseidonId, userAppId: response.data.userAppId, userPoseidonWorkerId: response.data.userPoseidonWorkerId })

            if (!grants || grants.length === 0) {
                dispatch(push(routesConst.noAuthorized))
            }
        })
        .catch(err => {
            // // dispatch({ type: actionTypes.FETCH_GRANT_FAILURE, err })
            // const actionError = error => {
            //     dispatch({ type: actionTypes.FETCH_GRANT_FAILURE, error })
            // }
            // // console.log('error', err)
            dispatch({ type: actionTypes.FETCH_GRANT_FAILURE, error: 'No se pudieron obtener los permisos' })

            errorHandler(dispatch, err.response, err)
            // dispatch(push(routesConst.noAuthorized))
        })
}

export const logout = () => dispatch => {
    dispatch({ type: actionTypes.LOGOUT_REQUEST })

    localStorage.clear()
    const logoutUrl = process.env.REACT_APP_URL_LOGOUT_POSEIDON
    window.location.replace(`${logoutUrl}`)
}
