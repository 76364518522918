import * as Api from './api'
import { errorHandler } from '../helperApi'
import { arrayToObject } from '../HelperActions'

export const actionsTypes = {
    FETCH_HISTORY_INSPECTIONS_REQUEST: 'FETCH_HISTORY_INSPECTIONS_REQUEST',
    FETCH_HISTORY_INSPECTIONS_SUCCESS: 'FETCH_HISTORY_INSPECTIONS_SUCCESS',
    FETCH_HISTORY_INSPECTIONS_FAILURE: 'FETCH_HISTORY_INSPECTIONS_FAILURE'
}

export const fetchHistoryInspections = reactTableState => (dispatch, getState) => {
    const sorted = reactTableState && reactTableState.sorted ? reactTableState.sorted : null
    const pageSize = reactTableState && reactTableState.pageSize ? reactTableState.pageSize : 5
    const page = reactTableState ? parseInt(reactTableState.page) + 1 : 1
    const filtered = reactTableState && reactTableState.filtered ? arrayToObject(reactTableState.filtered) : null

    const params = {
        page,
        sorted,
        pageSize,
        ...filtered
    }

    dispatch({ type: actionsTypes.FETCH_HISTORY_INSPECTIONS_REQUEST, params })

    Api.fetchHistoryInspectionsApi(params)
        .then(response => {
            dispatch({
                type: actionsTypes.FETCH_HISTORY_INSPECTIONS_SUCCESS,
                inspections: response.data.inspectionsExecuted.data,
                pages: Math.ceil(response.data.inspectionsExecuted.total / response.data.inspectionsExecuted.pageSize),
                total: response.data.inspectionsExecuted.total,
            })
        })
        .catch(err => {
            dispatch({
                type: actionsTypes.FETCH_HISTORY_INSPECTIONS_FAILURE
            })
            errorHandler(dispatch, err.response, err)
        })
}
