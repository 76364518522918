import { connect } from 'react-redux'

import { getAssessmentsQuestionnaires, getIsLoadingAssessmentQuestionnaires } from '../../store/assessmentQuestionnaires/reducers'
import AssessmentQuestionnairesScreen from './AssessmentQuestionnairesScreen'
import { fetchAssessmentQuestionnaires } from '../../store/assessmentQuestionnaires/actions'

const mapStateToProps = state => ({
    isLoading: getIsLoadingAssessmentQuestionnaires(state),
    assessmentQuestionnaires: getAssessmentsQuestionnaires(state)
})

const mapDispatchToProps = dispatch => ({
    fetchQuestionnaires: reactTableState => {
        dispatch(fetchAssessmentQuestionnaires(reactTableState))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssessmentQuestionnairesScreen)
