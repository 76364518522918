import React from 'react'
import TableServerSide from '../../shared/Table/TableServerSide'
import InspectionsHistoryFilter from '../../components/InspectionsHistoryFilter/InspectionsHistoryFilter'
import TicketDetail from '../../components/TicketDetail/TicketDetail'
import InspectionAttachmentsList from '../../components/InspectionAttachmentsList/InspectionAttachmentsList'
import { getHistoryInspectionsTotal, getIsLoadingHistoryInspections } from '../../store/historyInspections/reducers'
import { translateWords } from '../../Messages'
import { setTokenAuthParam } from '../../store/helperApi'
import { formatDateDb } from '../../shared/HelperDate'
import Modal from '../../shared/Modal'
import { discardInspection, resumeInspection } from '../../store/inspection/actions'
import { connect } from 'react-redux'
import { getUserId, getUserWorkerId } from '../../store/auth/reducers'
import { isLoadingInspection } from '../../store/inspection/reducers'

const screenTypes = {
    mobile: 'mobile',
    tablet: 'tablet',
    pc: 'pc'
}

const calculateScreenType = screenWith => {
    if (screenWith > 800) return screenTypes.pc
    if (screenWith > 450 && screenWith < 801) return screenTypes.tablet
    return screenTypes.mobile
}

const mapStateToProps = (state) => ({
    poseidonUserId: getUserId(state),
    poseidonWorkerId: getUserWorkerId(state),
    historyInspectionsTotal: getHistoryInspectionsTotal(state),
    isLoadingHistoryInspections: getIsLoadingHistoryInspections(state),
    isLoadingInspection: isLoadingInspection(state),
})

const mapDispatchToProps = (dispatch) => ({
    discardInspection: (inspectionId) => {
        return dispatch(discardInspection(inspectionId))
    },
    resumeInspection: (inspectionId) => {
        return dispatch(resumeInspection(inspectionId))
    }
})

class InspectionsHistoryScreen extends React.Component {
    myTableComponent
    myTable
    state = {
        screenType: null,
        showTicketModal: false,
        showAttachmentsModal: false,
        inspectionId: null
    }
    constructor(props) {
        super(props)
        this.myTableComponent = React.createRef()
        this.myTable = React.createRef()
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.props.resetCustomers()
        this.props.resetWatchman()
        this.props.resetDelegations()

        const defaultFilters = [{ accessor: 'inspectionDateFrom', value: formatDateDb(new Date()) }, { accessor: 'inspectionDateUntil', value: formatDateDb(new Date()) }]

        this.myTableComponent.current.setAllFilterValues(defaultFilters)
        window.scrollTo(0, 0)
        window.addEventListener('resize', this.updateSize)
        this.setState({
            screenType: calculateScreenType(window.innerWidth)
        })
    }

    setAllFilter = filters => {
        if (this.myTableComponent && this.myTableComponent.current) {
            this.myTableComponent.current.setAllFilterValues(filters)
        }
    }

    updateSize = () => {
        const currentScreenType = calculateScreenType(window.innerWidth)

        if (this.state.screenType !== currentScreenType) {
            this.setState({
                screenType: currentScreenType
            })
        }
    }

    handleOnClickTicketButton = (inspectionId) => {
        this.setState({
            showItemModal: true,
            inspectionId: inspectionId
        })
    }

    handleOnClickAttachmentsButton = (inspectionId) => {
        this.setState({
            showAttachmentsModal: true,
            inspectionId: inspectionId
        })
    }

    handleOnClickDiscardButton = (inspectionId) => {
        let currentTableState = this.myTable.current.state
        this.props.discardInspection(inspectionId)
        this.fetchData(currentTableState)
    }

    handleOnClickResumeButton = (inspectionId) => {
        this.props.resumeInspection(inspectionId)
    }

    fetchData = (reactableState) => {
        this.props.fetchHistoryInspections(reactableState)
    }

    resumeButtonDisabled = (inspection) => {
        const { poseidonUserId, poseidonWorkerId } = this.props
        if (inspection.assessmentType === 'OPERATIONAL') return true                                                                                                                 // Resume Button ALWAYS DISABLED for OPERATIONAL ASSESSMENTS
        if (['FINISHED', 'DISCARDED'].includes(inspection.inspectionStatus)) return true                                                                                             // Resume button DISABLED when INSPECTION STATUS = FINISHED/DISCARDED
        if ((poseidonWorkerId !== null) && (inspection.inspectedWorkerIdPoseidon === poseidonWorkerId) && inspection.inspectionStatus === 'AWAITING_SIGNATURE') return false         // Resume button NOT DISABLED when CURRENT USER = COLLABORATOR and INSPECTION STATUS = AWAITING SIGNATURE
        if ((inspection.inspectorUserIdPoseidon === poseidonUserId) && ['SAVED', 'PENDING'].includes(inspection.inspectionStatus)) return false                                      // Resume button NOT DISABLED when CURRENT USER = RESPONSABLE and INSPECTION STATUS = SAVED/PENDING
        if ((inspection.inspectorUserIdPoseidon !== poseidonUserId) && ((poseidonWorkerId !== null) && (inspection.inspectedWorkerIdPoseidon !== poseidonWorkerId))) return true     // Resume button DISABLED when CURRENT USER is NEITHER RESPONSABLE/COLLABORATOR
        return true
    }

    discardButtonDisabled = (inspection) => {
        const { poseidonUserId } = this.props
        return ['FINISHED', 'DISCARDED'].includes(inspection.inspectionStatus) || inspection.inspectorUserIdPoseidon !== poseidonUserId
    }

    showViewButton = (inspection) => {
        return ['FINISHED'].includes(inspection.inspectionStatus)
    }

    awaitingSignature = (inspection) => {
        return ['AWAITING_SIGNATURE'].includes(inspection.inspectionStatus)
    }

    render() {

        const { historyInspections, historyInspectionsPages, isLoadingHistoryInspections, token, resetTicket, resetInspectionAttachmentsList, historyInspectionsTotal, isLoadingInspection } = this.props

        const viewTablet = this.state.screenType === screenTypes.mobile || this.state.screenType === screenTypes.tablet
        const viewMobile = this.state.screenType === screenTypes.mobile

        const closeTicketModal = () => {
            resetTicket();
            this.setState({
                showItemModal: false,
            })
        }

        const closeAtachmentsModal = () => {
            resetInspectionAttachmentsList();
            this.setState({
                showAttachmentsModal: false,
            })
        }

        return (
            <div>
                {/* MODAL PARA VER LOS DATOS DEL TICKET POSEIDON */}
                {this.state.showItemModal && (
                    <Modal title="DATOS DEL TICKET"
                        footer=""
                        onClose={closeTicketModal}
                    >
                        <TicketDetail inspectionId={this.state.inspectionId} />
                    </Modal>
                )}

                {/* MODAL PARA VER EL LISTADO DE FICHEROS ADJUNTOS A LA EVALUACION */}
                {this.state.showAttachmentsModal && (
                    <Modal title="FICHEROS ADJUNTOS"
                        footer=""
                        onClose={closeAtachmentsModal}
                    >
                        <InspectionAttachmentsList inspectionId={this.state.inspectionId} />
                    </Modal>
                )}

                <section className="admin__body">
                    <div className="admin__panel">
                        <h1 className="panel__title">Evaluaciones realizadas</h1>
                        <div className="panel__body">
                            <TableServerSide
                                ref={this.myTableComponent}
                                refReactTable={this.myTable}
                                data={historyInspections}
                                manual={true}
                                clearFilters={false}
                                FiltersComponent={() => {
                                    return <InspectionsHistoryFilter setFilters={this.setAllFilter} />
                                }}
                                pages={historyInspectionsPages}
                                onFetchData={this.fetchData}
                                onResetChange={() => {
                                    // this.setState({ reset: true })
                                }}
                                collapseOnDataChange={false}
                                loading={isLoadingHistoryInspections || isLoadingInspection}
                                filterable
                                columns={[
                                    {
                                        Header: '',
                                        show: this.state.screenType !== screenTypes.pc,
                                        columns: [
                                            {
                                                expander: true,
                                                Header: () => <strong></strong>,
                                                maxWidth: 65,
                                                show: this.state.screenType !== screenTypes.pc,
                                                Expander: ({ isExpanded, ...rest }) => <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>,
                                                style: {
                                                    cursor: 'pointer',
                                                    fontSize: 25,
                                                    padding: '0',
                                                    textAlign: 'center',
                                                    userSelect: 'none'
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        Header: 'PDF',
                                        filterable: false,
                                        show: !(viewTablet || viewMobile),
                                        maxWidth: 70,
                                        accessor: null,
                                        id: null,
                                        sortable: false,
                                        // className: 'cell_center',
                                        Cell: ({ original }) => {
                                            return (
                                                <div>
                                                    <a className="button__mini" target="_blank" title="Descargar" href={setTokenAuthParam(token, original.downloadPdf)} rel="noopener noreferrer">
                                                        <i className="fas fa-download" />
                                                    </a>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Fecha',
                                        filterable: false,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        dateTimeFormat: true,
                                        sortable: false,
                                        accessor: 'inspectionDate'
                                    },
                                    {
                                        Header: 'Responsable',
                                        filterable: false,
                                        resizable: true,
                                        sortable: false,
                                        accessor: 'inspector',
                                        show: !(viewTablet || viewMobile)
                                    },
                                    {
                                        Header: 'Cliente',
                                        filterable: false,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: false,
                                        accessor: 'customer'
                                    },
                                    {
                                        Header: 'Delegación',
                                        filterable: false,
                                        resizable: true,
                                        sortable: false,
                                        style: { whiteSpace: 'unset' },
                                        accessor: 'delegationName',
                                        show: !(viewTablet || viewMobile)
                                    },
                                    {
                                        Header: 'Servicio',
                                        filterable: false,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: false,
                                        accessor: 'service',
                                        show: !(viewTablet || viewMobile)
                                    },
                                    {
                                        Header: 'Colaborador',
                                        filterable: false,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: false,
                                        accessor: 'inspected'
                                    },
                                    {
                                        Header: 'Resultado',
                                        filterable: false,
                                        resizable: true,
                                        show: !(viewTablet || viewMobile),
                                        sortable: false,
                                        accessor: 'inspectionResult',
                                        Cell: ({ original }) => {
                                            return <span>{translateWords(original.inspectionResult)}</span>
                                        }
                                    },
                                    {
                                        Header: 'Estado',
                                        filterable: false,
                                        resizable: true,
                                        show: !(viewTablet || viewMobile),
                                        sortable: true,
                                        accessor: 'inspectionStatus',
                                        Cell: ({ original }) => {
                                            return (
                                                <div>
                                                    <span>{translateWords(original.inspectionStatus)}</span>
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        Header: 'Acciones',
                                        filterable: false,
                                        resizable: false,
                                        show: !(viewTablet || viewMobile),
                                        sortable: false,
                                        minWidth: 190,
                                        centerCell: true,
                                        accessor: 'inspectionStatus',
                                        Cell: ({ original }) => {
                                            let resumeButtonDisabled = this.resumeButtonDisabled(original)
                                            let discardButtonDisabled = this.discardButtonDisabled(original)
                                            return (
                                                <div>
                                                    {this.showViewButton(original) ?
                                                        <button className="button__mini fas fa-eye" title="Ver" style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickResumeButton(original.inspectionId) }} />
                                                        :
                                                        this.awaitingSignature(original) ?
                                                            <button className="button__mini fas fa-signature" title="Firmar" disabled={resumeButtonDisabled} style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickResumeButton(original.inspectionId) }} />
                                                            :
                                                            <button className="button__mini fas fa-play" title="Reanudar" disabled={resumeButtonDisabled} style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickResumeButton(original.inspectionId) }} />
                                                    }
                                                    <button className="button__mini fas fa-trash" title="Descartar" disabled={discardButtonDisabled} style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickDiscardButton(original.inspectionId) }} />
                                                    <button className="button__mini fas fa-paperclip" title="Adjuntos" style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickAttachmentsButton(original.inspectionId) }} />
                                                    <button className="button__mini fas fa-calendar-check" title="Ticket" style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickTicketButton(original.inspectionId) }} />
                                                </div>
                                            )
                                        }
                                    },
                                ]}
                                SubComponent={row => {
                                    let resumeButtonDisabled = this.resumeButtonDisabled(row.original)
                                    let discardButtonDisabled = this.discardButtonDisabled(row.original)
                                    return (
                                        <div style={{ padding: '10px' }}>
                                            <div style={{ padding: '10px' }}>
                                                <a className="button__mini" target="_blank" href={setTokenAuthParam(token, row.original.downloadPdf)} rel="noopener noreferrer">
                                                    <i className="fas fa-download" /> Descargar informe
                                                </a>
                                            </div>
                                            <div className="table__subcomponent--row">
                                                <label>Responsable:</label> {row.original.inspector}
                                            </div>
                                            <div className="table__subcomponent--row">
                                                <label>Delegación:</label> {row.original.delegationName}
                                            </div>
                                            <div className="table__subcomponent--row">
                                                <label>Servicio:</label> {row.original.service}
                                            </div>
                                            <div className="table__subcomponent--row">
                                                <label>Resultado:</label> <span>{translateWords(row.original.inspectionResult)}</span>
                                            </div>
                                            <div className="table__subcomponent--row">
                                                <label>Estado:</label> <span>{translateWords(row.original.inspectionStatus)}</span>
                                            </div>
                                            <div className="table__subcomponent--row">
                                                {this.showViewButton(row.original) ?
                                                    <button className="button__mini fas fa-eye" title="Ver" style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickResumeButton(row.original.inspectionId) }}> Ver evaluación</button>
                                                    :
                                                    this.awaitingSignature(row.original) ?
                                                        <button className="button__mini fas fa-signature" title="Firmar" disabled={resumeButtonDisabled} style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickResumeButton(row.original.inspectionId) }}> Firmar evaluación</button>
                                                        :
                                                        <button className="button__mini fas fa-play" title="Reanudar" disabled={resumeButtonDisabled} style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickResumeButton(row.original.inspectionId) }}> Reanudar evaluación</button>
                                                }
                                                <button className="button__mini fas fa-trash" title="Descartar" disabled={discardButtonDisabled} style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickDiscardButton(row.original.inspectionId) }}> Descartar evaluación</button>
                                                <button className="button__mini fas fa-paperclip" title="Adjuntos" style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickAttachmentsButton(row.original.inspectionId) }}> Ver adjuntos</button>
                                                <button className="button__mini fas fa-calendar-check" title="Ticket" style={{ marginLeft: '5px' }} onClick={() => { this.handleOnClickTicketButton(row.original.inspectionId) }}> Ver ticket</button>
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                        <div className="panel__footer">Mostrando {historyInspections ? historyInspections.length : '0'} de un total de {historyInspectionsTotal ? historyInspectionsTotal : '0'}</div>
                    </div>
                </section>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsHistoryScreen)
