import * as Api from './api'
import { formatDateApiPoseidon } from '../../shared/HelperDate'
import { errorHandler } from '../helperApi'

export const actionTypes = {
    FETCH_TICKET_SUCCESS: 'FETCH_TICKET_SUCCESS',
    RESET_TICKET: 'RESET_TICKET',
    UPDATE_TICKET: 'UPDATE_TICKET',
    FETCH_TICKET_FAILURE: 'FETCH_TICKET_FAILURE',
    FETCH_TICKET_NOT_FOUND: 'FETCH_TICKET_NOT_FOUND',
}

export const resetTicket = () => (dispatch) => {
    dispatch({ type: actionTypes.RESET_TICKET })
}

export const updateTicket = (itemIndex, itemName, role, expirationDate, toDo) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_TICKET,
        payload: {
            itemIndex: itemIndex,
            role: role,
            expiration: formatDateApiPoseidon(expirationDate),
            toDo: toDo,
            itemName: itemName,
        },
    })
}

export const fetchTicket = (inspectionId) => (dispatch) => {
    return Api.fetchTickectDataApi(inspectionId)
        .then((response) => {
            dispatch({
                type: actionTypes.FETCH_TICKET_SUCCESS,
                ticketData: response.data.ticket,
            })
        })
        .catch((err) => {
            console.log('err.response.status -->' + JSON.stringify(err.response.status))

            if (err.response.status === 404) dispatch({ type: actionTypes.FETCH_TICKET_NOT_FOUND, error: 'No existe petición de tareas para esta evaluación' })
            else {
                const actionError = (err) => {
                    dispatch({ type: actionTypes.FETCH_TICKET_FAILURE, error: 'Error ' + err.response.status + 'al obtener la información' })
                }
                errorHandler(dispatch, err.response, err, actionError)
            }
        })
}
