import { actionsTypes as actionsTypesInspectionAttachments } from './actions'
import { actionsTypes as actionsTypesInspection } from '../inspection/actions'
const initState = {
    failureMessage: null,
    attachments: [],
    isLoading: false
}

const inspectionAttachments = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesInspectionAttachments.RESET_INSPECTION_ATTACHMENTS_LIST:
        case actionsTypesInspection.RESET_INSPECTION:
            return initState
        case actionsTypesInspectionAttachments.FETCH_INSPECTION_ATTACHMENTS_REQUEST:
        case actionsTypesInspectionAttachments.UPLOAD_INSPECTION_ATTACHMENT_REQUEST:
        case actionsTypesInspectionAttachments.DELETE_INSPECTION_ATTACHMENT_REQUEST:
            return { ...state, isLoading: true }
        case actionsTypesInspectionAttachments.FETCH_INSPECTION_ATTACHMENTS_SUCCESS:
            return { ...state, attachments: action.attachments, isLoading: false }
        case actionsTypesInspectionAttachments.UPLOAD_INSPECTION_ATTACHMENT_SUCCESS:
        case actionsTypesInspectionAttachments.UPLOAD_INSPECTION_ATTACHMENT_FAILURE:
        case actionsTypesInspectionAttachments.FETCH_INSPECTION_ATTACHMENTS_FAILURE:
            return { ...state, isLoading: false }

        default:
            return state
    }
}
export const getIsLoadingInspectionAttachments = state => {
    return state.inspectionAttachments.isLoading
}

export const getInspectionAttachments = state => {
    return state.inspectionAttachments.attachments
}

export default inspectionAttachments
