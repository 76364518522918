import { connect } from 'react-redux'
import InspectionsHistoryScreen from './InspectionsHistoryScreen'
import { getHistoryInspections, getHistoryInspectionsPages, getIsLoadingHistoryInspections } from '../../store/historyInspections/reducers'
import { fetchHistoryInspections } from '../../store/historyInspections/actions'
import { getToken } from '../../store/auth/reducers'
import { resetCustomers } from '../../store/customers/actions'
import { resetWatchman } from '../../store/watchmen/actions'
import { resetDelegations } from '../../store/delegationsCustomerCompany/actions'
import { resetTicket}     from '../../store/inspectionTickets/actions'
import { resetInspectionAttachmentsList}     from '../../store/inspectionAttachments/actions'



const mapStateToProps = state => ({
    token: getToken(state),
    historyInspections: getHistoryInspections(state),
    historyInspectionsPages: getHistoryInspectionsPages(state),
    isLoadingHistoryInspections: getIsLoadingHistoryInspections(state)
})

const mapDispatchToProps = dispatch => ({
    fetchHistoryInspections: reactTableState => {
        dispatch(fetchHistoryInspections(reactTableState))
    },
    resetCustomers: () => {
        dispatch(resetCustomers())
    },
    resetWatchman: () => {
        dispatch(resetWatchman())
    },
    resetDelegations: () => {
        dispatch(resetDelegations())
    },
    resetTicket: () => {
        dispatch(resetTicket())
    },
    resetInspectionAttachmentsList: () => {
        dispatch(resetInspectionAttachmentsList())
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionsHistoryScreen)
