import * as Api from './api'
import { errorHandler } from '../helperApi'

export const actionsTypes = {
    FETCH_ASSESSMENT_PACKAGE_DATA_REQUEST: 'FETCH_ASSESSMENT_PACKAGE_DATA_REQUEST',
    FETCH_ASSESSMENT_PACKAGE_DATA_SUCCESS: 'FETCH_ASSESSMENT_PACKAGE_DATA_SUCCESS',
    FETCH_ASSESSMENT_PACKAGE_DATA_FAILURE: 'FETCH_ASSESSMENT_PACKAGE_DATA_FAILURE',
    RESET_ASSESSMENT_PACKAGE: 'RESET_ASSESSMENT_PACKAGE',
}

export const fetchAssessmentDataByPackageId = packageId => (dispatch, getState) => {
    dispatch({ type: actionsTypes.FETCH_ASSESSMENT_PACKAGE_DATA_REQUEST })

    return Api.fetchAssessmentPackageDataApi(packageId)
        .then(response => {
            dispatch({
                type: actionsTypes.FETCH_ASSESSMENT_PACKAGE_DATA_SUCCESS,
                assessmentPackage: response.data.assessmentPackage
            })
        })
        .catch(err => {
            const actionError = error => {
                dispatch({ type: actionsTypes.FETCH_ASSESSMENT_PACKAGE_DATA_FAILURE, error: 'No se pudo obtener los datos del paquete de valoración' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const resetAssessmentPackage = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_ASSESSMENT_PACKAGE})
}
