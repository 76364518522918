import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import './assets/css/admin.css'
import './assets/css/alert.css'
import './assets/css/form.css'
import './assets/css/header.css'
import './assets/css/index.css'
import './assets/css/main.css'
import './assets/css/modal.css'
import './assets/css/reset.css'
import './assets/css/tab.css'
import './assets/css/table.css'
import './assets/css/questionsTable.css'
import * as Sentry from '@sentry/browser'

import App from './App'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: process.env.REACT_APP_DSN_SENTRY })
}
ReactDOM.render(
    <App />,

    document.getElementById('root')
)
