import React from 'react'

export const Card = ({ title, subTitle, body }) => {
    return (
        <section className="admin__body--card">
            <div className="admin__panel">
                <h1 className="panel__title">
                    {title}
                    {subTitle && <span className="actions__title">{subTitle}</span>}
                </h1>

                <div className="panel__body">
                    <div className="panel__inner">{body}</div>
                </div>
            </div>
        </section>
    )
}
