import React, { useState, useEffect } from 'react'
import Button from '../../shared/Button'
import { connect } from 'react-redux'
import { getCustomers, isLoadingCustomers, getCustomerPages } from '../../store/customers/reducers'
import { fetchCustomers, resetCustomers } from '../../store/customers/actions'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import TableServerSide from '../../shared/Table/TableServerSide'
import FormWithoutSection from '../../shared/FormWithoutSection'

const mapStateToProps = state => ({
    customers: getCustomers(state),
    isLoading: isLoadingCustomers(state),
    pages: getCustomerPages(state)
})

const mapDispatchToProps = dispatch => ({
    fetchCustomers: (companyId, paramsReactTable) => {
        dispatch(fetchCustomers(companyId, paramsReactTable))
    },
    resetCustomersData: () => {
        dispatch(resetCustomers())
    }
})

const CustomerFilter = ({ setFilters }) => {
    const [filterParams, setFilterParams] = useState({ customerCode: null, name: null })

    const updateFilterParam = (filterParamKey, filterParamValue) => {
        setFilterParams({ ...filterParams, [filterParamKey]: filterParamValue })
    }

    const hasSomeFilterParam = () => {
        const filterParamsValue = Object.values(filterParams)
        return filterParamsValue.find(filterParamValue => filterParamValue !== undefined && filterParamValue !== null && filterParamValue !== '')
    }

    const onSubmitFilters = () => {
        const filters = [{ accessor: 'customerCode', value: filterParams.customerCode }, { accessor: 'name', value: filterParams.name }]
        setFilters(filters)
    }

    return (
        <FormWithoutSection
            onSubmit={e => {
                e.preventDefault()

                onSubmitFilters()
            }}
            autoComplete="off"
            title="Seleccione el cliente"
        >
            <ul className="form__body">
                <li className="form__line">
                    <InputTextHTML5
                        name="customerCode"
                        id="customerCode"
                        label="Código cliente"
                        type="text"
                        onChange={value => {
                            updateFilterParam('customerCode', value)
                        }}
                    />
                    <InputTextHTML5
                        name="name"
                        id="name"
                        label="Nombre"
                        type="text"
                        onChange={value => {
                            updateFilterParam('name', value)
                        }}
                    />

                    <Button type="submit" disabled={!hasSomeFilterParam()}>
                        Buscar cliente
                    </Button>
                </li>
            </ul>
        </FormWithoutSection>
    )
}

const CustomerSelector = ({ handleSubmit, inspectionSelectors, fetchCustomers, resetCustomersData, customers, isLoading, pages }) => {
    useEffect(() => {
        resetCustomersData()
        // eslint-disable-next-line
    }, [])

    const setAllFilter = filters => {
        if (myTableComponent && myTableComponent.current) {
            myTableComponent.current.setAllFilterValues(filters)
        }
    }

    let myTableComponent = React.createRef()
    let myTable = React.createRef()
    let resultsDivRef = React.createRef()
    return (
        <section className="admin__body">
            <div className="admin__panel">
                <h1 className="panel__title">Seleccione al cliente</h1>
                <div className="panel__body">
                    <div ref={resultsDivRef}>
                        <TableServerSide
                            ref={myTableComponent}
                            refReactTable={myTable}
                            data={customers}
                            manual={true}
                            pages={pages}
                            FiltersComponent={() => {
                                return <CustomerFilter setFilters={setAllFilter} />
                            }}
                            clearFilters={false}
                            onFetchData={reactableState => {
                                fetchCustomers(inspectionSelectors.companySelector.id, reactableState)
                            }}
                            noFetchWhenEmptyFilters={true}
                            filterable
                            columns={[
                                {
                                    Header: 'Código cliente',
                                    accessor: 'customerCode',
                                    id: 'customerCode',
                                    filterable: false
                                },
                                {
                                    Header: 'Nombre',
                                    accessor: 'name',
                                    id: 'name',
                                    filterable: false
                                }
                            ]}
                            loading={isLoading}
                            getTrProps={(state, rowInfo) => {
                                let cursor = rowInfo ? 'pointer' : 'default'
                                return {
                                    onClick: () => {
                                        if (!rowInfo || !rowInfo.original) return
                                        handleSubmit({
                                            customerId: rowInfo.original.customerId,
                                            customerName: rowInfo.original.name
                                        })
                                    },
                                    style: { cursor: cursor }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerSelector)
