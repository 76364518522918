import React from 'react'
import TableNormal from '../../shared/Table/TableNormal'
import { routesConst, setVariableToRoute } from '../../Routes'
import Spinner from '../../shared/Spinner'

const screenTypes = {
    mobile: 'mobile',
    tablet: 'tablet',
    pc: 'pc'
}

const packagesEnableFilter = {
    all: 'all',
    yes: '1',
    no: '0'
}

const calculateScreenType = screenWith => {
    if (screenWith > 800) return screenTypes.pc
    if (screenWith > 450 && screenWith < 801) return screenTypes.tablet
    return screenTypes.mobile
}

export default class AssessmentPackagesScreen extends React.Component {
    state = {
        screenType: null,
        filtered: []
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        window.addEventListener('resize', this.updateSize)
        this.setState({
            screenType: calculateScreenType(window.innerWidth)
        })
        this.props.fetchPackages()
    }

    updateSize = () => {
        const currentScreenType = calculateScreenType(window.innerWidth)

        if (this.state.screenType !== currentScreenType) {
            this.setState({
                screenType: currentScreenType
            })
        }
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    };

    render() {
        const { isLoading, assesmentPackages } = this.props

        return (
            <div>
                {isLoading && <Spinner />}
                <section className="admin__head">
                    <div className="admin__profile">
                        <div className="profile__data">
                            <p className="data__title">Paquetes</p>
                            {/* <p className="data__name">{this.props.auth ? this.props.auth.username : '-'}</p> */}
                        </div>
                        <div className="profile__actions">
                            <button
                                onClick={() => {
                                    this.props.history.push(routesConst.assessmentPackagesCreate)
                                }}
                                className="button__submit"
                            >
                                Nuevo paquete
                            </button>
                        </div>
                    </div>
                </section>

                <section className="admin__body">
                    <div className="admin__panel">
                        {/* <h1 className="panel__title">Paquetes</h1> */}
                        <div className="panel__body">
                            <TableNormal
                                data={assesmentPackages}
                                clearFilters={false}
                                collapseOnDataChange={false}
                                // loading={isLoading}
                                filterable
                                filtered={this.state.filtered}
                                onFilteredChange={(filtered, column, value) => {
                                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                                }}
                                defaultFilterMethod={(filter, row, column) => {
                                  const id = filter.pivotId || filter.id;
                                  if (typeof filter.value === "object") {
                                    return row[id] !== undefined
                                      ? filter.value.indexOf(row[id]) > -1
                                      : true;
                                  } else {
                                    return row[id] !== undefined
                                      ? String(row[id]).indexOf(filter.value) > -1
                                      : true;
                                  }
                                }}
                                columns={[
                                    {
                                        Header: 'Nombre del paquete',
                                        filterable: true,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: true,
                                        accessor: 'title'
                                    },

                                    {
                                        Header: 'Habilitado',
                                        filterable: true,
                                        maxWidth: 125,
                                        resizable: true,
                                        style: { whiteSpace: 'unset' },
                                        sortable: true,
                                        accessor: 'enabled',
                                        Cell: ({ original }) => {
                                            return <span>{original.enabled ? 'Sí' : 'No'}</span>
                                        },
                                        filterMethod: (filter, row) => {
                                            if (filter.value.indexOf(packagesEnableFilter.all) > -1) {
                                              return row
                                            }
                                            if (filter.value.indexOf(packagesEnableFilter.yes) > -1) {
                                              return row[filter.id] === (parseInt(filter.value))
                                            }
                                            return row[filter.id] === 0
                                        },
                                        Filter: ({ filter }) => {
                                            return (
                                                <select
                                                onChange={event => {
                                                    this.onFilteredChangeCustom(event.target.value, "enabled")
                                                }}
                                                style={{ width: "100%" }}
                                                value={filter ? filter.value : packagesEnableFilter.all}
                                                multiple={false}
                                                >
                                                <option value={packagesEnableFilter.all}>Todos</option>
                                                <option value={packagesEnableFilter.yes}>Si</option>
                                                <option value={packagesEnableFilter.no}>No</option>
                                                </select>
                                            )
                                        }
                                    }
                                ]}
                                getTrProps={(state, rowInfo) => {
                                    let cursor = rowInfo ? 'pointer' : 'default'
                                    return {
                                        onClick: () => {
                                            if (!rowInfo || !rowInfo.original) return
                                            const packageId = rowInfo.original.packageId

                                            this.props.history.push(setVariableToRoute(routesConst.assessmentPackagesEdit, packageId))
                                        },
                                        style: { cursor: cursor }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
