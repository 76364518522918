import React from 'react'

import { connect } from 'react-redux'
import { getInspectionSignatures, canSignInspection, inspectedSignatureApprovalTypes, isStructureAssessment } from '../../store/inspection/reducers'
import { showColaboratorInputs } from '../../store/assessmentData/reducers'
import InspectedSignature from './InspectedSignature'
import { setCustomSuccessNotificationMessage, setCustomFailureNotificationMessage } from '../../store/ui/actions'
import { saveInspection, sendInspectedSignature, sendInspectorSignature } from '../../store/inspection/actions'
import InspectorSignature from './InspectorSignature'

const mapStateToProps = (state) => {
    return {
        signatures: getInspectionSignatures(state),
        canSign: canSignInspection(state),
        isStructureAssessment: isStructureAssessment(state),
        showColaboratorInputs: showColaboratorInputs(state),
    }
}

const mapDispatchToProps = (dispatch) => ({
    setCustomSuccessMessage: (message) => {
        dispatch(setCustomSuccessNotificationMessage(message))
    },
    setCustomFailureMessage: (message) => {
        dispatch(setCustomFailureNotificationMessage(message))
    },
    saveInspectedSignature: (inspectionId, signature, approvalResult) => {
        return dispatch(sendInspectedSignature(inspectionId, signature, approvalResult))
    },
    saveInspectorSignature: (inspectionId, signature) => {
        return dispatch(sendInspectorSignature(inspectionId, signature))
    },
    saveInspection: (inspectionId) => {
        return dispatch(saveInspection(inspectionId))
    },
})

class InspectionSignatures extends React.Component {
    state = { showInspectedSignature: false, showInspectorSignature: false }
    render() {
        return (
            <section className="admin__body">
                <div className="admin__panel">
                    <h1 className="panel__title">Firmas</h1>
                    <div className="panel__body">
                        <ul className="form__body">
                            <li className="form__line--start">
                                <div className="div__signature">
                                    <h3 className="title__signature">Firma del Responsable</h3>
                                    {this.props.signatures.inspectorSignature && (
                                        <div style={{ height: 135, width: '100%' }}>
                                            <img
                                                src={this.props.signatures.inspectorSignature.signature}
                                                alt="Firma del responsable"
                                                width="100%"
                                                style={{ maxWidth: '300px', maxHeight: '90%' }}
                                            />
                                        </div>
                                    )}
                                    <ul component="nav">
                                        <li
                                            className={this.props.signatures.inspectorSignature ? 'link__SignatureWorkOrder--active' : 'link__SignatureWorkOrder'}
                                            onClick={() => {
                                                if (this.props.canSign) {
                                                    this.setState({ showInspectorSignature: true })
                                                }
                                            }}
                                        >
                                            {this.props.signatures.inspectorSignature && <i className="fas fa-check" />}
                                            {!this.props.signatures.inspectorSignature && <i className="fas fa-edit" />}
                                            {this.props.signatures.inspectorSignature ? 'Firmado' : 'No firmado'}
                                        </li>
                                    </ul>
                                </div>
                               {this.props.showColaboratorInputs && (<div className="div__signature">
                                    <h3 className="title__signature">Firma del colaborador</h3>
                                    {this.props.signatures.inspectedSignature && (
                                        <div style={{ height: 135, width: '100%' }}>
                                            <img
                                                src={this.props.signatures.inspectedSignature.signature}
                                                alt="Firma del colaborador"
                                                width="100%"
                                                style={{ maxWidth: '300px', maxHeight: '90%' }}
                                            />
                                        </div>
                                    )}
                                    {this.props.signatures.inspectedSignature && (
                                        <span style={{ marginTop: 8 }}>
                                            {this.props.signatures.inspectedSignature.approvalResult === inspectedSignatureApprovalTypes.AGREE ? 'Conforme' : 'No conforme'}{' '}
                                            {this.props.signatures.inspectedSignature.createdAt}
                                        </span>
                                    )}

                                    <ul component="nav" style={{ marginTop: 8 }}>
                                        <li
                                            className={this.props.signatures.inspectedSignature ? 'link__SignatureWorkOrder--active' : 'link__SignatureWorkOrder'}
                                            onClick={() => {
                                                if (this.props.canSign) {
                                                    this.setState({ showInspectedSignature: true })
                                                }
                                            }}
                                        >
                                            {this.props.signatures.inspectedSignature && <i className="fas fa-check" />}
                                            {!this.props.signatures.inspectedSignature && <i className="fas fa-edit" />}
                                            {this.props.signatures.inspectedSignature ? 'Firmado' : 'No firmado'}
                                        </li>
                                    </ul>
                                </div>)}
                            </li>
                        </ul>
                    </div>
                </div>
                {this.state.showInspectedSignature && (
                    <InspectedSignature
                        setCustomFailureMessage={this.props.setCustomFailureMessage}
                        inspectedSignature={this.props.signatures.inspectedSignature}
                        close={() =>
                            this.setState({
                                showInspectedSignature: false,
                            })
                        }
                        saveSignature={(signature, approvalResult) => {
                            this.props
                                .saveInspectedSignature(this.props.inspectionId, signature, approvalResult)
                                .then(() => {
                                    this.setState({
                                        showInspectedSignature: false,
                                    })
                                })
                        }}
                    />
                )}
                {this.state.showInspectorSignature && (
                    <InspectorSignature
                        setCustomFailureMessage={this.props.setCustomFailureMessage}
                        inspectorSignature={this.props.signatures.inspectorSignature}
                        close={() =>
                            this.setState({
                                showInspectorSignature: false,
                            })
                        }
                        saveSignature={(signature) => {
                            if(this.props.isStructureAssessment){
                                this.props.saveInspection(this.props.inspectionId)  
                            }
                            this.props
                                .saveInspectorSignature(this.props.inspectionId, signature)
                                .then(() => {
                                    this.setState({
                                        showInspectorSignature: false,
                                    })
                                })
                        }}
                    />
                )}
            </section>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionSignatures)
