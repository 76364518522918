import { actionTypes } from './actions'

const initState = {
    tasks: []
}

const inspectionTicket = (state = initState, action) => {
    switch (action.type){
        case actionTypes.RESET_TICKET:
            return {...initState}
        case actionTypes.FETCH_TICKET_SUCCESS:
            return {...initState, ticketData: action.ticketData}
        case actionTypes.FETCH_TICKET_NOT_FOUND:
        case actionTypes.FETCH_TICKET_FAILURE:
            return {...initState, ticketData: {error: action.error}}
        case actionTypes.UPDATE_TICKET:
            // Si el index existe es que vamos a editar, eliminamos para despues añadirlo.
            state.tasks.forEach(function (taksInMemory, i) {
                if(taksInMemory.itemIndex === action.payload.itemIndex)
                    state.tasks.splice(i, 1);
            });
            
            // Task a añadir
            let tasks = state.tasks.concat({
                itemIndex:action.payload.itemIndex,
                role: action.payload.role,
                expiration: action.payload.expiration,
                toDo: action.payload.toDo,
                itemName: action.payload.itemName
            });

            return {...state, tasks: tasks}
        default:
            return state
    }
}

export const getTicket = state => {
    return state.inspectionTicket.tasks
}

export const getFetchedTicket = state => {
    return state.inspectionTicket.ticketData
}

export default inspectionTicket
