import React from 'react'
import { CompanySelector } from '../../components/InspectionsSelectorsModules/CompanySelector'
import CustomerSelector from '../../components/InspectionsSelectorsModules/CustomerSelector'
import ServiceSelector from '../../components/InspectionsSelectorsModules/ServiceSelector'
import WatchmanSelector from '../../components/InspectionsSelectorsModules/WatchmanSelector'
import PackageSelector from '../../components/InspectionsSelectorsModules/PackageSelector'
import { InspectionDetail } from '../../components/InspectionDetail/InspectionDetail'

import AssessmentModule from '../../components/assesmentModule/AssessmentModule'
import Spinner from '../../shared/Spinner'
import ContractSelector from '../../components/InspectionsSelectorsModules/ContractSelector'
import { inspectionStatusTypes, inspectionSelectorTypes } from '../../store/inspection/reducers'

export const inspectionModulesDefinition = {
    companySelector: {
        key: 'companySelector',
        title: 'Selección de empresa',
        component: CompanySelector,
        deleteSelectors: [
            inspectionSelectorTypes.companySelector,
            inspectionSelectorTypes.customerSelector,
            inspectionSelectorTypes.delegationSelector,
            inspectionSelectorTypes.contractSelector,
            inspectionSelectorTypes.serviceSelector,
            inspectionSelectorTypes.watchmanSelector,
            inspectionSelectorTypes.packageSelector,
        ],
        // deleteFields: ['companyId', 'customerId', 'contractId', 'delegation_id', 'serviceId', 'watchmanId', 'packageId'],
        // deleteFieldsNames: ['customerName', 'contractName', 'delegationName', 'serviceName', 'watchmanName', 'packageName']
    },
    customerSelector: {
        key: 'customerSelector',
        title: 'Selección de cliente',
        component: CustomerSelector,
        deleteSelectors: [
            inspectionSelectorTypes.customerSelector,
            inspectionSelectorTypes.delegationSelector,
            inspectionSelectorTypes.contractSelector,
            inspectionSelectorTypes.serviceSelector,
            inspectionSelectorTypes.watchmanSelector,
            inspectionSelectorTypes.packageSelector,
        ],
        // deleteFields: ['customerId', 'contractId', 'delegation_id', 'serviceId', 'watchmanId'],
        // deleteFieldsNames: ['customerName', 'contractName', 'delegationName', 'serviceName', 'watchmanName']
    },
    contractSelector: {
        key: 'contractSelector',
        title: 'Selección del contracto',
        component: ContractSelector,
        deleteSelectors: [
            inspectionSelectorTypes.delegationSelector,
            inspectionSelectorTypes.contractSelector,
            inspectionSelectorTypes.serviceSelector,
            inspectionSelectorTypes.watchmanSelector,
            inspectionSelectorTypes.packageSelector,
        ],
        // deleteFields: ['contractId', 'delegationId', 'serviceId', 'watchmanId'],
        // deleteFieldsNames: ['contractName', 'delegationName', 'serviceName', 'watchmanName']
    },

    serviceSelector: {
        key: 'serviceSelector',
        title: 'Selección del servicio',
        component: ServiceSelector,
        deleteSelectors: [inspectionSelectorTypes.serviceSelector, inspectionSelectorTypes.watchmanSelector, inspectionSelectorTypes.packageSelector],
        // deleteFields: ['serviceId', 'watchmanId'],
        // deleteFieldsNames: ['serviceName', 'watchmanName']
    },
    watchmanSelector: {
        key: 'watchmanSelector',
        title: 'Selección del colaborador',
        deleteSelectors: [inspectionSelectorTypes.watchmanSelector, inspectionSelectorTypes.packageSelector],
        component: WatchmanSelector,
        // deleteFields: ['watchmanId', 'watchmanName'],
        // deleteFieldsNames: ['watchmanName']
    },
    packageSelector: {
        key: 'packageSelector',
        title: 'Selección del paquete',
        component: PackageSelector,
        deleteSelectors: [inspectionSelectorTypes.packageSelector],
        // deleteFields: ['packageId'],
        // deleteFieldsNames: ['packageName']
    },

    assesmentModule: {
        key: 'assesmentModule',
        title: 'Realización de la Evaluación de operativa',
        component: AssessmentModule,
    },
}

class InspectionScreen extends React.Component {
    refDiv = null
    constructor(props) {
        super(props)

        this.refDiv = React.createRef()
    }

    componentDidMount() {
        this.props.fetchCompanies()
        window.scrollTo(0, 0)
    }

    getPropsByCurrentStep = (currentStep) => {
        if (currentStep === inspectionModulesDefinition.companySelector.key) {
            return {
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.companySelector,
                            id: newData.companyId,
                            name: newData.companyName,
                        },
                    ])
                    this.handleNextStep()
                    // this.setState(
                    //     prevState => {
                    //         return {
                    //             inspectionIdentificationSelectorValues: {
                    //                 ...prevState.inspectionIdentificationSelectorValues,
                    //                 companyId: newData.companyId
                    //             },
                    //             inspectionIdentificationSelectorNames: {
                    //                 ...prevState.inspectionIdentificationSelectorNames,
                    //                 companyName: newData.companyName
                    //             }
                    //         }
                    //     },
                    //     () => {
                    //         this.handleNextStep()
                    //     }
                    // )
                },
            }
        } else if (currentStep === inspectionModulesDefinition.customerSelector.key) {
            return {
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.customerSelector,
                            id: newData.customerId,
                            name: newData.customerName,
                        },
                    ])
                    this.handleNextStep()

                    // this.setState(
                    //     prevState => {
                    //         return {
                    //             inspectionIdentificationSelectorValues: {
                    //                 ...prevState.inspectionIdentificationSelectorValues,
                    //                 customerId: newData.customerId
                    //             },
                    //             inspectionIdentificationSelectorNames: {
                    //                 ...prevState.inspectionIdentificationSelectorNames,
                    //                 customerName: newData.customerName
                    //             }
                    //         }
                    //     },
                    //     () => {
                    //         this.handleNextStep()
                    //     }
                    // )
                },
            }
        } else if (currentStep === inspectionModulesDefinition.contractSelector.key) {
            return {
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.contractSelector,
                            id: newData.contractId,
                            name: newData.contractName,
                        },
                        {
                            selectorType: inspectionSelectorTypes.delegationSelector,
                            id: newData.delegationId,
                            name: newData.delegationName,
                        },
                    ])
                    this.handleNextStep()

                    // this.setState(
                    //     prevState => {
                    //         return {
                    //             inspectionIdentificationSelectorValues: {
                    //                 ...prevState.inspectionIdentificationSelectorValues,
                    //                 contractId: newData.contractId,
                    //                 delegationId: newData.delegationId
                    //             },
                    //             inspectionIdentificationSelectorNames: {
                    //                 ...prevState.inspectionIdentificationSelectorNames,
                    //                 contractName: newData.contractName,
                    //                 delegationName: newData.delegationName
                    //             }
                    //         }
                    //     },
                    //     () => {
                    //         this.handleNextStep()
                    //     }
                    // )
                },
            }
        } else if (currentStep === inspectionModulesDefinition.serviceSelector.key) {
            return {
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.serviceSelector,
                            id: newData.serviceId,
                            name: newData.serviceName,
                        },
                    ])
                    this.handleNextStep()
                    // this.setState(
                    //     prevState => {
                    //         return {
                    //             inspectionIdentificationSelectorValues: {
                    //                 ...prevState.inspectionIdentificationSelectorValues,
                    //                 serviceId: newData.serviceId
                    //             },
                    //             inspectionIdentificationSelectorNames: {
                    //                 ...prevState.inspectionIdentificationSelectorNames,
                    //                 serviceName: newData.serviceName
                    //             }
                    //         }
                    //     },
                    //     () => {
                    //         this.handleNextStep()
                    //     }
                    // )
                },
            }
        } else if (currentStep === inspectionModulesDefinition.watchmanSelector.key) {
            return {
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.watchmanSelector,
                            id: newData.watchmanId,
                            name: newData.watchmanName,
                        },
                    ])
                    this.handleNextStep()
                    // this.setState(
                    //     prevState => {
                    //         return {
                    //             inspectionIdentificationSelectorValues: {
                    //                 ...prevState.inspectionIdentificationSelectorValues,
                    //                 watchmanId: newData.watchmanId
                    //             },
                    //             inspectionIdentificationSelectorNames: {
                    //                 ...prevState.inspectionIdentificationSelectorNames,
                    //                 watchmanName: newData.watchmanName
                    //             }
                    //         }
                    //     },
                    //     () => {
                    //         this.handleNextStep()
                    //     }
                    // )
                },
            }
        } else if (currentStep === inspectionModulesDefinition.packageSelector.key) {
            return {
                handleSubmit: (newData) => {
                    this.props.setInspectionSelectorByTypes([
                        {
                            selectorType: inspectionSelectorTypes.packageSelector,
                            id: newData.packageId,
                            name: newData.packageName,
                        },
                    ])
                    this.handleNextStep()
                    // this.setState(
                    //     prevState => {
                    //         return {
                    //             inspectionIdentificationSelectorValues: {
                    //                 ...prevState.inspectionIdentificationSelectorValues,
                    //                 packageId: newData.packageId
                    //             },
                    //             inspectionIdentificationSelectorNames: {
                    //                 ...prevState.inspectionIdentificationSelectorNames,
                    //                 packageName: newData.packageName
                    //             }
                    //         }
                    //     },
                    //     () => {
                    //         this.handleNextStep()
                    //     }
                    // )
                },
            }
        }

        return {}
    }

    handleNextStep = () => {
        let newStep = this.props.currentStep
        if (this.props.currentStep === inspectionModulesDefinition.companySelector.key) {
            if (this.props.inspectionSelectors.companySelector.id) {
                newStep = inspectionModulesDefinition.customerSelector.key
            }
        } else if (this.props.currentStep === inspectionModulesDefinition.customerSelector.key) {
            if (this.props.inspectionSelectors.customerSelector.id) {
                newStep = inspectionModulesDefinition.contractSelector.key
            }
        } else if (this.props.currentStep === inspectionModulesDefinition.contractSelector.key) {
            if (this.props.inspectionSelectors.contractSelector.id) {
                newStep = inspectionModulesDefinition.serviceSelector.key
            }
        } else if (this.props.currentStep === inspectionModulesDefinition.serviceSelector.key) {
            if (this.props.inspectionSelectors.serviceSelector.id) {
                newStep = inspectionModulesDefinition.watchmanSelector.key
            }
        } else if (this.props.currentStep === inspectionModulesDefinition.watchmanSelector.key) {
            if (this.props.inspectionSelectors.watchmanSelector.id) {
                newStep = inspectionModulesDefinition.packageSelector.key
            }
        } else if (this.props.currentStep === inspectionModulesDefinition.packageSelector.key) {
            if (this.props.inspectionSelectors.packageSelector.id) {
                // newStep = inspectionModulesDefinition.assesmentModule.key
                const {
                    companySelector: { id: companyId },
                    customerSelector: { id: customerId },
                    contractSelector: { id: contractId },
                    delegationSelector: { id: delegationId },
                    serviceSelector: { id: serviceId },
                    watchmanSelector: { id: watchmanId },
                    packageSelector: { id: packageId },
                } = this.props.inspectionSelectors

                const inpsectionId = this.props.inspectionData.id
                if (inpsectionId) {
                    this.props.updateInspectionSelectorsRequest(inpsectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId)
                } else {
                    this.props.createNewInpsection(companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId)
                }
            }
        }

        if (newStep !== this.props.currentStep) {
            this.props.changeStep(newStep)
        }
    }

    handleDeleteSelector = (selectorKey) => {
        const fieldsToDelete = inspectionModulesDefinition[selectorKey].deleteSelectors
        this.props.resetAssessmentPackage()
        this.props.resetInspectionSelectorsByTypes(fieldsToDelete)
        this.props.changeStep(selectorKey)
        // const fieldsToDelete = inspectionModulesDefinition[selectorKey].deleteFields
        // let currentFieldsValues = this.state.inspectionIdentificationSelectorValues
        // for (let index = 0; index < fieldsToDelete.length; index++) {
        //     const fieldToDelete = fieldsToDelete[index]
        //     currentFieldsValues[fieldToDelete] = null
        // }
        // const fieldsNamesToDelete = inspectionModulesDefinition[selectorKey].deleteFieldsNames
        // let currentFieldsNames = this.state.inspectionIdentificationSelectorNames
        // for (let index = 0; index < fieldsNamesToDelete.length; index++) {
        //     const fieldToDelete = fieldsNamesToDelete[index]
        //     currentFieldsNames[fieldToDelete] = null
        // }
        // this.setState({
        //     inspectionIdentificationSelectorValues: {
        //         ...currentFieldsValues
        //     },
        //     inspectionIdentificationSelectorNames: {
        //         ...currentFieldsNames
        //     },
        //     currentStep: selectorKey
        // })
    }

    canDeleteSelectors = () => {
        return this.props.inspectionData.inspectionStatus !== inspectionStatusTypes.FINISHED
    }
    getCompanyName = (companies) => {
        const companyId = this.state.inspectionIdentificationSelectorValues.companyId
        if (!companies) return null
        const company = companies.find((company) => company.id === companyId)

        return company ? company.name : null
    }

    render() {
        const moduleSelected = inspectionModulesDefinition[this.props.currentStep]
        if (!moduleSelected) return null
        const ModuleSelectedComponent = moduleSelected.component
        return (
            <div>
                {this.props.isLoading && <Spinner />}

                <section className="admin__head">
                    <div className="admin__profile">
                        <div className="profile__data">
                            <p className="data__title">{moduleSelected.title}</p>
                        </div>
                    </div>
                </section>

                <InspectionDetail
                    inspectionModulesDefinition={inspectionModulesDefinition}
                    inspectionSelectors={this.props.inspectionSelectors}
                    inspectionData={this.props.inspectionData}
                    canDeleteSelectors={this.canDeleteSelectors()}
                    onHandleDeleteModule={this.handleDeleteSelector}
                />

                <div ref={this.refDiv}>
                    {moduleSelected && (
                        <ModuleSelectedComponent
                            companies={this.props.companies}
                            inspectionSelectors={this.props.inspectionSelectors}
                            {...this.getPropsByCurrentStep(this.props.currentStep)}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default InspectionScreen
