import React, { useState, useEffect } from 'react'
import FormWithoutSection from '../../shared/FormWithoutSection'
import Button from '../../shared/Button'
import FormHtml5Select from '../../shared/Form/html5/FormHtml5Select'

export const PackageQuestionnnaireForm = ({ fetchQuestionnaireAvailables, questionnaireOptions, onSubmit }) => {
    const [formData, setFormData] = useState({
        questionnairePackageSelected: null
    })

    useEffect(() => {
        fetchQuestionnaireAvailables()
        // eslint-disable-next-line
    }, [])

    const onHandleChangeDataInput = (input, value) => {
        setFormData({
            ...formData,
            [input]: value
        })
    }

    const resetFormData = () => {
        setFormData({
            questionnairePackageSelected: null
        })
    }

    const isValidFormData = () => {
        return formData.questionnairePackageSelected !== null
    }

    return (
        <FormWithoutSection
            onSubmit={e => {
                e.preventDefault()
                onSubmit(formData.questionnairePackageSelected)
                resetFormData()
            }}
            autoComplete="off"
            title="Item"
        >
            <ul className="form__body">
                <li className="form__line">
                    <FormHtml5Select
                        label="Seleccione el cuestionario"
                        options={questionnaireOptions}
                        value={formData.questionnairePackageSelected}
                        onChangeCallback={newQuestionnaireSelectedValue => {
                            if (newQuestionnaireSelectedValue) {
                                onHandleChangeDataInput('questionnairePackageSelected', newQuestionnaireSelectedValue.value)
                            }
                        }}
                    />

                    {isValidFormData && (
                        <Button type="submit" disabled={!isValidFormData}>
                            Añadir
                        </Button>
                    )}
                </li>
            </ul>
        </FormWithoutSection>
    )
}
