import { actionsTypes as actionsAssessmentData } from './actions'
import { actionsTypes as actionsInspection } from '../inspection/actions'

const getInitState = () => {
    return {
        loading: false,
        assessmentPackageId: null,
        assessmentPackage: null,
        failureMessage: null
    }
}

const assessmentData = (state = getInitState(), action) => {
    switch (action.type) {
        case actionsInspection.RESET_INSPECTION:
            return { ...getInitState() }
        case actionsAssessmentData.FETCH_ASSESSMENT_PACKAGE_DATA_REQUEST:
            return { ...state, loading: true }
        case actionsAssessmentData.FETCH_ASSESSMENT_PACKAGE_DATA_SUCCESS:
            return { ...state, loading: false, assessmentPackage: action.assessmentPackage }
        case actionsAssessmentData.FETCH_ASSESSMENT_PACKAGE_DATA_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }
        case actionsAssessmentData.RESET_ASSESSMENT_PACKAGE:
                return { ...getInitState() }
        default:
            return state
    }
}

export const isLoadingAssessmentData = state => {
    return state.assessmentData.loading
}

export const getAssessmentPackage = state => {
    return state.assessmentData.assessmentPackage
}

export const showColaboratorInputs = (state) => {
    return state.assessmentData.assessmentPackage.showColaboratorInputs
}
export const getAssessmentPackageBlocks = state => {
    if (!state.assessmentData.assessmentPackage) return null
    return state.assessmentData.assessmentPackage.assessmentBlocks
}
export const getAssessmentBlock = (state, assessmentBlockId) => {
    const assessmentPackage = getAssessmentPackage(state)

    if (!assessmentPackage || !Array.isArray(assessmentPackage.assessmentBlocks)) return null

    const assessmentBlock = assessmentPackage.assessmentBlocks.find(block => block.blockId === assessmentBlockId)
    return assessmentBlock
}

export const getAssessmentBlockScores = (state, assessmentBlockId) => {
    const assessmentBlock = getAssessmentBlock(state, assessmentBlockId)

    if (!assessmentBlock) return null
    return assessmentBlock.scores
}

export const getAssessmentBlockItems = (state, assessmentBlockId) => {
    const assessmentBlock = getAssessmentBlock(state, assessmentBlockId)

    if (!assessmentBlock) return null
    return assessmentBlock.items
}

export const getAssessmentBlockItemScore = (state, assessmentBlockId, assessmentScoreId) => {
    const assessmentBlockScores = getAssessmentBlockScores(state, assessmentBlockId)

    if (!assessmentBlockScores || !Array.isArray(assessmentBlockScores)) return 0
    const itemScore = assessmentBlockScores.find(score => score.id === assessmentScoreId)
    return itemScore ? itemScore.value : 0
}

export const getAssessmentBlockTotalScore = (state, assessmentBlockId) => {
    const assessmentBlockScores = getAssessmentBlockScores(state, assessmentBlockId)
    const assessmentBlockItems = getAssessmentBlockItems(state, assessmentBlockId)
    if (!assessmentBlockScores || !assessmentBlockItems) return null

    let blockMaxScore = 0

    assessmentBlockScores.forEach(blockScore => {
        if (blockScore.value > blockMaxScore) {
            blockMaxScore = blockScore.value
        }
    })

    return assessmentBlockItems.length * blockMaxScore
}

export default assessmentData
