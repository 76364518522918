import { actionsTypes as actionsTypesCustomerContracts } from './actions'
import { actionsTypes as actionsTypesCustomers } from '../customers/actions'

const initState = {
    loading: false,
    filter: null,
    customerContracts: [],
    failureMessage: null,
    pages: -1
}

const customerContracts = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesCustomers.RESET_CUSTOMERS:
            return { ...initState }
        case actionsTypesCustomerContracts.FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_REQUEST:
            return { ...state, loading: true }

        case actionsTypesCustomerContracts.FETCH_CUSTOMER_CONTRACTS_REQUEST:
            return { ...state, loading: true, customerContracts: action.page > 1 ? state.customerContracts : [] }
        case actionsTypesCustomerContracts.FETCH_CUSTOMER_CONTRACTS_SUCCESS:
            return { ...state, loading: false, customerContracts: action.customerContracts, pages: action.pages }
        case actionsTypesCustomerContracts.FETCH_CUSTOMER_CONTRACTS_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }
        case actionsTypesCustomerContracts.FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_SUCCESS:
            return { ...state, loading: false, customerContracts: action.customerContracts }
        default:
            return state
    }
}

export const isLoadingCustomerContracts = state => {
    return state.customerContracts.loading
}

export const getCustomerContracts = state => {
    return state.customerContracts.customerContracts
}

export const getCustomerContractsPages = state => {
    return state.customerContracts.pages
}

export default customerContracts
