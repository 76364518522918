import * as React from 'react'
import ReactTable, { ComponentPropsGetter0, ComponentPropsGetterR, FilterRender } from 'react-table'
import debounce from 'lodash/debounce'
import Spinner from '../Spinner'
import DatePicker, { DatePickerProps } from 'react-date-picker'
import format from 'date-fns/format'
import es from 'date-fns/locale/es'
import { ColumnType } from '../Types'
import { formatDate, formatDateTime } from '../HelperDate'

export interface TableComponentProps<D = any> {
    data: D[]
    columns: ColumnType
    // columnFormat: ColumnType
    loading?: boolean
    pages?: number
    getTrProps?: ComponentPropsGetterR | ComponentPropsGetter0
    onFetchData?: (state: any, instance?: any) => void
    collapseOnDataChange?: boolean
    manual?: boolean
    filterable?: boolean
    refReactTable?: React.RefObject<any>
    FiltersComponent?: any
    onResetChange?: () => void
    clearFilters?: boolean
    noFetchWhenEmptyFilters?: Boolean
}

export interface TableComponentState<D = any> {
    filtered: Array<{ id: string; value: string | boolean }>
    date: { [k: string]: Date | Date[] | undefined }
}

class TableServerSide<T = any> extends React.Component<TableComponentProps, TableComponentState> {
    private paginateTemp: any
    private debounced = debounce((fn: () => any) => fn(), 350)

    // private injectedColumns: Array<Column<any> & ColumnType> = []
    private injectedColumns: ColumnType<T> = []

    constructor(props: TableComponentProps) {
        super(props)
        this.state = {
            filtered: [],
            date: {}
        }
    }

    componentDidMount() {
        this.prepareColumns()
    }

    componentDidUpdate(prevProps: TableComponentProps) {
        if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)) {
            this.prepareColumns()
        }
    }

    prepareColumns = () => {
        this.injectedColumns = this.props.columns.map((cf: any) => {
            if (cf.date) {
                cf.filterable = true
                cf.Filter = this.datePickerFilter((cf.id ? cf.id : cf.accessor) as string, DatePicker)
            }
            return cf
        })
        this.injectDateFormats()
        this.forceUpdate()
    }

    resetfilter = () => {
        let dateReset: { [k: string]: Date | Date[] | undefined } = {}
        Object.keys(this.state.date).map((key: string) => (dateReset[`${key}`] = undefined))

        this.setState({ filtered: [], date: dateReset })
    }

    onFilteredChangeCustom = (value: any, accessor: any) => {
        let filtered = this.state.filtered
        let insertNewFilter = 1

        if (filtered.length) {
            filtered.forEach((filter: any, i: number) => {
                if (filter['id'] === accessor) {
                    if (value === '' || !value.length) filtered.splice(i, 1)
                    else filter['value'] = value

                    insertNewFilter = 0
                }
            })
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value })
        }

        this.setState({ filtered: filtered }, () => {
            if (this.props.refReactTable) {
                this.props.refReactTable.current.fireFetchData()
            }
        })
    }

    setAllFilterValues = (filteredValues: Array<{ value: any; accessor: string }>) => {
        if (!filteredValues) return

        let filterValuesToAdd: Array<any> = []

        filteredValues.forEach((filter: any) => {
            if (filter.value !== null && filter.value !== undefined && filter.value.trim !== '') {
                filterValuesToAdd.push({ id: filter.accessor, value: filter.value })
            }
        })

        this.setState({ filtered: filterValuesToAdd }, () => {
            if (this.props.refReactTable) {
                this.props.refReactTable.current.fireFetchData()
            }
        })
    }

    public render() {
        const { children, refReactTable, collapseOnDataChange, data, ...rest } = this.props

        return (
            <React.Fragment>
                {this.props.FiltersComponent && this.props.FiltersComponent()}
                <div
                    style={{
                        margin: 15,
                        textAlign: 'right'
                    }}
                >
                    {this.props.onFetchData && this.props.clearFilters && (
                        <button
                            className="button__submit mini"
                            onClick={() => {
                                this.resetfilter()
                                if (this.props.onResetChange) this.props.onResetChange()
                            }}
                        >
                            Limpiar filtros
                        </button>
                    )}
                </div>
                <div className="panel__body">
                    <ReactTable
                        {...rest}
                        manual={this.props.manual ? this.props.manual : false}
                        columns={this.injectedColumns}
                        data={data}
                        ref={refReactTable}
                        resizable={true}
                        minRows={!data || data.length < 1 ? 3 : 0}
                        className="table -striped -highlight"
                        loading={this.props.loading}
                        LoadingComponent={() => (this.props.loading ? <Spinner /> : null)}
                        filtered={this.state.filtered}
                        onFilteredChange={
                            this.props.onFetchData
                                ? (filtered, column, value) => {
                                      this.onFilteredChangeCustom(value, column.id || column.accessor)
                                  }
                                : undefined
                        }
                        getTheadFilterThProps={() => ({
                            style: { overflow: 'inherit' }
                        })}
                        previousText="Anterior"
                        nextText="Siguiente"
                        rowsText="filas"
                        noDataText="No hay datos disponibles"
                        pageText="Página"
                        ofText="de"
                        pageSizeOptions={[8, 15, 20, 25, 50, 100]}
                        defaultPageSize={8}
                        showPagination={true}
                        onFetchData={this.fetchForPaginate}
                        collapseOnDataChange={collapseOnDataChange}
                    />
                </div>
            </React.Fragment>
        )
    }

    private isEmptyFilters = () => {
        return this.state.filtered && !this.state.filtered.find(filter => filter.value)
    }

    private canFetchForPaginate = () => {
        if (!this.props.noFetchWhenEmptyFilters) return true
        return !this.isEmptyFilters()
    }

    private fetchForPaginate = (state?: any) => {
        if (!this.canFetchForPaginate()) {
            return
        }

        if (!this.props.onFetchData) {
            return
        }
        // we save the last state te get data from it if we call the method forcefully
        let deb = true
        let stateTemp: any
        //    if (state && this.paginateTemp) if (this.paginateTemp.filtered[0] !== state.filtered[0]) deb = true
        if (state && this.paginateTemp) if (this.paginateTemp.page !== state.page) deb = false

        if (state) {
            this.paginateTemp = state
            stateTemp = state
        } else stateTemp = this.paginateTemp
        // if it is not a filter we do not invoke debounce

        if (deb) this.debounced(() => (this.props.onFetchData ? this.props.onFetchData(stateTemp) : undefined))
        else this.props.onFetchData(stateTemp)
    }

    private datePickerFilter = (name: string, Picker: (props: DatePickerProps) => JSX.Element): FilterRender => () => (
        <Picker
            value={this.state.date[name]}
            onChange={d => {
                let transformed = ''
                const data = d !== null ? d : undefined
                this.setState({
                    date: { ...this.state.date, [name]: data }
                })
                // we determine if the date is a range<Array> or a single date and transform for sending
                if (data && !Array.isArray(data)) transformed = format(data, 'YYYY/MM/DD', { locale: es })
                if (data && Array.isArray(data))
                    // we create the date range `${date}-${date}`
                    transformed = data.reduce((acc, curr) => (acc ? acc + '-' + format(curr, 'D/M/YYYY', { locale: es }) : format(curr, 'D/M/YYYY', { locale: es })), '')
                const find = this.state.filtered.find(item => item.id === name)
                let addedOrMutated: TableComponentState['filtered'] = []
                if (data && find) {
                    addedOrMutated = this.state.filtered.map(f => {
                        if (f.id === name) f.value = transformed
                        return f
                    })
                } else if (data && !find) {
                    addedOrMutated = this.state.filtered.concat({
                        id: name,
                        value: transformed
                    })
                } else {
                    const index = this.state.filtered.findIndex(item => item.id === name)
                    if (index > -1) {
                        addedOrMutated = this.state.filtered
                        addedOrMutated.splice(index, 1)
                    }
                }
                this.setState({ filtered: addedOrMutated })
            }}
        />
    )

    private injectDateFormats() {
        if (!this.props.columns) return
        const columns = this.injectedColumns.length ? this.injectedColumns : this.props.columns
        this.injectedColumns = columns.map(ic => {
            if (ic.dateFormat)
                ic.Cell = item => (
                    <>
                        {item.original && item.original[ic.accessor as string] && formatDate(item.original[ic.accessor as string])
                        // format(item.original[ic.accessor as string], 'D/M/YYYY', {
                        //     locale: es
                        // })
                        }
                    </>
                )
            if (ic.dateTimeFormat)
                ic.Cell = item => (
                    <>
                        {item.original && item.original[ic.accessor as string] && formatDateTime(item.original[ic.accessor as string])
                        // format(item.original[ic.accessor as string], 'D/M/YYYY HH:mm:ss', {
                        //     locale: es
                        // })
                        }
                    </>
                )
            return ic
        })
    }
}

export default TableServerSide
