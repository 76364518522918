import React, { useState, useEffect } from 'react'
import FormWithoutSection from '../../shared/FormWithoutSection'
import Button from '../../shared/Button'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'

export const ItemForm = ({ item, onSubmit }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: ''
    })

    useEffect(() => {
        if (item) {
            setFormData({
                title: item.title,
                description: item.description
            })
        }
        // eslint-disable-next-line
    }, [])

    const onHandleChangeQuestionnaireDataInput = (input, value) => {
        setFormData({
            ...formData,
            [input]: value
        })
    }
    return (
        <FormWithoutSection
            onSubmit={e => {
                e.preventDefault()
                onSubmit(formData.title, formData.description)
            }}
            autoComplete="off"
            title="Item"
        >
            <ul className="form__body">
                <li className="form__line">
                    <InputTextHTML5
                        type="text"
                        label="Título"
                        required={true}
                        placeholder="Introduzca el título de la pregunta"
                        id="questionnnaireName"
                        name="questionnnaireName"
                        defaultValue={formData.title}
                        max="255"
                        onChange={newTitleValue => {
                            onHandleChangeQuestionnaireDataInput('title', newTitleValue)
                        }}
                    />
                </li>
                <li className="form__line">
                    <InputTextHTML5
                        type="textarea"
                        label="Descripción"
                        required={false}
                        placeholder="Introduzca la descripción"
                        id="description"
                        name="description"
                        value={formData.description}
                        max="255"
                        onChange={newTitleValue => {
                            onHandleChangeQuestionnaireDataInput('description', newTitleValue)
                        }}
                    />
                </li>

                <li className="form__line">
                    <Button type="submit">Añadir</Button>
                </li>
            </ul>
        </FormWithoutSection>
    )
}
