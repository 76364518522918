import React from 'react'
import { connect } from 'react-redux'
import { fetchInspectionAttachments, deleteInspectionAttachment, uploadInspectionAttachment } from '../../store/inspectionAttachments/actions'
import { getInspectionAttachments } from '../../store/inspectionAttachments/reducers'
import TableNormal from '../../shared/Table/TableNormal'
import { setTokenAuthParam } from '../../store/helperApi'
import { Checkbox } from '../../shared/Form/InputCheckboxHtml5'
import { canAttachInspectionFiles, inspectionFinished } from '../../store/inspection/reducers'
import { updateInspectionShowImagesInReport } from '../../store/inspection/actions'
import { formatDateTime } from '../../shared/HelperDate'
import { getToken } from '../../store/auth/reducers'

const mapStateToProps = state => {
    return {
        inspectionAttachments: getInspectionAttachments(state),
        canAttachFiles: canAttachInspectionFiles(state),
        token: getToken(state),
        inspectionFinished: inspectionFinished(state),
    }
}

const mapDispatchToProps = dispatch => ({
    fetchAttachments: inspectionId => {
        dispatch(fetchInspectionAttachments(inspectionId))
    },
    deleteAttachment: (inspectionId, attachmentId) => {
        dispatch(deleteInspectionAttachment(inspectionId, attachmentId))
    },
    uploadAttachment: (inspectionId, file, description) => {
        dispatch(uploadInspectionAttachment(inspectionId, file, description))
    },
    updateInspectionShowImagesInReport: checked => {
        dispatch(updateInspectionShowImagesInReport(checked))
    }
})

class InspectionAttachments extends React.Component {
    state = {
        type: '',
        description: ''
    }

    componentDidMount() {
        this.props.fetchAttachments(this.props.inspectionId)
    }

    render() {
        const { includeAttachmentsInReport, canAttachFiles, token, inspectionFinished } = this.props

        return (
            <div>
                <section className="admin__body">
                    <div className="admin__panel">
                        <h1 className="panel__title">Adjuntos</h1>
                        {canAttachFiles && (
                            <div className="panel__subheader">
                                <form>
                                    <ul className="form__body">
                                        <li className="form__line">
                                            <div className="form__group">
                                                <input
                                                    name="description"
                                                    placeholder="Descripción del adjunto"
                                                    className="form__input"
                                                    onChange={e => {
                                                        e.preventDefault()
                                                        this.setState({
                                                            description: e.target.value
                                                        })
                                                    }}
                                                    value={this.state.description}
                                                />
                                                <span className="focus-border" />
                                            </div>

                                            <div className="button__schema">
                                                <label
                                                    className="label__input--click"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.inputDocument.click()
                                                    }}
                                                >
                                                    Seleccionar adjunto
                                                </label>
                                                <input
                                                    name="file"
                                                    type="file"
                                                    className="form__input file__none"
                                                    ref={node => {
                                                        this.inputDocument = node
                                                    }}
                                                    onChange={e => {
                                                        e.preventDefault()
                                                        const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
                                                        if (!file) return
                                                        this.props.uploadAttachment(this.props.inspectionId, file, this.state.description)
                                                        this.setState({ type: '', description: '' })
                                                        this.inputDocument.value = null
                                                    }}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        )}
                        <div className="panel__body">
                            <ul className="form__body">
                                <li className="form__line">
                                    <Checkbox
                                        label="Incluir las imágenes en el informe"
                                        name="include_attachments_in_report"
                                        value={includeAttachmentsInReport}
                                        defaultChecked={true}
                                        onChecked={() => {
                                            this.props.updateInspectionShowImagesInReport(true)
                                        }}
                                        onNoChecked={() => {
                                            this.props.updateInspectionShowImagesInReport(false)
                                        }}
                                        disabled={inspectionFinished}
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="panel__body">
                            <TableNormal
                                filterable
                                data={this.props.inspectionAttachments}
                                columns={[
                                    { Header: 'Descripción', accessor: 'description' },
                                    {
                                        Header: 'Fecha',
                                        accessor: 'created_at',
                                        filterable: false,
                                        Cell: ({ original }) => {
                                            return <span>{formatDateTime(original.createdAt)}</span>
                                        }
                                    },
                                    {
                                        Header: '',
                                        filterable: false,
                                        className: 'cell_center',
                                        Cell: ({ original }) => {
                                            return (
                                                <div>
                                                    <a className="button__mini" target="_blank" href={setTokenAuthParam(token, original.urlDownload)} rel="noopener noreferrer">
                                                        <i className="fas fa-download" />
                                                    </a>
                                                    {canAttachFiles && (
                                                        <button
                                                            onClick={() => {
                                                                this.props.deleteAttachment(this.props.inspectionId, original.id)
                                                            }}
                                                            className="button__mini"
                                                        >
                                                            <i className="fas fa-trash" />
                                                        </button>
                                                    )}
                                                </div>
                                            )
                                        }
                                    }
                                ]}
                                defaultPageSize={5}
                                defaultSorted={[{ id: 'id', desc: true }]}
                            />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionAttachments)
