import React from 'react'
import { connect } from 'react-redux'
import { getCompanies, isLoadingGeneral } from '../../store/general/reducers'
import {
    resetStructureAssessment,
    changeInspectionStep,
    createStructureAssessment,
    setStructureAssessmentSelectorByTypes,
    resetStructureAssessmentSelectorByTypes,
    updateStructureAssessmentSelectors,
} from '../../store/inspection/actions'
import { getInspectionData, getCurrentStep, isLoadingInspection, getStructureAssessmentSelectors } from '../../store/inspection/reducers'
import { resetCustomers } from '../../store/customers/actions'
import { resetWatchman } from '../../store/watchmen/actions'
import { resetTicket } from '../../store/inspectionTickets/actions'
import StructureAssessmentScreen from './StructureAssessmentScreen'
import { resetAssessmentPackage } from '../../store/assessmentData/actions'

const mapStateToProps = (state) => ({
    currentStep: getCurrentStep(state),
    companies: getCompanies(state),
    inspectionData: getInspectionData(state),
    inspectionSelectors: getStructureAssessmentSelectors(state),
    isLoading: isLoadingGeneral(state) || isLoadingInspection(state),
})

const mapDispatchToProps = (dispatch) => ({
    resetStructureAssessment: () => {
        dispatch(resetStructureAssessment())
    },
    resetSelectorsFilters: () => {
        dispatch(resetCustomers())
        dispatch(resetWatchman())
    },
    resetTicket: () => {
        dispatch(resetTicket())
    },
    setInspectionSelectorByTypes: (inspectionSelectorsValues) => {
        dispatch(setStructureAssessmentSelectorByTypes(inspectionSelectorsValues))
    },
    resetInspectionSelectorsByTypes: (inspectionSelectorsToReset) => {
        dispatch(resetStructureAssessmentSelectorByTypes(inspectionSelectorsToReset))
    },
    changeStep: (newStep) => {
        dispatch(changeInspectionStep(newStep))
    },
    createNewStructureAssessment: (packageId, workerId) => {
        dispatch(createStructureAssessment(packageId, workerId))
    },
    updateInspectionSelectorsRequest: (inspectionId, packageId, workerId) => {
        dispatch(updateStructureAssessmentSelectors(inspectionId, packageId, workerId))
    },
    resetAssessmentPackage: () => {
        dispatch(resetAssessmentPackage())
    },
})

class CreateStructureAssessmentContainer extends React.Component {
    componentDidMount() {
        this.props.resetStructureAssessment()
        this.props.resetTicket()
    }
    componentWillUnmount() {
        this.props.resetSelectorsFilters()
    }
    render() {
        return <StructureAssessmentScreen {...this.props} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStructureAssessmentContainer)
