import { actionsTypes as actionsTypesWatchmen } from './actions'

const initState = {
    loading: false,
    filter: null,
    watchmen: [],
    failureMessage: null,
    pages: -1,
}

const watchmen = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesWatchmen.RESET_WORKERS:
        case actionsTypesWatchmen.RESET_WATCHMAN:
            return initState
        case actionsTypesWatchmen.FETCH_WATCHMEN_LIVE_SEARCH_REQUEST:
            return { ...state, loading: true }
        case actionsTypesWatchmen.FETCH_WORKERS_REQUEST:
        case actionsTypesWatchmen.FETCH_WATCHMEN_REQUEST:
            return { ...state, loading: true, watchmen: action.page > 1 ? state.watchmen : [] }
        case actionsTypesWatchmen.FETCH_WORKERS_SUCCESS:
        case actionsTypesWatchmen.FETCH_WATCHMEN_SUCCESS:
            return { ...state, loading: false, watchmen: action.watchmen, pages: action.pages }
        case actionsTypesWatchmen.FETCH_WATCHMEN_LIVE_SEARCH_SUCCESS:
            return { ...state, loading: false, watchmen: action.watchmen }
        case actionsTypesWatchmen.FETCH_WORKERS_FAILURE:
        case actionsTypesWatchmen.FETCH_WATCHMEN_FAILURE:
        case actionsTypesWatchmen.FETCH_WATCHMEN_LIVE_SEARCH_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }

        default:
            return state
    }
}

export const isLoadingWorkers = (state) => {
    return state.watchmen.loading
}

export const isLoadingWatchmen = (state) => {
    return state.watchmen.loading
}

export const getWatchmen = (state) => {
    return state.watchmen.watchmen
}

export const getWatchmenPages = (state) => {
    return state.watchmen.pages
}

export const getWorkers = (state) => {
    return state.watchmen.watchmen
}
export const getWorkersPages = (state) => {
    return state.watchmen.pages
}

export default watchmen
