import { actionsTypes as actionsAssessmentPackageManagement } from './actions'
import { arrayMove } from 'react-sortable-hoc'

const initState = {
    loading: false,
    errors: null,
    failureMessage: null,
    assessmentPackageData: null,
    assessmentPackageTypes: [],
    companies: [],
    questionnaires: [],
    availableQuestionnaires: [],
}

const assessmentPackageManagement = (state = initState, action) => {
    switch (action.type) {
        case actionsAssessmentPackageManagement.RESET_ASSESSMENT_PACKAGE:
        case actionsAssessmentPackageManagement.DELETE_ASSESSMENT_PACKAGE_SUCCESS:
            return { ...initState }
        case actionsAssessmentPackageManagement.FETCH_PACKAGE_DATA_REQUEST:
        case actionsAssessmentPackageManagement.FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_REQUEST:
        case actionsAssessmentPackageManagement.DELETE_PACKAGE_QUESTIONNAIRE_REQUEST:
        case actionsAssessmentPackageManagement.UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_REQUEST:
        case actionsAssessmentPackageManagement.UPDATE_ASSESSMENT_PACKAGE_REQUEST:
        case actionsAssessmentPackageManagement.DELETE_ASSESSMENT_PACKAGE_REQUEST:
        case actionsAssessmentPackageManagement.CREATE_ASSESSMENT_PACKAGE_REQUEST:
        case actionsAssessmentPackageManagement.SAVE_PACKAGE_QUESTIONNAIRE_REQUEST:
            return { ...state, loading: true }
        case actionsAssessmentPackageManagement.FETCH_PACKAGE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                assessmentPackageData: action.packageData,
                companies: action.companies,
                assessmentPackageTypes: action.assessmentPackageTypes,
                questionnaires: action.questionnaires,
                errors: null,
            }

        case actionsAssessmentPackageManagement.FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_SUCCESS:
            return { ...state, loading: false, availableQuestionnaires: action.questionnairesAvailables ? action.questionnairesAvailables : [], errors: null }

        case actionsAssessmentPackageManagement.CREATE_ASSESSMENT_PACKAGE_SUCCESS:
        case actionsAssessmentPackageManagement.UPDATE_ASSESSMENT_PACKAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                assessmentPackageData: action.packageData,
                companies: action.companies,
                assessmentPackageTypes: action.assessmentPackageTypes,
                errors: null,
            }

        case actionsAssessmentPackageManagement.SAVE_PACKAGE_QUESTIONNAIRE_SUCCESS:
        case actionsAssessmentPackageManagement.DELETE_PACKAGE_QUESTIONNAIRE_SUCCESS:
        case actionsAssessmentPackageManagement.UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_SUCCESS:
            return { ...state, loading: false, questionnaires: action.packageQuestionnaires ? action.packageQuestionnaires : [], errors: null }

        case actionsAssessmentPackageManagement.EDIT_PACKAGE_QUESTIONNAIRE_POSITION_REQUEST:
            if (state.questionnaires !== null && action.newPosition !== undefined && action.oldPosition !== undefined && action.oldPosition !== action.newPosition) {
                let newQuestionnaires = arrayMove(state.questionnaires, action.oldPosition, action.newPosition).map((packageQuestionnaire, index) => {
                    packageQuestionnaire.order = index + 1
                    return packageQuestionnaire
                })

                return { ...state, questionnaires: newQuestionnaires }
            }
            return { ...state }

        case actionsAssessmentPackageManagement.FETCH_PACKAGE_DATA_FAILURE:
        case actionsAssessmentPackageManagement.UPDATE_ASSESSMENT_PACKAGE_FAILURE:
        case actionsAssessmentPackageManagement.CREATE_ASSESSMENT_PACKAGE_FAILURE:
        case actionsAssessmentPackageManagement.SAVE_PACKAGE_QUESTIONNAIRE_FAILURE:
        case actionsAssessmentPackageManagement.DELETE_ASSESSMENT_PACKAGE_FAILURE:
        case actionsAssessmentPackageManagement.FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_FAILURE:
            return { ...state, loading: false, errors: action.errors }

        default:
            return state
    }
}

export const isLoadingAssessmentPackageManagement = (state) => {
    return state.assessmentPackageManagement.loading
}

export const getAssessmentPackageData = (state) => {
    return state.assessmentPackageManagement.assessmentPackageData
}

export const getAssessmentPackagesCompanies = (state) => {
    return state.assessmentPackageManagement.companies
}
export const getAssessmentPackageTypes = (state) => {
    return state.assessmentPackageManagement.assessmentPackageTypes
}

export const getAssessmentPackagesQuestionnaires = (state) => {
    return state.assessmentPackageManagement.questionnaires
}

export const getAssessmentPackageErrors = (state) => {
    return state.assessmentPackageManagement.errors
}

export const getAvailablePackageQuestionnaires = (state) => {
    return state.assessmentPackageManagement.availableQuestionnaires
}

export default assessmentPackageManagement
