import { actionsTypes as actionsTypesGeneral } from './actions'
const initState = {
    loading: false,
    companies: [],
    companyPackages: [],
    failureMessage: null,
}

const general = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_COMPANIES_REQUEST':
        case actionsTypesGeneral.FETCH_COMPANY_PACKAGES_REQUEST:
        case actionsTypesGeneral.FETCH_STRUCTURE_ASSESSMENT_PACKAGES_REQUEST:
            return { ...state, loading: true }
        case 'FETCH_COMPANIES_SUCCESS':
            return { ...state, loading: false, companies: action.companies }
        case 'FETCH_COMPANIES_FAILURE':
        case actionsTypesGeneral.FETCH_STRUCTURE_ASSESSMENT_PACKAGES_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }
        case actionsTypesGeneral.FETCH_COMPANY_PACKAGES_SUCCESS:
        case actionsTypesGeneral.FETCH_STRUCTURE_ASSESSMENT_PACKAGES_SUCCESS:
            return { ...state, loading: false, companyPackages: action.companyPackages }
        default:
            return state
    }
}

export const isLoadingGeneral = (state) => {
    return state.general.loading
}

export const getCompanies = (state) => {
    return state.general.companies
}

export const getCompanyPackages = (state) => {
    return state.general.companyPackages
}

export default general
