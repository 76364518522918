import React from 'react'
import { connect } from 'react-redux'

import InputTextHTML5 from '../../../shared/Form/InputTextHTML5'
import { getInspectionObservations, inspectionFinished, inspectionSubmitted, isOperationalAssessment} from '../../../store/inspection/reducers'
import { showColaboratorInputs } from '../../../store/assessmentData/reducers'
import { updateInspectionObservationByType, inspectionObservationTypes } from '../../../store/inspection/actions'

const mapStateToProps = (state) => ({
    observations: getInspectionObservations(state),
    inspectionFinished: inspectionFinished(state),
    inspectionSubmitted: inspectionSubmitted(state),
    observationsRequired: isOperationalAssessment(state),
    showColaboratorInputs: showColaboratorInputs(state),
})

const mapDispatchToProps = (dispatch) => ({
    updateInspectionObservation: (observationType, observationValue) => {
        dispatch(updateInspectionObservationByType(observationType, observationValue))
    },
})

const InspectionObservations = (props) => {
    const { observations, updateInspectionObservation, inspectionFinished, inspectionSubmitted, observationsRequired, showColaboratorInputs } = props
    const { inspectorObservations, inspectedObservations, proposedImprovements } = observations

    const updateObservations = (observationType, observationValue) => {
        updateInspectionObservation(observationType, observationValue)
    }

    return (
        <section className="admin__body">
            <div className="admin__panel">
                <h1 className="panel__title">Observaciones y mejoras</h1>
                <div className="panel__body">
                    <ul className="form__body">
                        <li className="form__line">
                            <InputTextHTML5
                                type="textarea"
                                required={observationsRequired}
                                label="Observaciones del responsable"
                                placeholder={observationsRequired?"Introduzca las observaciones del responsable":"Introduzca las observaciones del responsable, si las tiene"}
                                id="inspectorObservations"
                                name="inspectorObservations"
                                defaultValue={inspectorObservations}
                                max="255"
                                onChange={(newInspectorObservations) => {
                                    updateObservations(inspectionObservationTypes.INSPECTOR_OBSERVATIONS, newInspectorObservations)
                                }}
                                readOnly={inspectionSubmitted || inspectionFinished}
                            />
                        </li>
                        {showColaboratorInputs && (
                         <li className="form__line">
                            <InputTextHTML5
                                type="textarea"
                                label="Observaciones del colaborador"
                                placeholder="Introduzca las observaciones del colaborador, si las tiene"
                                id="inspectedObservations"
                                name="inspectedObservations"
                                defaultValue={inspectedObservations}
                                max="255"
                                onChange={(newInspectedObservations) => {
                                    updateObservations(inspectionObservationTypes.INSPECTED_OBSERVATIONS, newInspectedObservations)
                                }}
                                readOnly={inspectionFinished}
                            />
                        </li>)}
                        <li className="form__line">
                            <InputTextHTML5
                                type="textarea"
                                required={observationsRequired}
                                label="Mejoras propuestas"
                                placeholder={observationsRequired?"Introduzca las mejoras propuestas":"Introduzca las mejoras propuestas si las tiene"}
                                id="proposedImprovements"
                                name="proposedImprovements"
                                defaultValue={proposedImprovements}
                                max="255"
                                onChange={(newProposedImprovements) => {
                                    updateObservations(inspectionObservationTypes.PROPOSED_IMPROVEMENTS, newProposedImprovements)
                                }}
                                readOnly={inspectionFinished}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionObservations)
