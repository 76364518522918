import React from 'react'
import AssessmentQuestionnaireGeneralTab from '../../components/assessmentQuestionnaireTabs/AssessmentQuestionnaireGeneralTab'
import { questionnaireTypes } from '../../store/assessmentQuestionnaireManagement/reducers'
import Spinner from '../../shared/Spinner'
import AssessmentQuestionnaireItemsScoresTab from '../../components/assessmentQuestionnaireTabs/AssessmentQuestionnaireItemsScoresTab'
import ButtonTab from '../../shared/ButtonTab'

const tabSettings = {
    general: {
        key: 'general',
        component: AssessmentQuestionnaireGeneralTab,
        name: 'General',
        showOnCreating: true
    },
    itemsScores: {
        key: 'itemsScores',
        component: AssessmentQuestionnaireItemsScoresTab,
        name: 'Elementos de valoración',
        showOnCreating: false
    }
}

export default class AssessmentQuestionnaireManagementScreen extends React.Component {
    defaultState = {
        currentTab: tabSettings.general.key,
        questionnaireData: {
            title: '',
            description: '',
            minScore: 1,
            enabled: true,
            type: questionnaireTypes.EXTERNAL
        }
    }
    constructor(props) {
        super(props)
        this.state = {
            ...this.defaultState
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.resetAssessmentQuestionnaireReducer()
        if (this.props.questionnaireId) {
            this.props.fetchAssessmentQuestionnaire(this.props.questionnaireId)
        }
        // else {
        //     this.props.resetAssessmentQuestionnaireReducer()
        // }
        this.mapPropToSate()
    }

    componentDidUpdate(prevProps) {
        this.mapPropToSate(prevProps)
    }

    componentWillUnmount() {
        this.props.resetAssessmentQuestionnaireReducer()
    }

    mapPropToSate(prevProps = null) {
        if (this.props.questionnaireId && !this.props.questionnaireData && JSON.stringify(this.state) !== JSON.stringify(this.defaultState)) {
            this.setState({
                ...this.defaultState
            })
            return
        }

        if (
            this.props.questionnaireData &&
            (!prevProps || !prevProps.questionnaireData || JSON.stringify(this.props.questionnaireData) !== JSON.stringify(prevProps.questionnaireData))
        ) {
            this.setState({
                questionnaireData: {
                    title: this.props.questionnaireData.title,
                    description: this.props.questionnaireData.description,
                    minScore: this.props.questionnaireData.minScoreSuccess,
                    enabled: this.props.questionnaireData.enabled !== undefined ? this.props.questionnaireData.enabled : true,
                    type: this.props.questionnaireData.type ? this.props.questionnaireData.type : questionnaireTypes.EXTERNAL
                }
            })
        }
    }

    onHandleChangeQuestionnaireDataInput = (key, value) => {
        this.setState(prevState => {
            return {
                ...prevState,
                questionnaireData: {
                    ...prevState.questionnaireData,
                    [key]: value
                }
            }
        })
    }

    onHandleChangeQuestionnaireCompanyInput = newCompanies => {
        this.setState(prevState => {
            return {
                ...prevState,
                questionnaireCompanies: newCompanies
            }
        })
    }

    onHandleChangeQuestionnaireItems = (key, value) => {}

    onHandleChangeQuestionnaireScores = (key, value) => {}

    onHandleUpdateOrCreateAssessmentQuestionnaireData = () => {
        if (this.props.questionnaireId) {
            this.props.updateAssessmentQuestionnaire(
                this.props.questionnaireId,
                this.state.questionnaireData.title,
                this.state.questionnaireData.description,
                this.state.questionnaireData.minScore,
                this.state.questionnaireData.enabled,
                this.state.questionnaireData.type
            )
        } else {
            this.props.createAssessmentQuestionnaire(
                this.state.questionnaireData.title,
                this.state.questionnaireData.description,
                this.state.questionnaireData.minScore,
                this.state.questionnaireData.enabled,
                this.state.questionnaireData.type
            )
        }
    }

    render() {
        const ComponentTabToRender = tabSettings[this.state.currentTab].component
        const { errors, questionnaireId, deleteAssessmentQuestionnaire } = this.props

        return (
            <div>
                <section className="admin__body">
                    {this.props.isLoading && <Spinner />}
                    <div className="admin__panel">
                        <h1 className="panel__title">Detalles del cuestionario</h1>
                        <div className="panel__body">
                            {Object.keys(tabSettings).map((key, i) => (
                                <ButtonTab
                                    key={key}
                                    disabled={tabSettings[key].showOnCreating === false && (this.props.questionnaireId === null || this.props.questionnaireId === undefined)}
                                    active={key === this.state.currentTab}
                                    onClick={e => {
                                        this.setState({ currentTab: key })
                                    }}
                                >
                                    <span> {tabSettings[key].name}</span>
                                </ButtonTab>
                            ))}
                        </div>
                        <ComponentTabToRender
                            errors={errors}
                            questionnaireId={questionnaireId}
                            questionnaireData={this.state.questionnaireData}
                            questionnaireItems={this.state.questionnaireItems}
                            questionnaireScores={this.state.questionnaireScores}
                            questionnaireCompanies={this.state.questionnaireCompanies}
                            onHandleChangeQuestionnaireDataInput={this.onHandleChangeQuestionnaireDataInput}
                            onHandleChangeQuestionnaireItems={this.onHandleChangeQuestionnaireItems}
                            onHandleChangeQuestionnaireScores={this.onHandleChangeQuestionnaireScores}
                            onSubmitQuestionnaireData={this.onHandleUpdateOrCreateAssessmentQuestionnaireData}
                            onHandleDeleteQuestionnaire={deleteAssessmentQuestionnaire}
                        />
                    </div>
                </section>
            </div>
        )
    }
}
