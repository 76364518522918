import React from 'react'
import { isAuthenticated, canDoInspection, canViewHistoryInspections, canAdminQuestionnairePackages, canDoInspectionStructureAssessment } from '../store/auth/reducers'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { routesConst } from '../Routes'

const mapStateToProps = (state) => ({
    userIsAuthenticated: isAuthenticated(state),
    userCanDoInspection: canDoInspection(state),
    userCanDoInspectionStructureAssessment: canDoInspectionStructureAssessment(state),
    userCanViewHistoryInspections: canViewHistoryInspections(state),
    userCanAdminQuestionnairePackages: canAdminQuestionnairePackages(state),
})

const mapDispatchToProps = (dispatch) => ({})

const HomeScreen = ({ userIsAuthenticated, userCanDoInspection, userCanDoInspectionStructureAssessment, userCanViewHistoryInspections, userCanAdminQuestionnairePackages }) => {
    let history = useHistory()

    return (
        <div>
            <section className="admin__head">
                <div className="admin__profile">
                    <div className="profile__data">
                        <p className="data__title">Bienvenido al módulo de evaluaciones</p>
                    </div>
                </div>
            </section>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ul className={'home__panel'}>
                    {userIsAuthenticated && userCanDoInspection && (
                        <li
                            className="link__home"
                            onClick={() => {
                                history.push(routesConst.createInspection)
                            }}
                        >
                            <div>
                                <span className="link__home__label">Evaluación operativa</span>
                            </div>
                        </li>
                    )}
                    {userIsAuthenticated && userCanDoInspectionStructureAssessment && (
                        <li
                            className="link__home"
                            onClick={() => {
                                history.push(routesConst.createStructureAssessment)
                            }}
                        >
                            <div>
                                <span className="link__home__label">Evaluación estructura</span>
                            </div>
                        </li>
                    )}
                    {userIsAuthenticated && (
                        <li
                            className="link__home"
                            onClick={() => {
                                history.push(routesConst.historyInspections)
                            }}
                        >
                            <div>
                                {/* <i className="fas fa-history" /> */}

                                <span className="link__home__label">Histórico de evaluaciones</span>
                            </div>
                        </li>
                    )}
                    {userIsAuthenticated && userCanAdminQuestionnairePackages && (
                        <li
                            className="link__home"
                            onClick={() => {
                                history.push(routesConst.assessmentQuestionaires)
                            }}
                        >
                            <div>
                                {/* <i className="fas fa-history" /> */}

                                <span className="link__home__label">Configuración de cuestionarios</span>
                            </div>
                        </li>
                    )}

                    {userIsAuthenticated && userCanAdminQuestionnairePackages && (
                        <li
                            className="link__home"
                            onClick={() => {
                                history.push(routesConst.assessmentPackages)
                            }}
                        >
                            <div>
                                {/* <i className="fas fa-history" /> */}

                                <span className="link__home__label">Configuración de paquetes</span>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
