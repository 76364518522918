import React, { useState, useEffect } from 'react'
import Form from '../../shared/Form'
import Button from '../../shared/Button'
import { FormHtml5Select } from '../../shared/Form/html5/FormHtml5Select'
import { parseOptionsListIdNameToReactSelect } from '../../shared/Helper'

const canSendForm = formData => {
    return formData.companyId !== null && formData.companyId !== undefined
}

export const CompanySelector = ({ handleSubmit, inspectionIdentificationSelectorValues, companies }) => {
    const [currentCompanyValue, setCurrentCompanyValue] = useState(null)
    const [currentCompanyName, setCurrentCompanyName] = useState(null)

    useEffect(() => {
        if (inspectionIdentificationSelectorValues) {
            setCurrentCompanyValue(inspectionIdentificationSelectorValues.companyId)
        }
    }, [inspectionIdentificationSelectorValues])

    const isValidFormData = canSendForm({
        companyId: currentCompanyValue
    })

    const companiesMapped = parseOptionsListIdNameToReactSelect(companies)

    return (
        <Form
            onSubmit={e => {
                e.preventDefault()
                handleSubmit({ companyId: currentCompanyValue, companyName: currentCompanyName })
            }}
            autoComplete="off"
            title="Seleccione la empresa"
        >
            <ul className="form__body">
                <li className="form__line">
                    <FormHtml5Select
                        label="Empresa"
                        options={companiesMapped}
                        value={currentCompanyValue}
                        onChangeCallback={newCompanySelectedValue => {
                            if (newCompanySelectedValue) {
                                setCurrentCompanyValue(newCompanySelectedValue.value)
                                setCurrentCompanyName(newCompanySelectedValue.label)
                            }
                        }}
                    />

                    <Button type="submit" disabled={!isValidFormData}>
                        Siguiente
                    </Button>
                </li>
            </ul>
        </Form>
    )
}

export default CompanySelector
