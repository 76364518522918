import React from 'react'
import AssessmentBlock from './AssessmentBlock'

class AssessmentPackage extends React.Component {
    render() {
        const { assessmentPackage } = this.props
        if (!assessmentPackage) return null

        return (
            <div>
                <section className="admin__body">
                    <div className="admin__panel">
                        <h1 className="panel__title">{assessmentPackage.title}</h1>
                        <div className="panel__body">
                            {assessmentPackage.assessmentBlocks &&
                                assessmentPackage.assessmentBlocks.map((assessmentBlock, index) => {
                                    return <AssessmentBlock key={index} assessmentBlock={assessmentBlock} />
                                })}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default AssessmentPackage
