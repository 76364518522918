import React, { Component } from 'react'
class Modal extends Component {
    componentWillUnmount() {
        this.props.onClose()
    }
    render() {
        const { children, onClose, footer, title } = this.props

        return (
            <div className="parent__modal">
                <div className="modal">
                    <h1 className="modal__header">
                        {title}
                        <span className="modal__close" onClick={onClose}>
                            &times;
                        </span>
                    </h1>
                    <ul className="modal__body">
                        <li className="modal__content">{children}</li>
                        {footer && <li className="modal__footer">{footer}</li>}
                    </ul>
                </div>
                <div className="fade__show" />
            </div>
        )
    }
}
export default Modal
