import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchTicket }     from '../../store/inspectionTickets/actions'
import { getFetchedTicket} from '../../store/inspectionTickets/reducers'


const mapStateToProps = (state, ownProps) => {

    return {
        props:ownProps,
        ticketData: getFetchedTicket(state),
    }
}

const mapDispatchToProps = dispatch => {

    return {
        fetchTicket: (inspectionId) => {
            dispatch(fetchTicket(inspectionId))
        },
    }
}


/**
 * Muestra los datos del ticket poseidon generado en la pantalla de historico
 * de evaluaciones
 */
export const TicketDetail = ({props, fetchTicket, ticketData}) => {

    const inspectionId = props.inspectionId;

    useEffect(() => {
        fetchTicket(inspectionId);
        // eslint-disable-next-line
    }, [])

    return (
        <div>
        {ticketData && ticketData.error && (
            <ul className="form__body">
                    <li className="form__line">
                            <strong>{ticketData.error}</strong>
                    </li>
            </ul> 
        )}
        {ticketData && ticketData.ticketId && (
            <ul className="form__body">

                <li className="form__line">
                        <strong>Id Ticket:</strong>  {ticketData && ticketData.ticketId}
                </li>
                <li className="form__line">
                        <strong>Fecha:</strong> {ticketData && ticketData.inspectionFinishedDate}
                </li>
                <li className="form__line">
                        <strong>Creado Por:</strong> {ticketData && ticketData.inspectorName} {ticketData && '('+ticketData.inspectorEmail+')'}
                </li>
                <li className="form__line">
                        <strong>Enviado a:</strong> {ticketData && ticketData.ticketRoleName}
                </li>
                <li className="form__line">
                        <strong>Comentario:</strong> {ticketData && ticketData.ticketToDo}
                </li>
            </ul>        
        )}
        </div>

    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketDetail)