import React from 'react'
import { ObjectKeyValue } from '../Types'

export interface RadioButtonTypes {
    label: string | undefined
    value: string
}

export interface InputNumberPropTypes {
    label: string | undefined
    name: any
    value: any | undefined
    onChange: (newValue: number) => void
    options: Array<RadioButtonTypes>
    readOnly?: boolean
    required?: boolean
    errors?: Array<ObjectKeyValue>
}

let InputRadioGroup = (props: InputNumberPropTypes) => {
    const { label, name, value, options, onChange, readOnly = false, required = false, errors = null } = props

    let input: HTMLInputElement

    const onChangeValue = (val: any) => {
        console.log('val', val.target.value)
        onChange(val.target.value)
    }

    return (
        <div className="form__group">
            <label className="label__input">
                {label} {required ? '*' : ''}: {errors && errors[name] ? <span className="form__error"> {errors[name]}</span> : null}
            </label>
            <div className="form__group--row">
                {options.map((checkbox: RadioButtonTypes, key) => {
                    return (
                        <div className="form__line--row-start" key={`${name}_${key}`}>
                            <input
                                name={name}
                                checked={checkbox.value === value}
                                className={readOnly ? 'form__input_radio_button--readonly' : 'form__input_radio_button'}
                                ref={(node: HTMLInputElement) => (input = node)}
                                value={checkbox.value}
                                type="radio"
                                readOnly={readOnly}
                                required={required}
                                onChange={
                                    onChangeValue
                                    //     debounce(() => {
                                    //     input.value = changeValue() + ''
                                    // }, 1000)
                                }
                            />
                            <label className="label__input">{checkbox.label}</label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default InputRadioGroup
