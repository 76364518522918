import React, { useState, useEffect } from 'react'
import Button from '../../shared/Button'
import { connect } from 'react-redux'

import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import TableServerSide from '../../shared/Table/TableServerSide'
import FormWithoutSection from '../../shared/FormWithoutSection'
import { fetchCustomerContracts } from '../../store/customerContracts/actions'
import { getCustomerContracts, isLoadingCustomerContracts, getCustomerContractsPages } from '../../store/customerContracts/reducers'

import { getDelegationsCustomerCompany, isLoadingDelegations } from '../../store/delegationsCustomerCompany/reducers'
import { fetchCustomerCompanyDelegations } from '../../store/delegationsCustomerCompany/actions'
import FormHtml5Select from '../../shared/Form/html5/FormHtml5Select'

const mapStateToProps = state => ({
    customerDelegations: getDelegationsCustomerCompany(state),
    customerContracts: getCustomerContracts(state),
    isLoading: isLoadingCustomerContracts(state),
    isLoadingDelegations: isLoadingDelegations(state),
    pages: getCustomerContractsPages(state)
})

const mapDispatchToProps = dispatch => ({
    fetchCustomerContractsFilter: (companyId, customerId, paramsReactTable = null) => {
        dispatch(fetchCustomerContracts(companyId, customerId, paramsReactTable))
    },
    fetchCustomerDelegationsOptions: (companyId, customerId) => {
        dispatch(fetchCustomerCompanyDelegations(companyId, customerId))
    }
})

const ContractFilter = ({ setFilters, customerDelegations }) => {
    const [filterParams, setFilterParams] = useState({ contractCode: null, name: null, delegationId: -1 })
    const updateFilterParam = (filterParamKey, filterParamValue) => {
        setFilterParams({ ...filterParams, [filterParamKey]: filterParamValue })
    }

    const onSubmitFilters = () => {
        const filters = [
            { accessor: 'contractCode', value: filterParams.contractCode },
            { accessor: 'name', value: filterParams.name },
            { accessor: 'delegationId', value: filterParams.delegationId }
        ]
        setFilters(filters)
    }

    const hasSomeFilterParam = () => {
        const filterParamsValue = Object.values(filterParams)
        return filterParamsValue.find(filterParamValue => filterParamValue !== undefined && filterParamValue !== null && filterParamValue !== '')
    }

    return (
        <FormWithoutSection
            onSubmit={e => {
                e.preventDefault()
                onSubmitFilters()
            }}
            autoComplete="off"
            title="Filtrar contratos del cliente"
        >
            <ul className="form__body">
                <li className="form__line">
                    <FormHtml5Select
                        label="Delegación"
                        name="delegationId"
                        id="delegationId"
                        options={customerDelegations}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        valueKeyName={'id'}
                        labelKeyName={'name'}
                        value={filterParams.delegationId}
                        onChangeCallback={newDelegationSelected => {
                            if (newDelegationSelected) {
                                updateFilterParam('delegationId', newDelegationSelected.id)
                            }
                        }}
                    />

                    <InputTextHTML5
                        name="name"
                        id="name"
                        label="Código del contrato"
                        type="text"
                        onChange={value => {
                            updateFilterParam('contractCode', value)
                        }}
                    />

                    <InputTextHTML5
                        name="name"
                        id="name"
                        label="Nombre del contrato"
                        type="text"
                        onChange={value => {
                            updateFilterParam('name', value)
                        }}
                    />

                    <Button type="submit" disabled={!hasSomeFilterParam()}>
                        Buscar contrato
                    </Button>
                </li>
            </ul>
        </FormWithoutSection>
    )
}

const ContractSelector = ({
    handleSubmit,
    inspectionSelectors,
    inspectionIdentificationSelectorValues,
    fetchCustomerContractsFilter,
    fetchCustomerDelegationsOptions,
    customerContracts,
    customerDelegations,
    isLoading,
    pages
}) => {
    const companyIdSelector = inspectionSelectors.companySelector.id
    const customerIdSelector = inspectionSelectors.customerSelector.id
    useEffect(() => {
        fetchCustomerDelegationsOptions(companyIdSelector, customerIdSelector)
        // eslint-disable-next-line
    }, [])

    const setAllFilter = filters => {
        if (myTableComponent && myTableComponent.current) {
            myTableComponent.current.setAllFilterValues(filters)
        }
    }

    const myTableComponent = React.createRef()
    const myTable = React.createRef()

    return (
        <section className="admin__body">
            <div className="admin__panel">
                <h1 className="panel__title">Seleccione el contrato del cliente</h1>
                <div className="panel__body">
                    <TableServerSide
                        ref={myTableComponent}
                        refReactTable={myTable}
                        data={customerContracts}
                        FiltersComponent={() => <ContractFilter setFilters={setAllFilter} customerDelegations={customerDelegations} />}
                        manual={true}
                        pages={pages}
                        clearFilters={false}
                        onFetchData={reactableState => {
                            fetchCustomerContractsFilter(companyIdSelector, customerIdSelector, reactableState)
                        }}
                        onResetChange={() => {
                            // this.setState({ reset: true })
                        }}
                        filterable
                        columns={[
                            {
                                Header: 'Delegación',
                                accessor: 'delegation',
                                style: { whiteSpace: 'unset' },
                                filterable: false
                            },
                            {
                                Header: 'Código contrato',
                                accessor: 'contractCode',
                                filterable: false,
                                style: { whiteSpace: 'unset' },
                                maxWidth: 185
                                // Cell: ({ original }) => {
                                //     return <span>{original.contractCode}</span>
                                // }
                            },
                            {
                                Header: 'Nombre contrato',
                                accessor: 'name',
                                style: { whiteSpace: 'unset' },
                                filterable: false,
                                id: 'name'
                            }
                        ]}
                        loading={isLoading}
                        getTrProps={(state, rowInfo, original) => {
                            let cursor = rowInfo ? 'pointer' : 'default'
                            return {
                                onClick: () => {
                                    if (!rowInfo || !rowInfo.original) return
                                    handleSubmit({
                                        contractId: rowInfo.original.contractId,
                                        contractName: rowInfo.original.name,
                                        delegationId: rowInfo.original.delegationId,
                                        delegationName: rowInfo.original.delegation
                                    })
                                },
                                style: { cursor: cursor }
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContractSelector)
