import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import Modal from '../../shared/Modal'
import Button from '../../shared/Button'

class InspectorSignature extends React.Component {
    state = {
        errors: null,
    }

    sigCanvas = {}
    clearSignature = () => {
        this.sigCanvas.clear()
    }

    componentDidMount() {
        setTimeout(
            function () {
                if (this.props.inspectorSignature && this.props.inspectorSignature.signature) {
                    this.sigCanvas.fromDataURL(this.props.inspectorSignature.signature)
                }
            }.bind(this),
            500
        )
    }

    onHandleApprovalResult = (result) => {
        this.setState({
            approvalResult: result,
            warningMessage: null,
        })
    }

    setErrorMessage = (errorKey, errorMessage) => {
        this.setState((prevState) => {
            return {
                errors: {
                    ...prevState.errors,
                    [errorKey]: errorMessage,
                },
            }
        })
    }
    resetErrors = () => {
        this.setState({
            errors: {},
        })
    }
    onHandleSaveSignature = () => {
        this.resetErrors()

        let hasError = false

        if (this.sigCanvas.isEmpty()) {
            this.setErrorMessage('signature', '¡Debe de firmar!')
            hasError = true
        }
        if (hasError) return

        const signature = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png')
        this.props.saveSignature(signature, this.state.approvalResult)
    }

    render() {
        const { close } = this.props

        //Para móvil
        let widthCanvan = 795
        if (window.innerWidth < 480) {
            widthCanvan = 'auto'
        }

        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    onClose={this.props.close}
                    title="Firma del responsable"
                    footer={
                        <React.Fragment>
                            <Button type="submit" onClick={this.onHandleSaveSignature}>
                                He terminado
                            </Button>
                            <Button type="submit" className={'btnCrear'} onClick={this.clearSignature}>
                                Repetir firma
                            </Button>
                            <Button type="submit" onClick={close}>
                                Cancelar
                            </Button>
                        </React.Fragment>
                    }
                >
                    <div>
                        <form autoComplete="off">
                            <ul className="form__body">
                                <li className="form__line">
                                    <div style={{ border: '1px solid' }}>
                                        {this.state.errors && this.state.errors.signature && <span className="form__error"> {this.state.errors.signature}</span>}
                                        <SignatureCanvas
                                            penColor="blue"
                                            clearOnResize={
                                                false //maxWidth={4} //minWidth={1}
                                            }
                                            ref={(ref) => {
                                                this.sigCanvas = ref
                                            }}
                                            canvasProps={{ width: widthCanvan, height: 280, className: 'signCanvas' }}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default InspectorSignature
