import React, { useState, useEffect } from 'react'
import FormWithoutSection from '../../shared/FormWithoutSection'
import Button from '../../shared/Button'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import InputNumber from '../../shared/Form/InputNumber'

export const ScoreForm = ({ score, onSubmit }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        score: 0
    })

    useEffect(() => {
        if (score) {
            setFormData({
                title: score.title,
                description: score.description,
                score: score.score
            })
        }
        // eslint-disable-next-line
    }, [])

    const onHandleChangeQuestionnaireDataInput = (input, value) => {
        setFormData({
            ...formData,
            [input]: value
        })
    }
    return (
        <FormWithoutSection
            onSubmit={e => {
                e.preventDefault()
                onSubmit(formData.title,formData.description, formData.score)
            }}
            autoComplete="off"
            title="Item"
        >
            <ul className="form__body">
                <li className="form__line">
                    <InputTextHTML5
                        type="text"
                        label="Título"
                        required={true}
                        placeholder="Título de la puntuación"
                        id="scoreTitle"
                        name="scoreTitle"
                        defaultValue={formData.title}
                        max="255"
                        onChange={newTitleValue => {
                            onHandleChangeQuestionnaireDataInput('title', newTitleValue)
                        }}
                    />
                </li>
                <li className="form__line">
                    <InputTextHTML5
                        type="text"
                        label="Descripción"
                        //required={true}
                        placeholder="Descripción de la puntuación"
                        id="scoreDescription"
                        name="scoreDescription"
                        defaultValue={formData.description}
                        max="255"
                        onChange={newDescriptionValue => {
                            onHandleChangeQuestionnaireDataInput('description', newDescriptionValue)
                        }}
                    />
                </li>
                <li className="form__line">
                    <InputNumber
                        label="Puntuación"
                        value={formData.score}
                        change={qty => {
                            onHandleChangeQuestionnaireDataInput('score', qty)
                        }}
                        required={true}
                        step={1}
                        min={0}
                        max={100}
                    />
                </li>

                <li className="form__line">
                    <Button type="submit">Añadir</Button>
                </li>
            </ul>
        </FormWithoutSection>
    )
}
