import React from "react";
import axios from "axios";

const ApiLocation = process.env.REACT_APP_HOST_API;

const CsvExport = props => {
  const { filterParams } = props;

  const exportExcel = () => {
    axios({
      url: ApiLocation + "inspections/csv",
      method: "GET",
      responseType: "json",
      params: filterParams
    })
      .then(response => {
        const link = document.createElement("a");
        link.href = response.data.file;
        link.download = response.data.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <button className="button__submit mini" onClick={exportExcel}>
      Exportar Excel
    </button>
  );
};

export default CsvExport;
