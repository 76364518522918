import * as Api from './api'
import { errorHandler } from '../helperApi'

export const actionsTypes = {
    FETCH_HISTORY_INSPECTIONS_FILTERS_REQUEST: 'FETCH_HISTORY_INSPECTIONS_FILTERS_REQUEST',
    FETCH_HISTORY_INSPECTIONS_FILTERS_SUCCESS: 'FETCH_HISTORY_INSPECTIONS_FILTERS_SUCCESS',
    FETCH_HISTORY_INSPECTIONS_FILTERS_FAILURE: 'FETCH_HISTORY_INSPECTIONS_FILTERS_FAILURE'
}

export const fetchHistoryInspectionFilters = (companyId, customerId, contractId, serviceId, inspectedId, inspectionDateFrom, inspectionDateUntil, assessmentType,assessmentPackage) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.FETCH_HISTORY_INSPECTIONS_FILTERS_REQUEST, companyId, customerId, contractId, serviceId, inspectedId, inspectionDateFrom, inspectionDateUntil , assessmentType,assessmentPackage})
    Api.fetchHistoryInspectionFiltersApi(companyId, customerId, contractId, serviceId, inspectedId, inspectionDateFrom, inspectionDateUntil, assessmentType,assessmentPackage)
        .then(result => {
            const inspectors = result.data.inspectors
            const companies = result.data.companies
            const delegations = result.data.delegations
            const packages = result.data.packages
            dispatch({
                type: actionsTypes.FETCH_HISTORY_INSPECTIONS_FILTERS_SUCCESS,
                inspectors,
                companies,
                delegations,
                packages
            })
        })
        .catch(err => {
            dispatch({
                type: actionsTypes.FETCH_HISTORY_INSPECTIONS_FILTERS_FAILURE
            })
            errorHandler(dispatch, err.response, err)
        })
}
