import * as Api from './api'
import { errorHandler } from '../helperApi'
import { arrayToObject } from '../HelperActions'

export const actionsTypes = {
    RESET_CUSTOMERS: 'RESET_CUSTOMERS',
    FETCH_CUSTOMERS_REQUEST: 'FETCH_CUSTOMERS_REQUEST',
    FETCH_CUSTOMERS_SUCCESS: 'FETCH_CUSTOMERS_SUCCESS',
    FETCH_CUSTOMERS_FAILURE: 'FETCH_CUSTOMERS_FAILURE',

    FETCH_CUSTOMERS_LIVE_SEARCH_REQUEST: 'FETCH_CUSTOMERS_LIVE_SEARCH_REQUEST',
    FETCH_CUSTOMERS_LIVE_SEARCH_SUCCESS: 'FETCH_CUSTOMERS_LIVE_SEARCH_SUCCESS',
    FETCH_CUSTOMERS_LIVE_SEARCH_FAILURE: 'FETCH_CUSTOMERS_LIVE_SEARCH_FAILURE',

    CLEAR_CUSTOMERS_LIVE_SEARCH_RESULTS: 'CLEAR_CUSTOMERS_LIVE_SEARCH_RESULTS',
}

export const resetCustomers = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_CUSTOMERS })
}

export const fetchCustomers = (companyId, reactTableState) => (dispatch, getState) => {
    const filtered = reactTableState && reactTableState.filtered ? arrayToObject(reactTableState.filtered) : null
    const sorted = reactTableState && reactTableState.sorted ? reactTableState.sorted : null
    const pageSize = reactTableState && reactTableState.pageSize ? reactTableState.pageSize : 5
    const page = reactTableState ? parseInt(reactTableState.page) + 1 : 1

    const params = {
        page,
        sorted,
        ...filtered,
        pageSize
    }

    dispatch({ type: actionsTypes.FETCH_CUSTOMERS_REQUEST, params })

    return Api.fetchCustomersApi(companyId, params)
        .then(response => {
            const pages = Math.ceil(response.data.customers.total / response.data.customers.pageSize)
            dispatch({
                type: actionsTypes.FETCH_CUSTOMERS_SUCCESS,
                customers: response.data.customers.data,
                pages
            })
        })
        .catch(err => {
            const actionError = error => {
                dispatch({ type: actionsTypes.FETCH_CUSTOMERS_FAILURE, error: 'No se pudieron obtener los clientes' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchCustomerLiveSearch = (companyId, customerFilter) => (dispatch, getState) => {
    const params = {
        customerFilter,
        companyId
    }
    dispatch({ type: actionsTypes.FETCH_CUSTOMERS_LIVE_SEARCH_REQUEST, params, companyId })

    return Api.fetchCustomersLiveSearchApi(params)
        .then(response => {
            const customers = response.data
            dispatch({
                type: actionsTypes.FETCH_CUSTOMERS_LIVE_SEARCH_SUCCESS,
                customers
            })

            return Promise.resolve(customers)
        })
        .catch(err => {
            const actionError = error => {
                dispatch({ type: actionsTypes.FETCH_CUSTOMERS_LIVE_SEARCH_FAILURE, error: 'No se pudieron obtener los clientes' })
            }

            errorHandler(dispatch, err.response, err, actionError)
            return Promise.reject([])
        })
}

export const clearCustomerLiveSearchResults = () => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.CLEAR_CUSTOMERS_LIVE_SEARCH_RESULTS,
    })
}
