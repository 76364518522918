import React from 'react'
import { connect } from 'react-redux'
import { isLoadingGeneral } from '../../store/general/reducers'
import {
    changeInspectionStep,
    resetStructureAssessment,
    setStructureAssessmentSelectorByTypes,
    resetStructureAssessmentSelectorByTypes,
    updateStructureAssessmentSelectors,
} from '../../store/inspection/actions'
import { getInspectionData, getCurrentStep, isLoadingInspection, getStructureAssessmentSelectors } from '../../store/inspection/reducers'
import { routesConst } from '../../Routes'
import StructureAssessmentScreen from './StructureAssessmentScreen'
import { resetAssessmentPackage } from '../../store/assessmentData/actions'

const mapStateToProps = (state) => ({
    currentStep: getCurrentStep(state),
    inspectionData: getInspectionData(state),
    inspectionSelectors: getStructureAssessmentSelectors(state),
    isLoading: isLoadingGeneral(state) || isLoadingInspection(state),
})

const mapDispatchToProps = (dispatch) => ({
    resetInspection: () => {
        dispatch(resetStructureAssessment())
    },
    setInspectionSelectorByTypes: (inspectionSelectorsValues) => {
        dispatch(setStructureAssessmentSelectorByTypes(inspectionSelectorsValues))
    },
    resetInspectionSelectorsByTypes: (inspectionSelectorsToReset) => {
        dispatch(resetStructureAssessmentSelectorByTypes(inspectionSelectorsToReset))
    },
    changeStep: (newStep) => {
        dispatch(changeInspectionStep(newStep))
    },
    updateInspectionSelectorsRequest: (inspectionId, packageId, workerId) => {
        dispatch(updateStructureAssessmentSelectors(inspectionId, packageId, workerId))
    },
    resetAssessmentPackage: () => {
        dispatch(resetAssessmentPackage())
    },
})

class UpdateStructureAssessmentContainer extends React.Component {
    componentDidMount() {
        if (!this.props.inspectionData.id) {
            this.props.history.push(routesConst.home)
        }
    }
    render() {
        return <StructureAssessmentScreen {...this.props} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStructureAssessmentContainer)
