import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Form from '../../shared/Form'
import Button from '../../shared/Button'
import { FormHtml5Select } from '../../shared/Form/html5/FormHtml5Select'
import { parseOptionsListIdNameToReactSelect } from '../../shared/Helper'
import { getCompanyPackages, isLoadingGeneral } from '../../store/general/reducers'
import { fetchCompanyPackages, fetchStructureAssessmentPackages } from '../../store/general/actions'

const mapStateToProps = (state) => ({
    packages: getCompanyPackages(state),
    isLoading: isLoadingGeneral(state),
})

const mapDispatchToProps = (dispatch) => ({
    fetchPackagesByCompany: (companyId) => {
        dispatch(fetchCompanyPackages(companyId))
    },
    fetchStructureAssessmentPackages: () => {
        dispatch(fetchStructureAssessmentPackages())
    },
})

const canSendForm = (formData) => {
    return formData.packageId !== null && formData.packageId !== undefined
}

const PackageSelector = ({
    handleSubmit,
    inspectionSelectors,
    inspectionIdentificationSelectorValues,
    packages,
    fetchPackagesByCompany,
    fetchStructureAssessmentPackages,
    packageType = null,
}) => {
    const [currentPackageValue, setCurrentPackageValue] = useState(null)
    const [currentPackageName, setCurrentPackageName] = useState(null)

    useEffect(() => {
        if (packageType === 'STRUCTURE') {
            fetchStructureAssessmentPackages()
        } else {
            const companyIdSelector = inspectionSelectors.companySelector.id
            fetchPackagesByCompany(companyIdSelector)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (inspectionIdentificationSelectorValues) {
            setCurrentPackageValue(inspectionSelectors.packageSelector.id)
        }
        // eslint-disable-next-line
    }, [inspectionSelectors])

    const isValidFormData = canSendForm({
        packageId: currentPackageValue,
    })

    const packagesMapped = packages ? parseOptionsListIdNameToReactSelect(packages) : []

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault()
                handleSubmit({ packageId: currentPackageValue, packageName: currentPackageName })
            }}
            autoComplete="off"
            title="Seleccione el paquete"
        >
            <ul className="form__body">
                <li className="form__line">
                    <FormHtml5Select
                        label="Paquete"
                        options={packagesMapped}
                        value={currentPackageValue}
                        onChangeCallback={(newPackageSelectedValue) => {
                            if (newPackageSelectedValue) {
                                setCurrentPackageValue(newPackageSelectedValue.value)
                                setCurrentPackageName(newPackageSelectedValue.label)
                            }
                        }}
                    />

                    <Button type="submit" disabled={!isValidFormData}>
                        Continuar
                    </Button>
                </li>
            </ul>
        </Form>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageSelector)
