import { actionsTypes as actionsTypesHistoryInspectionFilters } from './actions'

const initState = {
    loading: false,
    companies: [],
    inspectors: [],
    delegations: [],
    packages:[]
}

const historyInspectionFilters = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesHistoryInspectionFilters.FETCH_HISTORY_INSPECTIONS_FILTERS_REQUEST:
            return { ...state, loading: true }
        case actionsTypesHistoryInspectionFilters.FETCH_HISTORY_INSPECTIONS_FILTERS_SUCCESS:
            return { ...state, loading: false, inspectors: action.inspectors, companies: action.companies, delegations: action.delegations, packages:action.packages }
        case actionsTypesHistoryInspectionFilters.FETCH_HISTORY_INSPECTIONS_FILTERS_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }

        default:
            return state
    }
}

export const isLoadingHistoryInspectionFilters = state => {
    return state.historyInspectionFilters.loading
}

export const getHistoryInspectionsFilterInspectors = state => {
    return state.historyInspectionFilters.inspectors
}

export const getHistoryInspectionsFilterCompanies = state => {
    return state.historyInspectionFilters.companies
}

export const getHistoryInspectionsFilterDelegations = state => {
    return state.historyInspectionFilters.delegations
}
export const getHistoryInspectionsFilterPackages = state => {
    return state.historyInspectionFilters.packages
}

export default historyInspectionFilters
