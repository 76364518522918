import * as Api from './api'
import { errorHandler } from '../helperApi'
import { arrayToObject } from '../HelperActions'

export const actionsTypes = {
    RESET_WATCHMAN: 'RESET_WATCHMAN',
    FETCH_WATCHMEN_REQUEST: 'FETCH_WATCHMEN_REQUEST',
    FETCH_WATCHMEN_SUCCESS: 'FETCH_WATCHMEN_SUCCESS',
    FETCH_WATCHMEN_FAILURE: 'FETCH_WATCHMEN_FAILURE',

    FETCH_WATCHMEN_LIVE_SEARCH_REQUEST: 'FETCH_WATCHMEN_LIVE_SEARCH_REQUEST',
    FETCH_WATCHMEN_LIVE_SEARCH_SUCCESS: 'FETCH_WATCHMEN_LIVE_SEARCH_SUCCESS',
    FETCH_WATCHMEN_LIVE_SEARCH_FAILURE: 'FETCH_WATCHMEN_LIVE_SEARCH_FAILURE',

    RESET_WORKERS: 'RESET_WORKERS',
    FETCH_WORKERS_REQUEST: 'FETCH_WORKERS_REQUEST',
    FETCH_WORKERS_SUCCESS: 'FETCH_WORKERS_SUCCESS',
    FETCH_WORKERS_FAILURE: 'FETCH_WORKERS_FAILURE',
}

export const resetWatchman = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_WATCHMAN })
}

export const resetWorkers = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_WORKERS })
}

export const fetchWatchmen = (companyId, reactTableState) => (dispatch, getState) => {
    const filtered = reactTableState && reactTableState.filtered ? arrayToObject(reactTableState.filtered) : null
    const sorted = reactTableState && reactTableState.sorted ? reactTableState.sorted : null
    const pageSize = reactTableState && reactTableState.pageSize ? reactTableState.pageSize : 5
    const page = reactTableState ? parseInt(reactTableState.page) + 1 : 1

    const params = {
        page,
        sorted,
        filtered,
        pageSize,
    }

    dispatch({ type: actionsTypes.FETCH_WATCHMEN_REQUEST, params })

    return Api.fetchWatchmenApi(companyId, params)
        .then((response) => {
            dispatch({
                type: actionsTypes.FETCH_WATCHMEN_SUCCESS,
                watchmen: response.data.watchmen.data,
                pages: Math.ceil(response.data.watchmen.total / response.data.watchmen.pageSize),
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.FETCH_WATCHMEN_FAILURE, error: 'No se pudieron obtener los colaboradores' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchWatchmenLiveSearch = (watchmanFilter) => (dispatch, getState) => {
    const params = {
        watchmanFilter,
    }
    dispatch({ type: actionsTypes.FETCH_WATCHMEN_LIVE_SEARCH_REQUEST, params })

    return Api.fetchWatchmenLiveSearchApi(params)
        .then((response) => {
            const watchmen = response.data
            dispatch({
                type: actionsTypes.FETCH_WATCHMEN_LIVE_SEARCH_SUCCESS,
                watchmen,
            })

            return Promise.resolve(watchmen)
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.FETCH_WATCHMEN_LIVE_SEARCH_FAILURE, error: 'No se pudieron obtener los colaboradores' })
            }

            errorHandler(dispatch, err.response, err, actionError)
            return Promise.reject([])
        })
}

export const fetchWorkers = (reactTableState) => (dispatch, getState) => {
    const filtered = reactTableState && reactTableState.filtered ? arrayToObject(reactTableState.filtered) : null
    const sorted = reactTableState && reactTableState.sorted ? reactTableState.sorted : null
    const pageSize = reactTableState && reactTableState.pageSize ? reactTableState.pageSize : 5
    const page = reactTableState ? parseInt(reactTableState.page) + 1 : 1

    const params = {
        page,
        sorted,
        filtered,
        pageSize,
    }

    dispatch({ type: actionsTypes.FETCH_WORKERS_REQUEST, params })

    return Api.fetchWorkersApi(params)
        .then((response) => {
            dispatch({
                type: actionsTypes.FETCH_WORKERS_SUCCESS,
                watchmen: response.data.workers.data,
                pages: Math.ceil(response.data.workers.total / response.data.workers.pageSize),
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.FETCH_WORKERS_FAILURE, error: 'No se pudieron obtener los empleados' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}
