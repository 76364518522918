import React from 'react'
import { Card } from '../../shared/Card'
import { formatDateTime } from '../../shared/HelperDate'
import { translateWords } from '../../Messages'

export const InspectionDetail = ({ inspectionModulesDefinition, inspectionData, inspectionSelectors, onHandleDeleteModule, canDeleteSelectors }) => {
    const deleteUi = (moduleKey, onHandleDelete) => {
        return (
            <div
                onClick={() => {
                    onHandleDelete(moduleKey)
                }}
            >
                <i className="fas fa-trash" />
            </div>
        )
    }

    const generateSubtittle = (keyModuleSelector, onHandleDeleteModule) => {
        if (!canDeleteSelectors) return null

        return deleteUi(keyModuleSelector, onHandleDeleteModule)
    }

    let startGeolocation = ""
    if(inspectionData.startLatitude && inspectionData.startLongitude){
        startGeolocation = `https://maps.google.com/maps?z=12&t=m&q=loc:${inspectionData.startLatitude}+${inspectionData.startLongitude}`
    }
    let endGeolocation = ""
    if(inspectionData.endLatitude && inspectionData.endLongitude){
        endGeolocation = `https://maps.google.com/maps?z=12&t=m&q=loc:${inspectionData.endLatitude}+${inspectionData.endLongitude}`
    }
    let userAgent = inspectionData.userAgent
    const movilePatter = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    let showGeolocation = startGeolocation || endGeolocation
    return (
        <div className="card__container">
            {inspectionData && inspectionData.id && (
                <Card
                    title={`Evaluación ${inspectionData.inspectionCode}`}
                    body={
                        <React.Fragment>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 16 }}>
                                <span>Iniciada: {formatDateTime(inspectionData.creationDate)}</span>
                                <span>Estado: {translateWords(inspectionData.inspectionStatus)}</span>
                                {showGeolocation && (
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 0, margin: '10px 0px' }}>
                                        {startGeolocation && (
                                            <a href={startGeolocation} className='button__submit' style={{ margin: '0px 0px'}} target='_blank' rel="noopener noreferrer">
                                                <i className="fas fa-map-marker-alt"></i>
                                                <span style={{ margin: '0px 5px'}}>Ubicación inicial</span>
                                            </a>
                                        )}
                                        {endGeolocation && (
                                            <a href={endGeolocation} className='button__submit' target='_blank' rel="noopener noreferrer">
                                                <i className="fas fa-map-marker-alt"></i>
                                                <span style={{ margin: '0px 5px'}}>Ubicación final</span>
                                            </a>
                                        )}
                                    </div>  
                                )}
                                {showGeolocation && (
                                    <span>Ubicación obtenida por: {movilePatter.test(userAgent)? 'Móvil' : 'Web'}</span>
                                )}
                            </div>
                        </React.Fragment>
                    }
                />
            )}

            {inspectionSelectors.companySelector && inspectionSelectors.companySelector.id && (
                <Card
                    title="Empresa"
                    subTitle={generateSubtittle(inspectionModulesDefinition.companySelector.key, onHandleDeleteModule)}
                    body={inspectionSelectors.companySelector.name}
                />
            )}

            {inspectionSelectors.customerSelector && inspectionSelectors.customerSelector.id && (
                <Card
                    title="Cliente"
                    subTitle={generateSubtittle(inspectionModulesDefinition.customerSelector.key, onHandleDeleteModule)}
                    body={inspectionSelectors.customerSelector.name}
                />
            )}
            {inspectionSelectors.contractSelector && inspectionSelectors.contractSelector.id && (
                <Card
                    title="Contrato"
                    subTitle={generateSubtittle(inspectionModulesDefinition.contractSelector.key, onHandleDeleteModule)}
                    body={inspectionSelectors.contractSelector.name}
                />
            )}
            {inspectionSelectors.serviceSelector && inspectionSelectors.serviceSelector.id && (
                <Card
                    title="Servicio"
                    subTitle={generateSubtittle(inspectionModulesDefinition.serviceSelector.key, onHandleDeleteModule)}
                    body={inspectionSelectors.serviceSelector.name}
                />
            )}

            {inspectionSelectors.watchmanSelector && inspectionSelectors.watchmanSelector.id && (
                <Card
                    title="Colaborador"
                    subTitle={generateSubtittle(inspectionModulesDefinition.watchmanSelector.key, onHandleDeleteModule)}
                    body={inspectionSelectors.watchmanSelector.name}
                />
            )}
            {inspectionSelectors.packageSelector && inspectionSelectors.packageSelector.id && (
                <Card
                    title="Paquete"
                    subTitle={generateSubtittle(inspectionModulesDefinition.packageSelector.key, onHandleDeleteModule)}
                    body={inspectionSelectors.packageSelector.name}
                />
            )}
            {inspectionSelectors.workerSelector && inspectionSelectors.workerSelector.id && (
                <Card
                    title="Colaborador"
                    subTitle={generateSubtittle(inspectionModulesDefinition.workerSelector.key, onHandleDeleteModule)}
                    body={inspectionSelectors.workerSelector.name}
                />
            )}
        </div>
    )
}
