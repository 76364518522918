import * as React from 'react'

export interface SpinnerProps {}

export interface SpinnerState {}

export default class Spinner extends React.Component<SpinnerProps, SpinnerState> {
    render() {
        return (
            <section className="spinner-section">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce-1" />
                    <div className="sk-child sk-double-bounce-2" />
                </div>
            </section>
        )
    }
}
