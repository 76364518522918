import React from 'react'
import { connect } from 'react-redux'

import PackageQuestionnaires from './PackageQuestionnaires'
import {
    deleteAssessmentPackageQuestionnaire,
    saveAssessmentPackageQuestionnaire,
    fetchAssessmentPackageQuestionnaireAvailables,
    changePackageQuestionnairePosition,
    updatePackageQuestionnairesOrder
} from '../../store/assessmentPackageManagement/actions'
import { getAvailablePackageQuestionnaires, getAssessmentPackagesQuestionnaires } from '../../store/assessmentPackageManagement/reducers'
import { translateWords } from '../../Messages'

const mapStateToProps = state => ({
    packageQuestionnaires: getAssessmentPackagesQuestionnaires(state),
    availablePackageQuestionnaires: getAvailablePackageQuestionnaires(state)
})

const mapDispatchToProps = dispatch => ({
    changePackageQuestionnairePosition: (oldPosition, newPosition) => {
        dispatch(changePackageQuestionnairePosition(oldPosition, newPosition))
    },
    addAssessmentPackageQuestionnaire: (packageId, questionnaireId) => {
        dispatch(saveAssessmentPackageQuestionnaire(packageId, questionnaireId))
    },
    deleteAssessmentPackageQuestionnaire: (packageId, questionnaireId) => {
        dispatch(deleteAssessmentPackageQuestionnaire(packageId, questionnaireId))
    },
    fetchPackageQuestionnaireAvailables: packageId => {
        dispatch(fetchAssessmentPackageQuestionnaireAvailables(packageId))
    },
    updatePackageQuestionnairesOrder: packageId => {
        dispatch(updatePackageQuestionnairesOrder(packageId))
    }
})

class AssessmentPackageQuestionnairesTab extends React.Component {
    state = {
        questionnaireItems: [],
        questionnaireScores: []
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const {
            packageId,
            packageQuestionnaires,
            changePackageQuestionnairePosition,
            fetchPackageQuestionnaireAvailables,
            addAssessmentPackageQuestionnaire,
            deleteAssessmentPackageQuestionnaire,
            updatePackageQuestionnairesOrder,
            availablePackageQuestionnaires
        } = this.props

        const availablePackageQuestionnairesMappedToSelect = availablePackageQuestionnaires
            ? availablePackageQuestionnaires.map(availableQuestionnaire => {
                  return {
                      label: `${availableQuestionnaire.name} (${translateWords(availableQuestionnaire.type)})`,
                      value: availableQuestionnaire.id
                  }
              })
            : []

        return (
            <div>
                <PackageQuestionnaires
                    packageQuestionnaires={packageQuestionnaires}
                    packageQuestionnnaireOptions={availablePackageQuestionnairesMappedToSelect}
                    fetchQuestionnaireAvailables={() => {
                        fetchPackageQuestionnaireAvailables(packageId)
                    }}
                    onSubmitAddPackageQuestionnaire={questionnaireId => {
                        addAssessmentPackageQuestionnaire(packageId, questionnaireId)
                    }}
                    onHandleUpdatePackageQuestionnairePosition={changePackageQuestionnairePosition}
                    onHandleUpdateQuestionnairesPosition={() => {
                        updatePackageQuestionnairesOrder(packageId)
                    }}
                    onDeletePackageQuestionnaire={questionnaireId => {
                        deleteAssessmentPackageQuestionnaire(packageId, questionnaireId)
                    }}
                />
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssessmentPackageQuestionnairesTab)
