import { runPost, fetchGet, runDelete } from '../helperApi'

export const createAssessmentQuestionnaire = body => {
    return runPost(`questionnaire/create`, body)
}

export const updateAssessmentQuestionnaire = (questionnaireId, body) => {
    return runPost(`questionnaire/${questionnaireId}`, body)
}

export const deleteAssessmentQuestionnaire = questionnaireId => {
    return runDelete(`questionnaire/${questionnaireId}`)
}

export const fetchAssessmentQuestionnaireDataApi = questionnaireId => {
    return fetchGet(`questionnaire/${questionnaireId}`)
}

export const saveAssessmentQuestionnaireItemsApi = (questionnaireId, itemsData) => {
    return runPost(`questionnaire/${questionnaireId}/items`, { items: itemsData })
}

export const deleteAssessmentQuestionnaireItemsApi = (questionnaireId, itemId) => {
    return runDelete(`questionnaire/${questionnaireId}/items/${itemId}`)
}

export const saveAssessmentQuestionnaireScoresApi = (questionnaireId, scoresData) => {
    return runPost(`questionnaire/${questionnaireId}/scores`, { scores: scoresData })
}

export const deleteAssessmentQuestionnaireScoresApi = (questionnaireId, itemId) => {
    return runDelete(`questionnaire/${questionnaireId}/scores/${itemId}`)
}
