import React from 'react'
import FormWithoutSection from '../../shared/FormWithoutSection'
import Button from '../../shared/Button'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import InputNumber from '../../shared/Form/InputNumber'
import InputRadioGroup from '../../shared/Form/InputRadioGroup'
import { questionnaireTypes } from '../../store/assessmentQuestionnaireManagement/reducers'

export default class AssessmentQuestionnaireGeneralTab extends React.Component {
    state = {
        questionnaireData: {
            title: '',
            minScore: null,
            enabled: true,
        },
        questionnaireItems: [],
        questionnaireScores: [],
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { questionnaireData, questionnaireId, onHandleChangeQuestionnaireDataInput, onSubmitQuestionnaireData, onHandleDeleteQuestionnaire } = this.props
        return (
            <FormWithoutSection
                onSubmit={(e) => {
                    e.preventDefault()
                    onSubmitQuestionnaireData()
                }}
                autoComplete="off"
                title="Nuevo cuestionario"
            >
                <ul className="form__body">
                    <li className="form__line">
                        <InputTextHTML5
                            type="text"
                            label="Título"
                            required={true}
                            placeholder="Introduzca el título del cuestionario"
                            id="questionnnaireName"
                            name="questionnnaireName"
                            defaultValue={questionnaireData.title}
                            max="255"
                            onChange={(newTitleValue) => {
                                onHandleChangeQuestionnaireDataInput('title', newTitleValue)
                            }}
                        />
                    </li>
                    <li className="form__line">
                        <InputNumber
                            label="Mínima puntuación evaluación satisfactoria"
                            value={questionnaireData.minScore}
                            change={(qty) => {
                                onHandleChangeQuestionnaireDataInput('minScore', qty)
                            }}
                            required={true}
                            step={1}
                            min={1}
                            max={100}
                        />
                    </li>

                    <li className="form__line">
                        <InputRadioGroup
                            label="Habilitado"
                            required={true}
                            name="questionnnaireEnabled"
                            value={questionnaireData.enabled}
                            options={[
                                { label: 'Sí', value: true },
                                { label: 'No', value: false },
                            ]}
                            onChange={(newOptionsChecked) => {
                                const parseValueToBoolean = JSON.parse(newOptionsChecked)
                                onHandleChangeQuestionnaireDataInput('enabled', parseValueToBoolean)
                            }}
                        />
                    </li>

                    <li className="form__line">
                        <InputRadioGroup
                            label="Tipo de cuestionario"
                            required={true}
                            name="questionnnaireType"
                            value={questionnaireData.type}
                            options={[
                                { label: 'Interno (No afecta al cálculo del resultado)', value: questionnaireTypes.INTERNAL },
                                { label: 'Cliente (Afecta al cálculo del resultado)', value: questionnaireTypes.EXTERNAL },
                            ]}
                            onChange={(newOptionsChecked) => {
                                onHandleChangeQuestionnaireDataInput('type', newOptionsChecked)
                            }}
                        />
                    </li>

                    <li className="form__line">
                        <Button type="submit">Guardar</Button>
                        {questionnaireId && (
                            <Button
                                type="cancel"
                                onClick={(e) => {
                                    e.preventDefault()
                                    onHandleDeleteQuestionnaire(questionnaireId)
                                }}
                            >
                                Eliminar cuestionario
                            </Button>
                        )}
                    </li>
                </ul>
            </FormWithoutSection>
        )
    }
}
