import React from 'react'
import Select from 'react-select'

export const FormHtml5Select = ({
    label,
    noMargin = false,
    disabled = false,
    id,
    name,
    value,
    errors,
    onChangeCallback,
    children,
    isMulti = false,
    simpleValue = false,
    required = false,
    options,
    isCancelable = false,
    valueKeyName = 'value',
    labelKeyName = 'label',
    ...rest
}) => {
    let selectValue = isMulti
        ? null
        : { [labelKeyName]: '', [valueKeyName]: '' }
    if (value && !isMulti) {
        let valueSearched = options.find(
            (option) => option[valueKeyName] === value
        )
        selectValue = valueSearched
            ? valueSearched
            : { [labelKeyName]: '', [valueKeyName]: '' }
    } else if (value && isMulti && Array.isArray(value)) {
        selectValue = []
        value.forEach((optionValue) => {
            let valueSearched = options.find(
                (option) => option[valueKeyName] === optionValue
            )

            if (valueSearched) {
                selectValue.push(valueSearched)
            }
            // selectValue = valueSearched ? valueSearched : { [labelKeyName]: '', [valueKeyName]: '' }
        })
    }
    return (
        <div
            className={
                noMargin === true ? 'form__group--noMargin' : 'form__group'
            }
        >
            <label className='label__input'>
                {label && (
                    <React.Fragment>
                        {label} {required ? '*' : ''}
                    </React.Fragment>
                )}
                {errors && errors[name] ? (
                    <span className='form__error'> {errors[name]}</span>
                ) : null}
            </label>

            <Select
                options={options}
                disabled={disabled}
                onChange={(event) => {
                    if (onChangeCallback) onChangeCallback(event)
                }}
                value={selectValue}
                // simpleValue={simpleValue}
                required={required}
                isMulti={isMulti}
                isCancelable={true}
                isClearable={true}
                {...rest}
            />
        </div>
    )
}
export default FormHtml5Select
