export const getPosition = ()=>{
    return new Promise((resolve, reject) => {
        const onSuccess = (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const pos = {lat,lng};
            resolve(pos)
        }
        const onError = () => {
            reject('No se puede acceder a la localización.');
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    })
}