import React, { useState } from 'react'
import { connect } from 'react-redux'

import InputTextHTML5 from '../../../shared/Form/InputTextHTML5'

import { sendInspectionReportWithEmails } from '../../../store/inspection/actions'
import Button from '../../../shared/Button'

const mapStateToProps = state => ({
    // emailsReport: getInspectionObservations(state)
})

const mapDispatchToProps = dispatch => ({
    sendReport: (inspectionId, emails) => {
        dispatch(sendInspectionReportWithEmails(inspectionId, emails))
    }
})

const InspectionSendReport = ({ visible, inspectionId, sendReport }) => {
    const [emailsValue, setEmailsValue] = useState('')
    const canSendEmail = () => {
        return emailsValue !== undefined && emailsValue !== null && emailsValue !== ''
    }

    return (
        <section className="admin__body">
            <div className="admin__panel">
                <h1 className="panel__title">Enviar informe por email</h1>
                <div className="panel__body">
                    <ul className="form__body">
                        <li className="form__line">
                            <InputTextHTML5
                                type="textarea"
                                label="Emails"
                                placeholder="Introduzca los emails, separados por comas"
                                id="emailsReport"
                                name="emailsReport"
                                defaultValue={emailsValue}
                                max="350"
                                onChange={newEmailsValue => {
                                    setEmailsValue(newEmailsValue)
                                }}
                            />
                            <Button
                                type="submit"
                                onClick={() => {
                                    sendReport(inspectionId, emailsValue)
                                }}
                                disabled={!canSendEmail(emailsValue)}
                            >
                                Enviar emails
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionSendReport)
