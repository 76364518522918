import * as Api from './api'
import { errorHandler } from '../helperApi'

export const actionsTypes = {
    RESET_DELEGATIONS: 'RESET_DELEGATIONS',
    FETCH_DELEGATIONS_CUSTOMER_COMPANY_REQUEST: 'FETCH_DELEGATIONS_CUSTOMER_COMPANY_REQUEST',
    FETCH_DELEGATIONS_CUSTOMER_COMPANY_SUCCESS: 'FETCH_DELEGATIONS_CUSTOMER_COMPANY_SUCCESS',
    FETCH_DELEGATIONS_CUSTOMER_COMPANY_FAILURE: 'FETCH_DELEGATIONS_CUSTOMER_COMPANY_FAILURE'
}

export const resetDelegations = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_DELEGATIONS })
}

export const fetchCustomerCompanyDelegations = (companyId, customerId) => (dispatch, getState) => {
    const params = {
        companyId,
        customerId
    }

    dispatch({ type: actionsTypes.FETCH_DELEGATIONS_CUSTOMER_COMPANY_REQUEST, params })

    return Api.fetchCustomerDelegationsByCompanyApi(companyId, customerId)
        .then(response => {
            dispatch({
                type: actionsTypes.FETCH_DELEGATIONS_CUSTOMER_COMPANY_SUCCESS,
                customerDelegations: response.data.delegations
            })
        })
        .catch(err => {
            const actionError = error => {
                dispatch({ type: actionsTypes.FETCH_DELEGATIONS_CUSTOMER_COMPANY_FAILURE, error: 'No se pudieron obtener las delegaciones' })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}
