import { actionsTypes as actionsInspection } from './actions'
import { getAssessmentBlockItems, getAssessmentBlock, getAssessmentPackageBlocks, getAssessmentPackage,showColaboratorInputs } from '../assessmentData/reducers'

const initInspectionSelectors = {
    companySelector: {
        id: null,
        name: null,
    },
    customerSelector: {
        id: null,
        name: null,
    },
    delegationSelector: {
        id: null,
        name: null,
    },
    contractSelector: {
        id: null,
        name: null,
    },
    serviceSelector: {
        id: null,
        name: null,
    },
    watchmanSelector: {
        id: null,
        name: null,
    },
}
const initStructureAssessmentSelectors = {
    packageSelector: {
        id: null,
        name: null,
    },
    workerSelector: {
        id: null,
        name: null,
    },
}
const initInspectionData = {
    id: null,
    creationDate: null,
    createdById: null,
    createdByUserName: null,
    inspectionStatus: null,
    inspectionCode: null,
    showImagesInReport: true,
    assessmentType: null,
    startLatitude: null,
    startLongitude: null,
    endLatitude: null,
    endLongitude: null,
    userAgent: null
}

const initObservations = {
    inspectorObservations: '',
    inspectedObservations: '',
    proposedImprovements: '',
}

const initSignatures = {
    inspectedSignature: null,
    inspectorSignature: null,
}

const getInitState = () => {
    return {
        loading: false,
        failureMessage: null,
        currentStep: 'companySelector',
        inspectionSelectors: { ...initInspectionSelectors },
        structureAssessmentSelectors: { ...initStructureAssessmentSelectors },
        inspectionData: {
            ...initInspectionData,
        },
        blocksScores: { ...{} },
        blocksScoreAbstract: { ...{} },
        observations: {
            ...initObservations,
        },
        signatures: {
            ...initSignatures,
        },
    }
}

export const blockResultTypes = {
    SUCCESSFUL: 'SUCCESSFUL',
    UNSUCCESSFUL: 'UNSUCCESSFUL',
    INCOMPLETE: 'INCOMPLETE',
}

export const inspectionSignaturesTypes = {
    INSPECTED: 'INSPECTED',
    INSPECTOR: 'INSPECTOR',
}

export const inspectedSignatureApprovalTypes = {
    AGREE: 'AGREE',
    DISAGREE: 'DISAGREE',
}

export const inspectionStatusTypes = {
    PENDING: 'PENDING',
    FINISHED: 'FINISHED',
    SAVED: 'SAVED',
    DISCARDED: 'DISCARDED',
    AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
}

export const inspectionAssessmentTypes = {
    OPERATIONAL: 'OPERATIONAL',
    STRUCTURE: 'STRUCTURE',
}

export const inspectionSelectorTypes = {
    companySelector: 'companySelector',
    customerSelector: 'customerSelector',
    delegationSelector: 'delegationSelector',
    contractSelector: 'contractSelector',
    serviceSelector: 'serviceSelector',
    watchmanSelector: 'watchmanSelector',
    workerSelector: 'workerSelector',
    packageSelector: 'packageSelector',
    assesmentModule: 'assesmentModule',
}

const inspection = (state = getInitState(), action) => {
    switch (action.type) {
        case actionsInspection.RESET_INSPECTION:
            return { ...getInitState() }
        case actionsInspection.RESET_STRUCTURE_ASSESSMENT:
            return {
                ...getInitState(),
                currentStep: inspectionSelectorTypes.packageSelector,
            }
        case actionsInspection.CREATE_STRUCTURE_ASSESSMENT_REQUEST:
        case actionsInspection.CREATE_INSPECTION_REQUEST:
        case actionsInspection.SAVE_INSPECTED_SIGNATURE_REQUEST:
        case actionsInspection.SAVE_INSPECTOR_SIGNATURE_REQUEST:
        case actionsInspection.FINISH_INSPECTION_REQUEST:
        case actionsInspection.SEND_INSPECTION_REPORT_REQUEST:
        case actionsInspection.UPDATE_INSPECTION_SELECTORS_REQUEST:
        case actionsInspection.UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_REQUEST:
        case actionsInspection.SAVE_INSPECTION_REQUEST:
        case actionsInspection.RESUME_INSPECTION_REQUEST:
        case actionsInspection.SUBMIT_INSPECTION_REQUEST:
        case actionsInspection.FETCH_WORKER_DATA_REQUEST:
        case actionsInspection.SAVE_INSPECTION_QUESTIONNAIRE_REQUEST:
            return { ...state, loading: true }
        case actionsInspection.CHANGE_STEP:
            return { ...state, currentStep: action.newStep }
        case actionsInspection.CREATE_STRUCTURE_ASSESSMENT_SUCCESS:
        case actionsInspection.CREATE_INSPECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                inspectionData: {
                    ...state.inspectionData,
                    id: action.inspectionId,
                    creationDate: action.inspectionCreatedDate,
                    createdById: action.createdById,
                    createdByUserName: action.createdByUserName,
                    inspectionStatus: action.inspectionStatus,
                    inspectionCode: action.inspectionCode,
                    assessmentType: action.assessmentType,
                },
            }
        case actionsInspection.UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_SUCCESS:
        case actionsInspection.UPDATE_INSPECTION_SELECTORS_SUCCESS:
            return { ...state, loading: false }
        case actionsInspection.CREATE_STRUCTURE_ASSESSMENT_FAILURE:
        case actionsInspection.CREATE_INSPECTION_FAILURE:
        case actionsInspection.UPDATE_INSPECTION_SELECTORS_FAILURE:
        case actionsInspection.UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_FAILURE:
        case actionsInspection.FETCH_WORKER_DATA_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }

        case actionsInspection.UPDATE_INSPECTION_ITEM_RESPONSE_REQUEST:
            const prevBlockItemsResponse = state.blocksScores && state.blocksScores[action.assessmentBlockId] ? state.blocksScores[action.assessmentBlockId] : {}
            const newBlocksScores = {
                ...state.blocksScores,
                [action.assessmentBlockId]: {
                    ...prevBlockItemsResponse,
                    [action.assessmentBlockItemId]: {
                        itemId: action.assessmentBlockItemId,
                        scoreItemId: action.assessmentBlockItemScoreId,
                        scoreValue: action.assessmentBlockItemScoreValue,
                    },
                },
            }

            const blockResult = blockResultTypes.SUCCESSFUL

            const blockInspectionTotalScore = calculateBlockInspectionTotalScore(newBlocksScores[action.assessmentBlockId])
            return {
                ...state,
                loading: false,

                blocksScores: newBlocksScores,
                blocksScoreAbstract: {
                    ...state.blocksScoreAbstract,
                    [action.assessmentBlockId]: {
                        result: blockResult,
                        totalScore: blockInspectionTotalScore,
                    },
                },
            }

        case actionsInspection.UPDATE_INSPECTION_SELECTOR_REQUEST:
            let inspectionSelectorsNewValues = state.inspectionSelectors

            if (Array.isArray(action.inspectionSelectorsValues)) {
                action.inspectionSelectorsValues.forEach((inspectionSelector) => {
                    inspectionSelectorsNewValues[inspectionSelector.selectorType] = {
                        id: inspectionSelector.id,
                        name: inspectionSelector.name,
                    }
                })
            }
            return {
                ...state,
                inspectionSelectors: {
                    ...inspectionSelectorsNewValues,
                },
            }
        case actionsInspection.UPDATE_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST:
            let structureAssessmentSelectorsNewValues = state.structureAssessmentSelectors

            if (Array.isArray(action.structureAssessmentSelectorsValues)) {
                action.structureAssessmentSelectorsValues.forEach((structureSelector) => {
                    structureAssessmentSelectorsNewValues[structureSelector.selectorType] = {
                        id: structureSelector.id,
                        name: structureSelector.name,
                    }
                })
            }
            return {
                ...state,
                structureAssessmentSelectors: {
                    ...structureAssessmentSelectorsNewValues,
                },
            }
        case actionsInspection.RESET_INSPECTION_SELECTOR_REQUEST:
            let newInspectionSelectors = state.inspectionSelectors

            if (Array.isArray(action.selectorTypesToReset)) {
                action.selectorTypesToReset.forEach((inspectionSelectorToReset) => {
                    newInspectionSelectors[inspectionSelectorToReset] = {
                        id: null,
                        name: null,
                    }
                })
            }

            return {
                ...state,
                inspectionSelectors: {
                    ...newInspectionSelectors,
                },
            }
        case actionsInspection.RESET_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST:
            let newStructureAssessmentSelectors = state.structureAssessmentSelectors

            if (Array.isArray(action.selectorTypesToReset)) {
                action.selectorTypesToReset.forEach((selectorToReset) => {
                    newStructureAssessmentSelectors[selectorToReset] = {
                        id: null,
                        name: null,
                    }
                })
            }

            return {
                ...state,
                structureAssessmentSelectors: {
                    ...newStructureAssessmentSelectors,
                },
            }
        case actionsInspection.UPDATE_INSPECTION_OBSERVATION:
            return {
                ...state,
                loading: false,
                observations: {
                    ...state.observations,
                    [action.observationType]: action.observationValue,
                },
            }
        case actionsInspection.UPDATE_INSPECTION_SHOW_ATTACH_IN_REPORT:
            return {
                ...state,
                loading: false,
                inspectionData: {
                    ...state.inspectionData,
                    showImagesInReport: action.showImagesInReport,
                },
            }

        case actionsInspection.SAVE_INSPECTED_SIGNATURE_SUCCESS:
            return {
                ...state,
                loading: false,
                signatures: {
                    ...state.signatures,
                    inspectedSignature: {
                        id: action.signature.id,
                        signature: action.signature.signatureImage,
                        approvalResult: action.signature.approvalResult,
                    },
                },
            }
        case actionsInspection.SAVE_INSPECTOR_SIGNATURE_SUCCESS:
            return {
                ...state,
                loading: false,
                signatures: {
                    ...state.signatures,
                    inspectorSignature: {
                        id: action.signature.id,
                        signature: action.signature.signatureImage,
                    },
                },
            }
        case actionsInspection.FINISH_INSPECTION_SUCCESS:
        case actionsInspection.SAVE_INSPECTION_SUCCESS:
            return {
                ...state,
                loading: false,

                inspectionData: {
                    ...state.inspectionData,
                    inspectionStatus: action.inspectionData.inspectionStatus,
                },
            }
        case actionsInspection.SUBMIT_INSPECTION_SUCCESS:
            return {
                ...state,
                loading: false,

                inspectionData: {
                    ...state.inspectionData,
                    inspectionStatus: action.inspectionData.inspectionStatus,
                },
            }
        case actionsInspection.SEND_INSPECTION_REPORT_SUCCESS:
        case actionsInspection.SAVE_INSPECTION_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case actionsInspection.FINISH_INSPECTION_FAILURE:
        case actionsInspection.SEND_INSPECTION_REPORT_FAILURE:
        case actionsInspection.SAVE_INSPECTED_SIGNATURE_FAILURE:
        case actionsInspection.SAVE_INSPECTOR_SIGNATURE_FAILURE:
        case actionsInspection.SAVE_INSPECTION_FAILURE:
        case actionsInspection.RESUME_INSPECTION_FAILURE:
        case actionsInspection.SUBMIT_INSPECTION_FAILURE:
        case actionsInspection.SAVE_INSPECTION_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case actionsInspection.RESUME_INSPECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                inspectionData: {
                    ...state.inspectionData,
                    id: action.id,
                    creationDate: action.creationDate,
                    createdById: action.createdById,
                    createdByUserName: action.createdByUserName,
                    inspectionStatus: action.inspectionStatus,
                    inspectionCode: action.inspectionCode,
                    assessmentType: action.assessmentType,
                    blockItems: action.blockItems,
                    startLatitude: action.startLatitude,
                    startLongitude: action.startLongitude,
                    endLatitude: action.endLatitude,
                    endLongitude: action.endLongitude,
                    userAgent: action.userAgent
                },
            }
        case actionsInspection.LOAD_INSPECTOR_SIGNATURE_SUCCESS:
            return {
                ...state,
                loading: false,
                signatures: {
                    ...state.signatures,
                    inspectorSignature: {
                        id: action.signature.id,
                        signature: action.signature.signature_image,
                    },
                },
            }
            case actionsInspection.LOAD_INSPECTED_SIGNATURE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    signatures: {
                        ...state.signatures,
                        inspectedSignature: {
                            id: action.signature.id,
                            signature: action.signature.signature_image,
                            approvalResult: action.signature.approval_result,
                        },
                    },
                }
            case actionsInspection.FETCH_WORKER_DATA_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    structureAssessmentSelectors: {
                        ...state.structureAssessmentSelectors,
                        packageSelector: action.assessmentPackage,
                        workerSelector: action.worker,
                    }
                }
        default:
            return state
    }
}

const calculateBlockInspectionTotalScore = (blockItemScoresSelected) => {
    if (!blockItemScoresSelected) return 0
    let blockTotalScore = 0
    Object.values(blockItemScoresSelected).forEach((item) => {
        blockTotalScore += item.scoreValue
    })
    return blockTotalScore
}

export const isStructureAssessment = (state) => {
    return getInspectionData(state).assessmentType === inspectionAssessmentTypes.STRUCTURE
}

export const isOperationalAssessment = (state) => {
    return getInspectionData(state).assessmentType === inspectionAssessmentTypes.OPERATIONAL
}

export const getInspectionData = (state) => {
    return state.inspection.inspectionData
}

export const getInspectionId = (state) => {
    return state.inspection.inspectionData.id
}

export const getCurrentStep = (state) => {
    return state.inspection.currentStep
}

export const getInspectionSelectors = (state) => {
    return state.inspection.inspectionSelectors
}

export const getStructureAssessmentSelectors = (state) => {
    return state.inspection.structureAssessmentSelectors
}

export const getInspectionResponsibleWorkerSelector = (state) => {
    if (isOperationalAssessment(state)) {
        return getInspectionSelectors(state).watchmanSelector
    } else if (isStructureAssessment(state)) {
        return getStructureAssessmentSelectors(state).workerSelector
    }
    return {}
}

export const isLoadingInspection = (state) => {
    return state.inspection.loading
}
export const getInspectionBlocksScores = (state) => {
    if (!state.inspection) return {}

    return state.inspection.blocksScores
}

export const getInspectionBlockScores = (state, blockId) => {
    if (!state.inspection || !blockId) return {}
    return state.inspection.blocksScores[blockId]
}

export const getBlockScoreAbstract = (state, blockId) => {
    if (!state.inspection || !blockId) return {}
    const blockScoreAbstract = state.inspection.blocksScoreAbstract[blockId]

    if (!blockScoreAbstract) return null

    return blockScoreAbstract
}

export const getInspectionResult = (state) => {
    const blocks = getAssessmentPackageBlocks(state)
    const inspectionBlocksDone = getInspectionBlocksScores(state)

    if (!blocks || !inspectionBlocksDone) return blockResultTypes.INCOMPLETE
    if (blocks.length !== Object.keys(inspectionBlocksDone).length) return blockResultTypes.INCOMPLETE

    const packageBlockIds = blocks.map((block) => block.blockId)
    const inspectionBlocksIdDone = Object.keys(inspectionBlocksDone)
    let completeAllBlocks = true

    packageBlockIds.forEach((packageBlockId) => {
        if (!inspectionBlocksIdDone.includes(packageBlockId)) {
            completeAllBlocks = false
        }
    })

    if (!completeAllBlocks) return blockResultTypes.INCOMPLETE

    let inspectionBlocksResult = []
    packageBlockIds.forEach((packageBlockId) => {
        inspectionBlocksResult.push(getInspectionBlockResult(state, packageBlockId))
    })

    const thereIsBlockIncomplete = inspectionBlocksResult.find((blockResult) => blockResult === blockResultTypes.INCOMPLETE)
    if (thereIsBlockIncomplete) return blockResultTypes.INCOMPLETE

    let inspectionResult = blockResultTypes.SUCCESSFUL
    inspectionBlocksResult.forEach((blockResult) => {
        if (blockResult === blockResultTypes.UNSUCCESSFUL) {
            inspectionResult = blockResultTypes.UNSUCCESSFUL
        }
    })

    return inspectionResult
}

export const getInspectionBlockResult = (state, blockId) => {
    const blockScoreAbstract = getBlockScoreAbstract(state, blockId)

    const assessmentBlock = getAssessmentBlock(state, blockId)

    const blockItemsScoreSelected = getInspectionBlockScores(state, blockId)

    const assessmentBlockItems = getAssessmentBlockItems(state, blockId)
    const defaultBlockResult = blockResultTypes.INCOMPLETE
    if (!assessmentBlock || !assessmentBlockItems || !blockItemsScoreSelected) return blockResultTypes.INCOMPLETE

    const itemsIdSelected = Object.values(blockItemsScoreSelected).map((blockItemScoreSelected) => {
        return blockItemScoreSelected.itemId
    })
    if (!itemsIdSelected || assessmentBlockItems.length !== itemsIdSelected.length) return defaultBlockResult
    const isCompletedInspection = checkIsCompleteAllBlockItems(assessmentBlockItems, itemsIdSelected)

    return isCompletedInspection && blockScoreAbstract && assessmentBlock.minScoreSuccess <= blockScoreAbstract.totalScore
        ? blockResultTypes.SUCCESSFUL
        : blockResultTypes.UNSUCCESSFUL
}

const checkIsCompleteAllBlockItems = (assessmentBlockItems, blockItemsIdSelected) => {
    let isCompletedInspection = true
    for (let index = 0; index < assessmentBlockItems.length; index++) {
        const item = assessmentBlockItems[index]
        if (!blockItemsIdSelected.includes(item.id)) {
            isCompletedInspection = false
            break
        }
    }

    return isCompletedInspection
}

export const getInspectionObservations = (state) => {
    return state.inspection.observations
}

export const getInspectionSignatures = (state) => {
    return state.inspection.signatures
}

export const getInspectorObservations = (state) => {
    return state.inspection.observations.inspectorObservations
}

export const getProposedImprovements = (state) => {
    return state.inspection.observations.proposedImprovements
}

export const getShowEvaluationCannotBeFinishedWarning = (state) => {
    return !(getInspectorObservations(state) && getProposedImprovements(state))
}

export const canFinishInspection = (state) => {
    const signatures = getInspectionSignatures(state)
    const inspectionResult = getInspectionResult(state)
    const inspectionData = getInspectionData(state)
    const isOperational = isOperationalAssessment(state)
    const inspectorObservations = getInspectorObservations(state)
    const proposedImprovements = getProposedImprovements(state)
    const inspectionStatusAllowed = ['PENDING', 'SAVED', 'AWAITING_SIGNATURE']
    
    if (isOperational && (!inspectorObservations || !proposedImprovements)) return false
    if (!inspectionData || !inspectionStatusAllowed.includes(inspectionData.inspectionStatus)) return false
    const inspectionResultAllowed = [blockResultTypes.SUCCESSFUL, blockResultTypes.UNSUCCESSFUL]
    if (!inspectionResultAllowed.includes(inspectionResult)) return false
    if (!signatures 
        || (showColaboratorInputs(state) && !signatures.inspectedSignature)
        || !signatures.inspectorSignature
        || (showColaboratorInputs(state) && !signatures.inspectedSignature.signature)
        || !signatures.inspectorSignature.signature)
        return false
    return true
}

export const canSignInspection = (state) => {
    const inspectionData = getInspectionData(state)
    const inspectionStatusAllowed = ['PENDING', 'SAVED', 'AWAITING_SIGNATURE']
    if (!inspectionData || !inspectionStatusAllowed.includes(inspectionData.inspectionStatus)) return false
    return true
}

export const canAttachInspectionFiles = (state) => {
    const inspectionData = getInspectionData(state)
    const inspectionStatusAllowed = ['PENDING', 'SAVED']
    if (!inspectionData || !inspectionStatusAllowed.includes(inspectionData.inspectionStatus)) return false
    return true
}

export const canSendInspectionReport = (state) => {
    const inspectionData = getInspectionData(state)
    const inspectionStatusAllowed = ['FINISHED']
    if (!inspectionData || !inspectionStatusAllowed.includes(inspectionData.inspectionStatus)) return false
    return true
}

/**
 * Get assessmentType of a given inspection state
 *
 * @author  Daniel Hilbrand <dhilbrand@idi28.com>
 *
 * @since 1.3.1
 *
 * @return string
 */
export const getInspectionAssessmentType = (state) => {
    return state.inspection.inspectionData.assessmentType
}

export const disableSaveButton = (state) => {
    return Object.keys(getInspectionBlocksScores(state)).length === 0 && getInspectionBlocksScores(state).constructor === Object
}

export const disableSubmitButton = (state) => {
    return getInspectionSignatures(state).inspectorSignature === null || getInspectionSignatures(state).inspectedSignature !== null || getInspectionStatus(state) === inspectionStatusTypes.AWAITING_SIGNATURE
}

export const hideSubmitButton = (state) => {
    return getInspectionSignatures(state).inspectorSignature === null 
    || getInspectionSignatures(state).inspectedSignature !== null
    || isOperationalAssessment(state) 
    || !getAssessmentPackage(state)
    || !showColaboratorInputs(state)
}

export const hideSaveButton = (state) => {
    return getInspectionSignatures(state).inspectorSignature !== null || isOperationalAssessment(state) || !getAssessmentPackage(state) || inspectionSubmitted(state)
}

export const getInspectionStatus = (state) => {
    return state.inspection.inspectionData.inspectionStatus
}

export const inspectionFinished = (state) => {
    return state.inspection.inspectionData.inspectionStatus === inspectionStatusTypes.FINISHED
}

export const inspectionSubmitted = (state) => {
    return state.inspection.inspectionData.inspectionStatus === inspectionStatusTypes.AWAITING_SIGNATURE
}

export default inspection
