import React, { useState } from 'react'
import Button from '../../shared/Button'
import { connect } from 'react-redux'
import { getCustomerServices, isLoadingCustomerServices, getCustomerServicesPages } from '../../store/customerServices/reducers'
import { fetchContractServices } from '../../store/customerServices/actions'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import TableServerSide from '../../shared/Table/TableServerSide'
import FormWithoutSection from '../../shared/FormWithoutSection'

const mapStateToProps = state => ({
    customerServices: getCustomerServices(state),
    isLoading: isLoadingCustomerServices(state),
    pages: getCustomerServicesPages(state)
})

const mapDispatchToProps = dispatch => ({
    fetchContractServicesFilter: (contractId, paramsReactTable = null) => {
        dispatch(fetchContractServices(contractId, paramsReactTable))
    }
})

const ServicesFilter = ({ setFilters }) => {
    const [filterParams, setFilterParams] = useState({ serviceCode: null, name: null })
    const hasSomeFilterParam = () => {
        const filterParamsValue = Object.values(filterParams)
        return filterParamsValue.find(filterParamValue => filterParamValue !== undefined && filterParamValue !== null && filterParamValue !== '')
    }

    const updateFilterParam = (filterParamKey, filterParamValue) => {
        setFilterParams({ ...filterParams, [filterParamKey]: filterParamValue })
    }
    const onSubmitFilters = () => {
        const filters = [{ accessor: 'serviceCode', value: filterParams.serviceCode }, { accessor: 'name', value: filterParams.name }]
        setFilters(filters)
    }

    return (
        <FormWithoutSection
            onSubmit={e => {
                e.preventDefault()

                onSubmitFilters()
            }}
            autoComplete="off"
            title="Filtrar servicio del cliente"
        >
            <ul className="form__body">
                <li className="form__line">
                    <InputTextHTML5
                        name="serviceCode"
                        id="serviceCode"
                        label="Código del servicio"
                        type="text"
                        onChange={value => {
                            updateFilterParam('serviceCode', value)
                        }}
                    />

                    <InputTextHTML5
                        name="name"
                        id="name"
                        label="Nombre"
                        type="text"
                        onChange={value => {
                            updateFilterParam('name', value)
                        }}
                    />

                    <Button type="submit" disabled={!hasSomeFilterParam()}>
                        Buscar servicio
                    </Button>
                </li>
            </ul>
        </FormWithoutSection>
    )
}

const ServiceSelector = ({ handleSubmit, inspectionSelectors, fetchContractServicesFilter, customerServices, isLoading, pages }) => {
    const contractIdSelector = inspectionSelectors.contractSelector.id

    const setAllFilter = filters => {
        if (myTableComponent && myTableComponent.current) {
            myTableComponent.current.setAllFilterValues(filters)
        }
    }

    const myTableComponent = React.createRef()
    const myTable = React.createRef()

    return (
        <section className="admin__body">
            <div className="admin__panel">
                <h1 className="panel__title">Seleccione el servicio</h1>
                <div className="panel__body">
                    <TableServerSide
                        ref={myTableComponent}
                        refReactTable={myTable}
                        data={customerServices}
                        FiltersComponent={() => <ServicesFilter setFilters={setAllFilter} />}
                        manual={true}
                        pages={pages}
                        clearFilters={false}
                        onFetchData={reactableState => {
                            fetchContractServicesFilter(contractIdSelector, reactableState)
                        }}
                        onResetChange={() => {
                            // this.setState({ reset: true })
                        }}
                        filterable
                        columns={[
                            {
                                Header: 'Código servicio',
                                accessor: 'serviceCode',
                                filterable: false,
                                id: 'serviceCode'
                            },
                            {
                                Header: 'Servicio',
                                filterable: false,
                                accessor: 'name',
                                Cell: ({ original }) => {
                                    return (
                                        <span>
                                            {original.name} - {original.province} ({original.postalCode})
                                        </span>
                                    )
                                }
                            }
                        ]}
                        loading={isLoading}
                        getTrProps={(state, rowInfo, original) => {
                            let cursor = rowInfo ? 'pointer' : 'default'
                            return {
                                onClick: () => {
                                    if (!rowInfo || !rowInfo.original) return
                                    handleSubmit({
                                        serviceId: rowInfo.original.customerServiceId,
                                        serviceName: rowInfo.original.name
                                    })
                                },
                                style: { cursor: cursor }
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceSelector)
