import React from 'react'
import ReactTable from 'react-table'

import Spinner from '../Spinner'

const TableNormal = ({ onFetchData, data, ...props }) => {
    return (
        <ReactTable
            {...props}
            previousText="Anterior"
            nextText="Siguiente"
            rowsText="filas"
            data={data}
            minRows={!data || data.length < 1 ? 3 : 0}
            noDataText="No hay datos disponible"
            pageText="Página"
            ofText="de"
            loadingText={<Spinner />}
            className="table -striped -highlight"
        />
    )
}
export default TableNormal
