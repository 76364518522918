import { actionsTypes as actionsTypesGeneralOptions } from '../general/actions'
import { actionsTypes as actionsTypesInspection } from '../inspection/actions'
import { actionsTypes as actionsTypesInspectionAttachments } from '../inspectionAttachments/actions'
import { actionsTypes as actionsTypesDelegationsCustomerCompany } from '../delegationsCustomerCompany/actions'
import { actionsTypes as actionsTypesAssessmentPackageManagement } from '../assessmentPackageManagement/actions'
import { actionsTypes as actionsTypesAssessmentModuleManagement } from '../assessmentQuestionnaireManagement/actions'

const initState = {
    errorMessage: null,
    successMessage: null,
    failureMessage: null,
}

const ui = (state = initState, action) => {
    switch (action.type) {
        case 'CLOSE_MESSAGE':
            return { ...state, successMessage: null, failureMessage: null }
        case 'CLEAR_ERROR_MESSAGE':
            return { ...state, errorMessage: null }
        case 'SET_ERROR_MESSAGE':
            return { ...state, errorMessage: action.errorMessage }
        case 'CUSTOM_FAILURE_MESSAGE':
            return { ...state, successMessage: null, failureMessage: action.failureMessage }
        case 'CUSTOM_SUCCESS_MESSAGE':
            return { successMessage: action.successMessage, failureMessage: null }
        case actionsTypesGeneralOptions.FETCH_COMPANIES_SUCCESS:
            return { successMessage: '', failureMessage: null }
        case actionsTypesGeneralOptions.FETCH_COMPANIES_FAILURE:
        case actionsTypesInspection.FINISH_INSPECTION_FAILURE:
        case actionsTypesDelegationsCustomerCompany.FETCH_DELEGATIONS_CUSTOMER_COMPANY_FAILURE:
        case actionsTypesInspection.SAVE_INSPECTION_FAILURE:
        case actionsTypesInspection.SUBMIT_INSPECTION_FAILURE:
        case actionsTypesInspection.CREATE_INSPECTION_FAILURE:
        case actionsTypesInspection.CREATE_STRUCTURE_ASSESSMENT_FAILURE:
            return { successMessage: '', failureMessage: action.error }
        case actionsTypesInspection.CREATE_INSPECTION_SUCCESS:
        case actionsTypesInspection.CREATE_STRUCTURE_ASSESSMENT_SUCCESS:
            return { successMessage: '¡Evaluación iniciada!', failureMessage: null }
        case actionsTypesInspection.FINISH_INSPECTION_SUCCESS:
            return { successMessage: '¡Evaluación finalizada!', failureMessage: null }
        case actionsTypesInspectionAttachments.UPLOAD_INSPECTION_ATTACHMENT_SUCCESS:
            return { successMessage: 'Documento adjuntado con éxito!', failureMessage: null }
        case actionsTypesInspectionAttachments.DELETE_INSPECTION_ATTACHMENT_SUCCESS:
            return { successMessage: 'Documento eliminado con éxito!', failureMessage: null }
        case actionsTypesInspection.SAVE_INSPECTED_SIGNATURE_SUCCESS:
            return { successMessage: 'Firmada por el colaborador', failureMessage: null }
        case actionsTypesInspection.SAVE_INSPECTOR_SIGNATURE_SUCCESS:
            return { successMessage: 'Firmada por el responsable', failureMessage: null }
        case actionsTypesInspection.SEND_INSPECTION_REPORT_SUCCESS:
            return { successMessage: 'Informe de la evaluación enviado con éxito', failureMessage: null }
        case actionsTypesAssessmentPackageManagement.DELETE_PACKAGE_QUESTIONNAIRE_SUCCESS:
            return { successMessage: 'Cuestionario desasociado del paquete con éxito', failureMessage: null }
        case actionsTypesAssessmentPackageManagement.SAVE_PACKAGE_QUESTIONNAIRE_SUCCESS:
            return { successMessage: 'Cuestionario asociado al paquete', failureMessage: null }
        case actionsTypesAssessmentPackageManagement.UPDATE_ASSESSMENT_PACKAGE_SUCCESS:
            return { successMessage: 'Paquete actualizado correctamente', failureMessage: null }
        case actionsTypesAssessmentPackageManagement.CREATE_ASSESSMENT_PACKAGE_SUCCESS:
            return { successMessage: 'Paquete creado correctamente', failureMessage: null }
        case actionsTypesAssessmentPackageManagement.DELETE_ASSESSMENT_PACKAGE_SUCCESS:
            return { successMessage: 'Paquete eliminado correctamente', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.CREATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS:
            return { successMessage: 'Cuestionario creado', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.ADD_QUESTIONNAIRE_ITEM_SCORE_REQUEST:
            return { successMessage: 'Puntuación añadida', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.EDIT_QUESTIONNAIRE_ITEM_SCORE_REQUEST:
            return { successMessage: 'Puntuación modificada', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.ADD_QUESTIONNAIRE_ITEM_REQUEST:
            return { successMessage: 'Pregunta añadida', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.EDIT_QUESTIONNAIRE_ITEM_REQUEST:
            return { successMessage: 'Pregunta modificada', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.SAVE_QUESTIONNNAIRE_ITEMS_REQUEST:
            return { successMessage: 'Posiciones de las preguntas del cuestionario actualizadas', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.SAVE_QUESTIONNNAIRE_ITEM_SCORES_SUCCESS:
            return { successMessage: 'Posiciones de las puntuaciones del cuestionario actualizadas', failureMessage: null }

        case actionsTypesAssessmentPackageManagement.UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_SUCCESS:
            return { successMessage: 'Posiciones del cuestionario actualizadas', failureMessage: null }
        case actionsTypesAssessmentModuleManagement.DELETE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS:
            return { successMessage: 'Cuestionario eliminado con éxito', failureMessage: null }
        case actionsTypesInspection.UPDATE_INSPECTION_SELECTORS_SUCCESS:
            return { successMessage: 'Evaluación actualizada con éxito', failureMessage: null }
        case actionsTypesInspection.SEND_INSPECTION_REPORT_FAILURE:
            return { successMessage: null, failureMessage: 'No se pudo enviar el email' }
        case actionsTypesInspection.SAVE_INSPECTION_SUCCESS:
                return { successMessage: '¡Evaluación guardada!', failureMessage: null }
        case actionsTypesInspection.SUBMIT_INSPECTION_SUCCESS:
            return { successMessage: '¡Evaluación enviada para firmar por el colaborador !', failureMessage: null }
        default:
            return state
    }
}

export const getSuccessMessage = (state) => {
    return state.ui.successMessage
}
export const getFailureMessage = (state) => {
    return state.ui.failureMessage
}

export const getErrorMessage = (state) => {
    return state.ui.errorMessage
}

export default ui
