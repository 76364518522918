import React from 'react'

let InputText = ({ errors, id, name, type, label, required, defaultValue, onChange, readOnly, ...rest }) => (
    <div className="form__group">
        <label className="label__input">
            {label} {required ? '*' : ''}: {errors && errors[name] ? <span className="form__error"> {errors[name]}</span> : null}
        </label>
        <input
            required={required}
            id={id}
            className={readOnly ? 'form__input--readonly' : 'form__input'}
            readOnly={readOnly}
            type={type}
            defaultValue={defaultValue}
            onChange={e => {
                if (onChange) {
                    onChange(e.target.value)
                }
            }}
            {...rest}
        />
        <span className="focus-border" />
    </div>
)

const InputTextMini = ({ errors, id, name, type, label, required, defaultValue, readOnly, ...rest }) => (
    <div className="form__group--mini">
        <label className="label__input">
            {label} {required ? '*' : ''}: {errors && errors[name] ? <span className="form__error"> {errors[name]}</span> : null}
        </label>
        <input required={required} id={id} name={name} className="form__input" type={type} defaultValue={defaultValue} {...rest} />
        <span className="focus-border" />
    </div>
)

const InputTextarea = ({ errors, id, name, type, label, required, defaultValue, noMargin = false, readOnly, onChange, ...rest }) => (
    <div className={noMargin === true ? 'form__group--noMargin' : 'form__group'}>
        <label className="label__input">
            {label} {required ? '*' : ''}: {errors && errors[name] ? <span className="form__error"> {errors[name]}</span> : null}
        </label>
        <textarea
            required={required}
            id={id}
            name={name}
            className="form__input"
            rows="4"
            onChange={e => {
                if (onChange) {
                    onChange(e.target.value)
                }
            }}
            {...rest}
            defaultValue={defaultValue}
            readOnly={readOnly}
        />

        <span className="focus-border" />
    </div>
)

const InputTextHTML5 = props => {
    if (props.type === 'textarea') return <InputTextarea {...props} />
    else if (props.type === 'textmini') return <InputTextMini {...props} />
    return <InputText {...props} />
}
export default InputTextHTML5
