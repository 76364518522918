import { actionsTypes as actionsTypesCustomers } from './actions'

const initState = {
    loading: false,
    filter: null,
    customers: [],
    failureMessage: null,
    pages: -1,
}

const customers = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesCustomers.RESET_CUSTOMERS:
            return { ...initState }
        case actionsTypesCustomers.FETCH_CUSTOMERS_LIVE_SEARCH_REQUEST:
            return { ...state, loading: true }
        case actionsTypesCustomers.FETCH_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
                customers: action.page > 1 ? state.customers : [],
            }
        case actionsTypesCustomers.FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.customers,
                pages: action.pages,
            }
        case actionsTypesCustomers.FETCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                failureMessage: action.failureMessage,
            }
        case actionsTypesCustomers.FETCH_CUSTOMERS_LIVE_SEARCH_SUCCESS:
            return { ...state, loading: false, customers: action.customers }
        case actionsTypesCustomers.CLEAR_CUSTOMERS_LIVE_SEARCH_RESULTS:
            return {
                ...state,
                customers: [],
            }
        default:
            return state
    }
}

export const isLoadingCustomers = (state) => {
    return state.customers.loading
}

export const getCustomers = (state) => {
    return state.customers.customers
}

export const getCustomerPages = (state) => {
    return state.customers.pages
}

export default customers
