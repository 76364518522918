import { actionsTypes as actionsTypesDelegations } from './actions'

const initState = {
    loading: false,
    delegations: [],
    companyId: null,
    customerId: null,
    failureMessage: null
}

const delegationsCustomerCompany = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesDelegations.RESET_DELEGATIONS:
            return { ...initState }
        case actionsTypesDelegations.FETCH_DELEGATIONS_CUSTOMER_COMPANY_REQUEST:
            return { ...state, loading: true, companyId: action.companyId, customerId: action.customerId }
        case actionsTypesDelegations.FETCH_DELEGATIONS_CUSTOMER_COMPANY_SUCCESS:
            return { ...state, loading: true, delegations: [{ id: -1, name: 'Todos' }, ...action.customerDelegations] }
        case actionsTypesDelegations.FETCH_DELEGATIONS_CUSTOMER_COMPANY_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }
        default:
            return state
    }
}

export const isLoadingDelegations = state => {
    return state.delegationsCustomerCompany.loading
}

export const getDelegationsCustomerCompany = state => {
    return state.delegationsCustomerCompany.delegations
}

export default delegationsCustomerCompany
