import React, { useState, useEffect } from 'react'
import FormWithoutSection from '../../shared/FormWithoutSection'
import Button from '../../shared/Button'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es' // the locale you want
import FormHtml5Select from '../../shared/Form/html5/FormHtml5Select'
import { getInspectionAssessmentType } from '../../store/inspection/reducers'
import { connect } from 'react-redux'

registerLocale('es', es) // register it with the name you want

const mapStateToProps = (state) => ({
    inspectionAssessmentType: getInspectionAssessmentType(state),
})

/**
 * Form para guardar en memoria las tareas que se enviarán al finalizar la evaluación
 * 
 * @param {object} tasks Elemento tarea a editar
 * @param {function} onSubmit Form submit callback function
 */
const TicketForm = (props) => {

    /**
     * Destructure necessary props
     * 
     * @author  Daniel Hilbrand <dhilbrand@idi28.com>
     *
     * @since 1.3.1
     */
    const { taskId, tasks, onSubmit } = props

    // Inicializacion de variables del formulario en State
    const [formData, setFormData] = useState({
        role: 1, // Importante se indica este valor porque si se envía el formulario directamente llega a null y este es el valor por defecto
        expirationDate: null,
        toDo: ''
    })

    useEffect(() => {
        // Se realiza un bucle con todos los creados
        tasks.forEach(task => {
            // Si el indice pulsado coincide con alguna tarea es la que queremos editar
            if (task.itemIndex === taskId)
                setFormData({
                    role: task.role,
                    expirationDate: new Date(task.expiration),
                    toDo: task.toDo
                })
        });
        // eslint-disable-next-line
    }, []);

    // Se usa para el Datepicker 
    const DateInputButton = ({ onClick, value, ref }) => {
        return (
            <input
                ref={ref}
                value={value}
                required={true}
                className={'form__input'}
                onClick={e => {
                    e.preventDefault()
                    onClick(e)
                }}
                onKeyDown={e => {
                    e.preventDefault()
                }}
                placeholder="Introduzca la fecha de vencimiento"
            />
        )
    }

    /**
     * Options Object
     *
     * Show option text depending on assessmentType
     * 
     * @author  Daniel Hilbrand <dhilbrand@idi28.com>
     *
     * @since 1.2.1
     * 
     * @return void
     */
    const options = [
        { value: 1, label: 'Colaborador' },
        { value: 2, label: 'Evaluador' },
        { value: 3, label: 'Responsable' },
        { value: 4, label: 'Plan de Vida' },
        { value: 5, label: 'Jefe de Seguridad Nacional' },
    ]

    /**
     * Guarda en State el valor registrado
     * 
     * @param {string} name Nombre del Input
     * @param {string} value Valor del Input
     */
    const onHandleChangeFormDataInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (

        <FormWithoutSection
            onSubmit={e => {
                e.preventDefault()
                onSubmit(
                    formData.role,
                    formData.expirationDate,
                    formData.toDo)
            }}
            autoComplete="off"
            title="Task"
        >
            <ul className="form__body">

                <li className="form__line">
                    <FormHtml5Select
                        label="Quien realizará la tarea"
                        options={options}
                        value={formData.role}
                        onChange={optionSelected => {
                            onHandleChangeFormDataInput('role', optionSelected.value)
                        }}
                    />
                </li>

                <li className="form__line">
                    <div className="form__group">
                        <label className="label__input">Fecha de vencimiento</label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="es"
                            selected={formData.expirationDate}
                            selectsStart={true}
                            minDate={new Date()}
                            onChange={value => onHandleChangeFormDataInput('expirationDate', value)}
                            autoComplete="off"
                            onKeyDown={e => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            customInput={<DateInputButton />}
                        />
                    </div>
                </li>

                <li className="form__line">
                    <InputTextHTML5
                        type="textarea"
                        label="Descripción de la Tarea"
                        required={true}
                        placeholder="Incluir descripción"
                        id="toDo"
                        name="toDo"
                        value={formData.toDo}
                        max="255"
                        onChange={value => {
                            onHandleChangeFormDataInput('toDo', value)
                        }}
                    />
                </li>

                <li className="form__line">
                    <Button type="submit">Añadir</Button>
                </li>
            </ul>
        </FormWithoutSection>
    )
}

export default connect(mapStateToProps)(TicketForm)
