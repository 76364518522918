import * as Api from './api'
import { errorHandler } from '../helperApi'
import { getAssessmentBlockItemScore } from '../assessmentData/reducers'
import { fetchAssessmentDataByPackageId } from '../assessmentData/actions'
import { updateTicket } from '../inspectionTickets/actions'
import {
    inspectionAssessmentTypes,
    inspectionSignaturesTypes,
    getInspectionBlocksScores,
    getInspectionObservations,
    getInspectionData,
    inspectionSelectorTypes,
    getInspectionResponsibleWorkerSelector,
    getInspectionResult,
    blockResultTypes,
    getInspectionId,
} from './reducers'
import { getUserRolId } from '../auth/reducers'
import { push } from 'react-router-redux'
import { routesConst } from '../../Routes'
import { getTicket } from '../inspectionTickets/reducers'
import { formatDateApiPoseidon } from '../../shared/HelperDate'
import { getPosition } from '../HelperGeolocation'

export const actionsTypes = {
    RESET_INSPECTION: 'RESET_INSPECTION',
    RESET_STRUCTURE_ASSESSMENT: 'RESET_STRUCTURE_ASSESSMENT',

    CREATE_STRUCTURE_ASSESSMENT_REQUEST: 'CREATE_STRUCTURE_ASSESSMENT_REQUEST',
    CREATE_STRUCTURE_ASSESSMENT_SUCCESS: 'CREATE_STRUCTURE_ASSESSMENT_SUCCESS',
    CREATE_STRUCTURE_ASSESSMENT_FAILURE: 'CREATE_STRUCTURE_ASSESSMENT_FAILURE',

    CREATE_INSPECTION_REQUEST: 'CREATE_INSPECTION_REQUEST',
    CREATE_INSPECTION_SUCCESS: 'CREATE_INSPECTION_SUCCESS',
    CREATE_INSPECTION_FAILURE: 'CREATE_INSPECTION_FAILURE',

    UPDATE_INSPECTION_SELECTORS_REQUEST: 'UPDATE_INSPECTION_SELECTORS_REQUEST',
    UPDATE_INSPECTION_SELECTORS_SUCCESS: 'UPDATE_INSPECTION_SELECTORS_SUCCESS',
    UPDATE_INSPECTION_SELECTORS_FAILURE: 'UPDATE_INSPECTION_SELECTORS_FAILURE',

    UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_REQUEST: 'UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_REQUEST',
    UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_SUCCESS: 'UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_SUCCESS',
    UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_FAILURE: 'UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_FAILURE',

    UPDATE_INSPECTION_ITEM_RESPONSE_REQUEST: 'UPDATE_INSPECTION_ITEM_RESPONSE_REQUEST',
    UPDATE_INSPECTION_SELECTOR_REQUEST: 'UPDATE_INSPECTION_SELECTOR_REQUEST',
    RESET_INSPECTION_SELECTOR_REQUEST: 'RESET_INSPECTION_SELECTOR_REQUEST',

    UPDATE_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST: 'UPDATE_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST',
    RESET_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST: 'RESET_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST',

    CHANGE_STEP: 'CHANGE_STEP',

    UPDATE_INSPECTION_OBSERVATION: 'UPDATE_INSPECTION_OBSERVATION',
    UPDATE_INSPECTION_SHOW_ATTACH_IN_REPORT: 'UPDATE_INSPECTION_SHOW_ATTACH_IN_REPORT',

    SAVE_INSPECTED_SIGNATURE_REQUEST: 'SAVE_INSPECTED_SIGNATURE_REQUEST',
    SAVE_INSPECTED_SIGNATURE_SUCCESS: 'SAVE_INSPECTED_SIGNATURE_SUCCESS',
    SAVE_INSPECTED_SIGNATURE_FAILURE: 'SAVE_INSPECTED_SIGNATURE_FAILURE',

    SAVE_INSPECTOR_SIGNATURE_REQUEST: 'SAVE_INSPECTOR_SIGNATURE_REQUEST',
    SAVE_INSPECTOR_SIGNATURE_SUCCESS: 'SAVE_INSPECTOR_SIGNATURE_SUCCESS',
    SAVE_INSPECTOR_SIGNATURE_FAILURE: 'SAVE_INSPECTOR_SIGNATURE_FAILURE',

    FINISH_INSPECTION_REQUEST: 'FINISH_INSPECTION_REQUEST',
    FINISH_INSPECTION_SUCCESS: 'FINISH_INSPECTION_SUCCESS',
    FINISH_INSPECTION_FAILURE: 'FINISH_INSPECTION_FAILURE',

    SEND_INSPECTION_REPORT_REQUEST: 'SEND_INSPECTION_REPORT_REQUEST',
    SEND_INSPECTION_REPORT_SUCCESS: 'SEND_INSPECTION_REPORT_SUCCESS',
    SEND_INSPECTION_REPORT_FAILURE: 'SEND_INSPECTION_REPORT_FAILURE',

    SAVE_INSPECTION_REQUEST: 'SAVE_INSPECTION_REQUEST',
    SAVE_INSPECTION_SUCCESS: 'SAVE_INSPECTION_SUCCESS',
    SAVE_INSPECTION_FAILURE: 'SAVE_INSPECTION_FAILURE',

    DISCARD_INSPECTION_REQUEST: 'DISCARD_INSPECTION_REQUEST',
    DISCARD_INSPECTION_SUCCESS: 'DISCARD_INSPECTION_SUCCESS',
    DISCARD_INSPECTION_FAILURE: 'DISCARD_INSPECTION_FAILURE',

    RESUME_INSPECTION_REQUEST: 'RESUME_INSPECTION_REQUEST',
    RESUME_INSPECTION_SUCCESS: 'RESUME_INSPECTION_SUCCESS',
    RESUME_INSPECTION_FAILURE: 'RESUME_INSPECTION_FAILURE',

    LOAD_INSPECTED_SIGNATURE_SUCCESS: 'LOAD_INSPECTED_SIGNATURE_SUCCESS',
    LOAD_INSPECTOR_SIGNATURE_SUCCESS: 'LOAD_INSPECTOR_SIGNATURE_SUCCESS',

    SUBMIT_INSPECTION_REQUEST: 'SUBMIT_INSPECTION_REQUEST',
    SUBMIT_INSPECTION_SUCCESS: 'SUBMIT_INSPECTION_SUCCESS',
    SUBMIT_INSPECTION_FAILURE: 'SUBMIT_INSPECTION_FAILURE',

    FETCH_WORKER_DATA_REQUEST: 'FETCH_WORKER_DATA_REQUEST',
    FETCH_WORKER_DATA_SUCCESS: 'FETCH_WORKER_DATA_SUCCESS',
    FETCH_WORKER_DATA_FAILURE: 'FETCH_WORKER_DATA_FAILURE',

    SAVE_INSPECTION_QUESTIONNAIRE_REQUEST: 'SAVE_INSPECTION_QUESTIONNAIRE_REQUEST',
    SAVE_INSPECTION_QUESTIONNAIRE_SUCCESS: 'SAVE_INSPECTION_QUESTIONNAIRE_SUCCESS',
    SAVE_INSPECTION_QUESTIONNAIRE_FAILURE: 'SAVE_INSPECTION_QUESTIONNAIRE_FAILURE',
}

export const inspectionObservationTypes = {
    INSPECTOR_OBSERVATIONS: 'inspectorObservations',
    INSPECTED_OBSERVATIONS: 'inspectedObservations',
    PROPOSED_IMPROVEMENTS: 'proposedImprovements',
}

export const signatureTypes = {
    INSPECTOR: 'INSPECTOR',
    INSPECTED: 'INSPECTED',
}

export const resetInspection = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_INSPECTION })
}

export const resetStructureAssessment = () => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_STRUCTURE_ASSESSMENT })
}

export const createInspection = (companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId, startLatitude = null, startLongitude = null) => async (dispatch, getState) => {

    const assessmentType = inspectionAssessmentTypes.OPERATIONAL
    let body = { assessmentType, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId, startLatitude, startLongitude }

    await getPosition()
        .then((position) => {
            startLatitude = position.lat
            startLongitude = position.lng
            body = {
                ...body,
                startLatitude: startLatitude,
                startLongitude: startLongitude,
            }
        })
        .catch((error) => console.log(error))
    dispatch({ type: actionsTypes.CREATE_INSPECTION_REQUEST, ...body })
    return Api.createInspectionApi(body)
        .then((response) => {
            const inspectionId = response.data.inspectionCreated.inspectionId
            dispatch({
                type: actionsTypes.CREATE_INSPECTION_SUCCESS,
                inspectionId,
                inspectionCreatedDate: response.data.inspectionCreated.createdDate,
                createdById: response.data.inspectionCreated.createdById,
                createdByUserName: response.data.inspectionCreated.createdByUserName,
                inspectionStatus: response.data.inspectionCreated.inspectionStatus,
                inspectionCode: response.data.inspectionCreated.inspectionCode,
                assessmentType: response.data.inspectionCreated.assessmentType,
                startLatitude: response.data.inspectionCreated.startLatitude,
                startLongitude: response.data.inspectionCreated.startLongitude,
            })
            let newRoute = routesConst.updateInspection
            newRoute = newRoute.split(':')[0] + inspectionId
            dispatch(push(newRoute))
            dispatch(fetchAssessmentDataByPackageId(packageId))
            dispatch(changeInspectionStep(inspectionSelectorTypes.assesmentModule))
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.CREATE_INSPECTION_FAILURE, error: 'No se pudo crear la evaluación' })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const createStructureAssessment = (packageId, workerId) => (dispatch, getState) => {
    const assessmentType = inspectionAssessmentTypes.STRUCTURE
    const body = { assessmentType, packageId, workerId }

    dispatch({ type: actionsTypes.CREATE_STRUCTURE_ASSESSMENT_REQUEST, ...body })

    return Api.createInspectionApi(body)
        .then((response) => {
            const inspectionId = response.data.inspectionCreated.inspectionId
            dispatch({
                type: actionsTypes.CREATE_STRUCTURE_ASSESSMENT_SUCCESS,
                inspectionId,
                inspectionCreatedDate: response.data.inspectionCreated.createdDate,
                createdById: response.data.inspectionCreated.createdById,
                createdByUserName: response.data.inspectionCreated.createdByUserName,
                inspectionStatus: response.data.inspectionCreated.inspectionStatus,
                inspectionCode: response.data.inspectionCreated.inspectionCode,
                assessmentType: response.data.inspectionCreated.assessmentType,
            })
            let newRoute = routesConst.updateStructureAssessment
            newRoute = newRoute.split(':')[0] + inspectionId
            dispatch(push(newRoute))
            dispatch(fetchAssessmentDataByPackageId(packageId))
            dispatch(changeInspectionStep(inspectionSelectorTypes.assesmentModule))
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.CREATE_STRUCTURE_ASSESSMENT_FAILURE, error: 'No se pudo crear la evaluación de estructura' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const updateInspectionSelectors = (inspectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId) => (dispatch, getState) => {
    const assessmentType = inspectionAssessmentTypes.OPERATIONAL
    const body = { assessmentType, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId }
    dispatch({ type: actionsTypes.UPDATE_INSPECTION_SELECTORS_REQUEST, inspectionId, ...body })

    return Api.updateInspectionSelectors(inspectionId, body)
        .then((response) => {
            dispatch({
                type: actionsTypes.UPDATE_INSPECTION_SELECTORS_SUCCESS,
            })
            dispatch(fetchAssessmentDataByPackageId(packageId))
            dispatch(changeInspectionStep(inspectionSelectorTypes.assesmentModule))
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.CREATE_INSPECTION_FAILURE, error: 'No se pudo actualizar la evaluación' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const updateStructureAssessmentSelectors = (inspectionId, packageId, workerId) => (dispatch, getState) => {
    const assessmentType = inspectionAssessmentTypes.STRUCTURE
    const body = { assessmentType, packageId, watchmanId: workerId }

    dispatch({ type: actionsTypes.UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_REQUEST, inspectionId, ...body })

    return Api.updateInspectionSelectors(inspectionId, body)
        .then((response) => {
            dispatch({
                type: actionsTypes.UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_SUCCESS,
            })
            dispatch(fetchAssessmentDataByPackageId(packageId))
            dispatch(changeInspectionStep(inspectionSelectorTypes.assesmentModule))
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.UPDATE_STRUCTURE_ASSESSMENT_SELECTORS_FAILURE, error: 'No se pudo actualizar la evaluación de estructura' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const changeInspectionStep = (newStep) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.CHANGE_STEP, newStep })
}

export const updateInspectionItemResponse = (assessmentBlockId, assessmentBlockItemId, assessmentBlockItemScoreId) => (dispatch, getState) => {
    const assessmentBlockItemScoreValue = getAssessmentBlockItemScore(getState(), assessmentBlockId, assessmentBlockItemScoreId)
    dispatch({
        type: actionsTypes.UPDATE_INSPECTION_ITEM_RESPONSE_REQUEST,
        assessmentBlockId,
        assessmentBlockItemId,
        assessmentBlockItemScoreId,
        assessmentBlockItemScoreValue
    })
    const inspectionResult = getInspectionResult(getState())
    const questionnaireIncomplete = inspectionResult === blockResultTypes.INCOMPLETE
    if (!questionnaireIncomplete) {
        dispatch(saveInspectionQuestionnaire())
    }
}

export const saveInspectionQuestionnaire = () => (dispatch, getState) => {
    const inspectionBlocksScores = getInspectionBlocksScores(getState())
    const inspectionId = getInspectionId(getState())
    if (!inspectionBlocksScores) return
    let inspectionBlocksScoresToApi = {}
    Object.keys(inspectionBlocksScores).forEach((inspectionBlockId) => {
        const scoreItemsBlock = inspectionBlocksScores[inspectionBlockId]
        const scoreItemsBlockParsedToApi = {}
        Object.values(scoreItemsBlock).forEach((itemScore) => {
            scoreItemsBlockParsedToApi[itemScore.itemId] = itemScore.scoreItemId
        })
        inspectionBlocksScoresToApi[inspectionBlockId] = scoreItemsBlockParsedToApi
    })
    dispatch({
        type: actionsTypes.SAVE_INSPECTION_QUESTIONNAIRE_REQUEST,
        inspectionId,
        inspectionBlocksScoresToApi,
    })
    return Api.saveInspectionBlockScores(inspectionId, inspectionBlocksScoresToApi)
        .then((response) => {
            dispatch({
                type: actionsTypes.SAVE_INSPECTION_QUESTIONNAIRE_SUCCESS,
                response,
            })
        })
        .catch((error) => {
            dispatch({
                type: actionsTypes.SAVE_INSPECTION_QUESTIONNAIRE_FAILURE,
                error,
            })
        })
}

export const setInspectionSelectorByTypes = (inspectionSelectorsValues) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.UPDATE_INSPECTION_SELECTOR_REQUEST, inspectionSelectorsValues })
}

export const resetInspectionSelectorsByTypes = (selectorTypesToReset) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_INSPECTION_SELECTOR_REQUEST, selectorTypesToReset })
}

export const setStructureAssessmentSelectorByTypes = (structureAssessmentSelectorsValues) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.UPDATE_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST, structureAssessmentSelectorsValues })
}

export const resetStructureAssessmentSelectorByTypes = (selectorTypesToReset) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESET_STRUCTURE_ASSESSMENT_SELECTOR_REQUEST, selectorTypesToReset })
}

export const updateInspectionObservationByType = (observationType, observationValue) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.UPDATE_INSPECTION_OBSERVATION,
        observationType,
        observationValue,
    })
}

export const updateInspectionShowImagesInReport = (showImagesInReport) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.UPDATE_INSPECTION_SHOW_ATTACH_IN_REPORT,
        showImagesInReport,
    })
}

export const sendInspectedSignature = (inspectionId, signature, approvalResult) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.SAVE_INSPECTED_SIGNATURE_REQUEST,
        signature,
        approvalResult,
    })
    const body = {
        signature,
        signerType: inspectionSignaturesTypes.INSPECTED,
        approvalResult,
    }

    return Api.saveSignature(inspectionId, 'inspected', body)
        .then((response) => {
            dispatch({
                type: actionsTypes.SAVE_INSPECTED_SIGNATURE_SUCCESS,
                signature: response.data.signature,
            })
            return Promise.resolve()
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.SAVE_INSPECTED_SIGNATURE_FAILURE, error: 'No se pudo enviar la firma' })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const sendInspectorSignature = (inspectionId, signature) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.SAVE_INSPECTOR_SIGNATURE_REQUEST,
        signature,
    })
    const body = {
        signature,
        signerType: inspectionSignaturesTypes.INSPECTOR,
    }

    return Api.saveSignature(inspectionId, 'inspector', body)
        .then((response) => {
            dispatch({
                type: actionsTypes.SAVE_INSPECTOR_SIGNATURE_SUCCESS,
                signature: response.data.signature,
            })
            return Promise.resolve()
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.SAVE_INSPECTOR_SIGNATURE_FAILURE, error: 'No se pudo enviar la firma' })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const finishInspection = (inspectionId) => async (dispatch, getState) => {
    const inspectionBlocksScores = getInspectionBlocksScores(getState())

    if (!inspectionBlocksScores) return
    let inspectionBlocksScoresToApi = {}
    Object.keys(inspectionBlocksScores).forEach((inspectionBlockId) => {
        const scoreItemsBlock = inspectionBlocksScores[inspectionBlockId]

        const scoreItemsBlockParsedToApi = {}
        Object.values(scoreItemsBlock).forEach((itemScore) => {
            scoreItemsBlockParsedToApi[itemScore.itemId] = itemScore.scoreItemId
        })
        inspectionBlocksScoresToApi[inspectionBlockId] = scoreItemsBlockParsedToApi
    })
    const observations = getInspectionObservations(getState())
    const inspectionData = getInspectionData(getState())
    const showImagesInReport = inspectionData.showImagesInReport
    dispatch({
        type: actionsTypes.FINISH_INSPECTION_REQUEST,
        inspectionBlocksScoresToApi,
        observations,
    })

    const inspectionTicket = getTicket(getState())

    const roleIdPoseidon = getUserRolId(getState())
    const workerIdPoseidon = getInspectionResponsibleWorkerSelector(getState()).id
    const workerNamePoseidon = getInspectionResponsibleWorkerSelector(getState()).name

    let endLatitude = null
    let endLongitude = null

    if (inspectionData.assessmentType === inspectionAssessmentTypes.OPERATIONAL) {
        await getPosition()
            .then((position) => {
                endLatitude = position.lat
                endLongitude = position.lng
            })
            .catch((error) => console.log(error))
    }
    return Api.finishInspection(inspectionId, inspectionBlocksScoresToApi, observations, showImagesInReport, inspectionTicket, roleIdPoseidon, workerIdPoseidon, workerNamePoseidon, endLatitude, endLongitude)
        .then((response) => {
            dispatch({
                type: actionsTypes.FINISH_INSPECTION_SUCCESS,
                inspectionData: response.data.inspectionFinished,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({
                    type: actionsTypes.FINISH_INSPECTION_FAILURE,
                    error: err.response.data.error,
                    errors: error.response.data.errors
                })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const sendInspectionReportWithEmails = (inspectionId, emails) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.SEND_INSPECTION_REPORT_REQUEST,
        inspectionId,
        emails,
    })

    return Api.sendInspectionReport(inspectionId, emails)
        .then((response) => {
            dispatch({
                type: actionsTypes.SEND_INSPECTION_REPORT_SUCCESS,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.SEND_INSPECTION_REPORT_FAILURE, error: err.response.data.error })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const saveInspection = (inspectionId) => (dispatch, getState) => {
    const inspectionBlocksScores = getInspectionBlocksScores(getState())

    if (!inspectionBlocksScores) return
    let inspectionBlocksScoresToApi = {}
    Object.keys(inspectionBlocksScores).forEach((inspectionBlockId) => {
        const scoreItemsBlock = inspectionBlocksScores[inspectionBlockId]

        const scoreItemsBlockParsedToApi = {}
        Object.values(scoreItemsBlock).forEach((itemScore) => {
            scoreItemsBlockParsedToApi[itemScore.itemId] = itemScore.scoreItemId
        })
        inspectionBlocksScoresToApi[inspectionBlockId] = scoreItemsBlockParsedToApi
    })
    const observations = getInspectionObservations(getState())
    const inspectionData = getInspectionData(getState())
    const showImagesInReport = inspectionData.showImagesInReport
    dispatch({
        type: actionsTypes.SAVE_INSPECTION_REQUEST,
        inspectionBlocksScoresToApi,
        observations,
    })

    const inspectionTicket = getTicket(getState())

    const roleIdPoseidon = getUserRolId(getState())
    const workerIdPoseidon = getInspectionResponsibleWorkerSelector(getState()).id
    const workerNamePoseidon = getInspectionResponsibleWorkerSelector(getState()).name

    return Api.saveInspection(inspectionId, inspectionBlocksScoresToApi, observations, showImagesInReport, inspectionTicket, roleIdPoseidon, workerIdPoseidon, workerNamePoseidon)
        .then((response) => {
            dispatch({
                type: actionsTypes.SAVE_INSPECTION_SUCCESS,
                inspectionData: response.data.inspectionSaved,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.SAVE_INSPECTION_FAILURE, error: err.response.data.error, errors: error.response.data.errors })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const discardInspection = (inspectionId) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.DISCARD_INSPECTION_REQUEST })
    return Api.discardInspection(inspectionId)
        .then((response) => {
            dispatch({
                type: actionsTypes.DISCARD_INSPECTION_SUCCESS,
                inspectionData: response.data.inspectionDiscarded,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.DISCARD_INSPECTION_FAILURE, error: err.response.data.error, errors: error.response.data.errors })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const resumeInspection = (inspectionId) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.RESUME_INSPECTION_REQUEST, inspectionId: inspectionId })
    Api.getInspectionData(inspectionId)
        .then((response) => {
            let blockItems = response.data.inspectionExecuted.block_items
            dispatch({
                type: actionsTypes.RESUME_INSPECTION_SUCCESS,
                id: inspectionId,
                creationDate: response.data.inspectionExecuted.created_at,
                createdById: response.data.inspectionExecuted.inspector.id,
                createdByUserName: response.data.inspectionExecuted.inspector.name,
                inspectionStatus: response.data.inspectionExecuted.inspection_status,
                inspectionCode: response.data.inspectionExecuted.inspection_code,
                assessmentType: response.data.inspectionExecuted.assessment_type,
                blockItems: blockItems,
                startLatitude: response.data.inspectionExecuted.start_latitude,
                startLongitude: response.data.inspectionExecuted.start_longitude,
                endLatitude: response.data.inspectionExecuted.end_latitude,
                endLongitude: response.data.inspectionExecuted.end_longitude,
                userAgent: response.data.inspectionExecuted.user_agent

            })
            const assessmentType = response.data.inspectionExecuted.assessment_type
            let newRoute = assessmentType === inspectionAssessmentTypes.STRUCTURE ? routesConst.updateStructureAssessment : routesConst.updateInspection
            newRoute = newRoute.split(':')[0] + inspectionId
            dispatch(push(newRoute))
            dispatch(fetchAssessmentDataByPackageId(response.data.inspectionExecuted.package_id))
            dispatch(fetchWorkerDataByInspectionId(inspectionId))
            dispatch(changeInspectionStep(inspectionSelectorTypes.assesmentModule))
            if (blockItems) {
                blockItems.forEach((blockItem) => {
                    dispatch({
                        type: actionsTypes.UPDATE_INSPECTION_ITEM_RESPONSE_REQUEST,
                        assessmentBlockId: blockItem.assessment_block_id,
                        assessmentBlockItemId: blockItem.assessment_block_item_id,
                        assessmentBlockItemScoreId: blockItem.assessment_block_score_id,
                        assessmentBlockItemScoreValue: blockItem.option_score,
                    })
                })
            }
            let inspectorObservations = response.data.inspectionExecuted.inspector_observations
            let inspectedObservations = response.data.inspectionExecuted.inspected_observations
            let proposedImprovements = response.data.inspectionExecuted.proposed_improvements
            if (inspectorObservations) {
                dispatch(updateInspectionObservationByType(inspectionObservationTypes.INSPECTOR_OBSERVATIONS, inspectorObservations))
            }
            if (inspectedObservations) {
                dispatch(updateInspectionObservationByType(inspectionObservationTypes.INSPECTED_OBSERVATIONS, inspectedObservations))
            }
            if (inspectorObservations) {
                dispatch(updateInspectionObservationByType(inspectionObservationTypes.PROPOSED_IMPROVEMENTS, proposedImprovements))
            }
            let signatures = response.data.inspectionExecuted.signatures
            if (signatures) {
                signatures.forEach((signature) => {
                    if (signature.signature_type === signatureTypes.INSPECTOR) {
                        dispatch({
                            type: actionsTypes.LOAD_INSPECTOR_SIGNATURE_SUCCESS,
                            signature: signature,
                        })
                    }
                    else {
                        dispatch({
                            type: actionsTypes.LOAD_INSPECTED_SIGNATURE_SUCCESS,
                            signature: signature,
                        })
                    }
                })
            }
            let tasks = response.data.inspectionExecuted.tasks
            if (tasks) {
                tasks.forEach((task) => {
                    dispatch(updateTicket(
                        task.assessment_block_item_id,
                        task.item_name,
                        task.selected_role_id,
                        formatDateApiPoseidon(task.expiration_date),
                        task.to_do,
                    ))
                })
            }
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.RESUME_INSPECTION_FAILURE, error: err.response.data.error, errors: error.response.data.errors })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })

}

export const submitInspection = (inspectionId) => (dispatch, getState) => {
    const inspectionBlocksScores = getInspectionBlocksScores(getState())

    if (!inspectionBlocksScores) return
    let inspectionBlocksScoresToApi = {}
    Object.keys(inspectionBlocksScores).forEach((inspectionBlockId) => {
        const scoreItemsBlock = inspectionBlocksScores[inspectionBlockId]

        const scoreItemsBlockParsedToApi = {}
        Object.values(scoreItemsBlock).forEach((itemScore) => {
            scoreItemsBlockParsedToApi[itemScore.itemId] = itemScore.scoreItemId
        })
        inspectionBlocksScoresToApi[inspectionBlockId] = scoreItemsBlockParsedToApi
    })
    const observations = getInspectionObservations(getState())
    const inspectionData = getInspectionData(getState())
    const showImagesInReport = inspectionData.showImagesInReport
    dispatch({
        type: actionsTypes.SUBMIT_INSPECTION_REQUEST,
        inspectionBlocksScoresToApi,
        observations,
    })

    const inspectionTicket = getTicket(getState())

    const roleIdPoseidon = getUserRolId(getState())
    const workerIdPoseidon = getInspectionResponsibleWorkerSelector(getState()).id
    const workerNamePoseidon = getInspectionResponsibleWorkerSelector(getState()).name

    return Api.submitInspection(inspectionId, inspectionBlocksScoresToApi, observations, showImagesInReport, inspectionTicket, roleIdPoseidon, workerIdPoseidon, workerNamePoseidon)
        .then((response) => {
            dispatch({
                type: actionsTypes.SUBMIT_INSPECTION_SUCCESS,
                inspectionData: response.data.inspectionSubmitted,
            })
        })
        .catch((err) => {
            const actionError = (error) => {
                dispatch({ type: actionsTypes.SUBMIT_INSPECTION_FAILURE, error: err.response.data.error, errors: error.response.data.errors })
            }
            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchWorkerDataByInspectionId = inspectionId => (dispatch, getState) => {
    dispatch({ type: actionsTypes.FETCH_WORKER_DATA_REQUEST })

    return Api.fetchWorkerDataApi(inspectionId)
        .then(response => {
            dispatch({
                type: actionsTypes.FETCH_WORKER_DATA_SUCCESS,
                assessmentPackage: response.data.assessmentPackage,
                worker: response.data.worker,
            })
        })
        .catch(err => {
            const actionError = error => {
                dispatch({ type: actionsTypes.FETCH_WORKER_DATA_FAILURE, error: 'No se pudo obtener los datos del paquete de valoración' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}
