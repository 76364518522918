export const translateWords = (word) => {
    const words = {
        EXTERNAL: 'Cliente',
        INTERNAL: 'Interna',
        SYSTEM_ERROR: 'No se pudo realizar la petición, ocurrió un problema en el sistema.',
        NETWORK_ERROR: 'Hubo un problema con la conexión',
        PENDING: 'Inicializada',
        FINISHED: 'Finalizada',
        SUCCESSFUL: 'Satisfactoria',
        UNSUCCESSFUL: 'No satisfactoria',
        USER_NOT_FOUND: 'El usuario no se encontró en nuestros sistemas',
        CUSTOMER_NOT_FOUND: 'El cliente no existe',
        DELEGATION_NOT_FOUND: 'La delegación no existe',
        CUSTOMER_CONTRACT_NOT_FOUND: 'El contrato indicado no existe',
        ASSESSMENT_PACKAGE_NOT_FOUND: 'El paquete no existe',
        INSPECTION_NOT_FOUND: 'La evaluación no existe',
        INSPECTION_FILE_NOT_FOUND: 'El archivo no existe',
        QUESTIONNAIRE_NOF_FOUND: 'El cuestionario no existe',
        PACKAGE_HAS_QUESTIONNAIRE: 'El paquete ya tiene este cuestionario asociado',
        OPERATIONAL: 'Operativa',
        STRUCTURE: 'Estructura',
        SAVED: 'Guardada',
        DISCARDED: 'Descartada',
        AWAITING_SIGNATURE: 'Pendiente',
    }
    let wordTranslate = word
    try {
        if (words[word]) {
            wordTranslate = words[word]
        }
    } catch (error) {}

    return wordTranslate
}
