import { connect } from 'react-redux'
import AssessmentPackagesScreen from './AssessmentPackagesScreen'
import { fetchAssessmentPackages } from '../../store/assessmentPackages/actions'
import { getAssessmentPackages, getIsLoadingAssessmentPackages } from '../../store/assessmentPackages/reducers'

const mapStateToProps = state => ({
    isLoading: getIsLoadingAssessmentPackages(state),
    assesmentPackages: getAssessmentPackages(state)
})

const mapDispatchToProps = dispatch => ({
    fetchPackages: reactTableState => {
        dispatch(fetchAssessmentPackages(reactTableState))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssessmentPackagesScreen)
