import auth from './auth/reducers'
import general from './general/reducers'
import ui from './ui/reducers'
import customers from './customers/reducers'
import customerServices from './customerServices/reducers'
import watchmen from './watchmen/reducers'
import assessmentData from './assessmentData/reducers'
import inspection from './inspection/reducers'
import inspectionAttachments from './inspectionAttachments/reducers'
import customerContracts from './customerContracts/reducers'
import historyInspections from './historyInspections/reducers'
import historyInspectionFilters from './historyInspectionFilters/reducers'
import delegationsCustomerCompany from './delegationsCustomerCompany/reducers'
import assessmentQuestionnaireManagement from './assessmentQuestionnaireManagement/reducers'
import assessmentQuestionnaires from './assessmentQuestionnaires/reducers'
import assessmentPackages from './assessmentPackages/reducers'
import assessmentPackageManagement from './assessmentPackageManagement/reducers'
import inspectionTicket from './inspectionTickets/reducers'

import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'

import { loadState } from '../localStorage'
import axiosAuth from './helperApi'

const reducersConfig = {
    auth,
    general,
    ui,
    customers,
    customerServices,
    watchmen,
    assessmentData,
    inspection,
    inspectionAttachments,
    customerContracts,
    historyInspections,
    historyInspectionFilters,
    delegationsCustomerCompany,
    assessmentQuestionnaireManagement,
    assessmentQuestionnaires,
    assessmentPackages,
    assessmentPackageManagement,
    inspectionTicket
}

export const configureStore = historyRoutes => {
    let middlewares = [thunk, axiosAuth, routerMiddleware(historyRoutes)]
    if (process.env.NODE_ENV === `development`) {
        const logger = createLogger({
            collapsed: true,
            predicate: (getState, action) => !action.type.includes('redux-form'),
        })
        middlewares.push(logger)
    }

    const persistedState = loadState()

    const reducer = combineReducers(reducersConfig)

    return createStore(reducer, persistedState, applyMiddleware(...middlewares))
}
