import React from 'react'

let Button = props => {
    let cssClasses = `button__${props.type}`
    let onClick = props.onClick

    if (props.disabled) {
        cssClasses += ' button__disabled'
        onClick = e => {
            e.preventDefault()
            return null
        }
    }
    return (
        <button className={cssClasses} onClick={onClick} title={props.title} data-cy={props.dataCY} type={props.type === 'submit' ? props.type : null}>
            <i className={props.iconCssClasses} />
            {props.children}
        </button>
    )
}

export default Button
