import React from 'react'
import Spinner from '../../shared/Spinner'
import ButtonTab from '../../shared/ButtonTab'
import AssessmentPackageGeneralTab from '../../components/assessmentPackageTabs/AssessmentPackageGeneralTab'
import AssessmentPackageQuestionnairesTab from '../../components/assessmentPackageTabs/AssessmentPackageQuestionnairesTab'

const tabSettings = {
    general: {
        key: 'general',
        component: AssessmentPackageGeneralTab,
        name: 'General',
        showOnCreating: true,
    },
    packageQuestionnaires: {
        key: 'packageQuestionnaires',
        component: AssessmentPackageQuestionnairesTab,
        name: 'Cuestionarios',
        showOnCreating: false,
    },
}

export default class AssessmentPackagesManagementScreen extends React.Component {
    defaultState = {
        currentTab: tabSettings.general.key,
        packageData: {
            title: '',
            enabled: true,
            showColaboratorInputs:true,
        },
        packageCompanies: [],
        assessmentPackageTypes: [],
    }
    constructor(props) {
        super(props)
        this.state = {
            ...this.defaultState,
        }
    }

    componentDidMount() {
        this.props.fetchCompanies()
        window.scrollTo(0, 0)
        this.props.resetAssessmentPackageReducer()
        if (this.props.packageId) {
            this.props.fetchAssessmentPackage(this.props.packageId)
        }
        this.mapPropToSate()
    }

    componentDidUpdate(prevProps) {
        this.mapPropToSate(prevProps)
    }

    componentWillUnmount() {
        this.props.resetAssessmentPackageReducer()
    }

    mapPropToSate(prevProps = null) {
        if (this.props.packageData && (!prevProps || !prevProps.packageData || JSON.stringify(this.props.packageData) !== JSON.stringify(prevProps.packageData))) {
            this.setState({
                packageData: {
                    title: this.props.packageData.title,
                    enabled: this.props.packageData.enabled !== undefined ? this.props.packageData.enabled : true,
                    showColaboratorInputs: this.props.packageData.showColaboratorInputs !== undefined ? this.props.packageData.showColaboratorInputs : true,
                },
                packageCompanies: this.props.packageCompanies ? this.props.packageCompanies : [],
                assessmentPackageTypes: this.props.assessmentPackageTypes ? this.props.assessmentPackageTypes : [],
            })
        }
    }

    onHandleChangePackageDataInput = (key, value) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                packageData: {
                    ...prevState.packageData,
                    [key]: value,
                },
            }
        })
    }

    onHandleChangePackageCompanyInput = (newCompanies) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                packageCompanies: newCompanies,
            }
        })
    }

    onHandleChangeAssessmentPackageTypeInput = (newAssessmentPackageTypes) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                assessmentPackageTypes: newAssessmentPackageTypes,
            }
        })
    }

    onHandleUpdateOrCreateAssessmentPackageData = () => {
        if (this.props.packageId) {
            this.props.updateAssessmentPackage(
                this.props.packageId,
                this.state.packageData.title,
                this.state.packageData.enabled,
                this.state.packageData.showColaboratorInputs,
                this.state.packageCompanies,
                this.state.assessmentPackageTypes)
        } else {
            this.props.createAssessmentPackage(this.state.packageData.title, 
                this.state.packageData.enabled,
                this.state.packageData.showColaboratorInputs,
                this.state.packageCompanies, 
                this.state.assessmentPackageTypes)
        }
    }

    render() {
        const ComponentTabToRender = tabSettings[this.state.currentTab].component
        const { errors, packageId, deleteAssessmentPackage, companies } = this.props

        return (
            <div>
                <section className="admin__body">
                    {this.props.isLoading && <Spinner />}
                    <div className="admin__panel">
                        <h1 className="panel__title">Detalles del paquete</h1>
                        <div className="panel__body">
                            {Object.keys(tabSettings).map((key, i) => (
                                <ButtonTab
                                    key={key}
                                    disabled={tabSettings[key].showOnCreating === false && (this.props.packageId === null || this.props.packageId === undefined)}
                                    active={key === this.state.currentTab}
                                    onClick={(e) => {
                                        this.setState({ currentTab: key })
                                    }}
                                >
                                    <span> {tabSettings[key].name}</span>
                                </ButtonTab>
                            ))}
                        </div>
                        <ComponentTabToRender
                            errors={errors}
                            packageId={packageId}
                            packageData={this.state.packageData}
                            onHandleChangePackageDataInput={this.onHandleChangePackageDataInput}
                            packageCompanies={this.state.packageCompanies}
                            assessmentPackageTypes={this.state.assessmentPackageTypes}
                            onHandleChangePackageCompanyInput={this.onHandleChangePackageCompanyInput}
                            onHandleChangeAssessmentPackageTypeInput={this.onHandleChangeAssessmentPackageTypeInput}
                            onSubmitPackageData={this.onHandleUpdateOrCreateAssessmentPackageData}
                            onHandleDeletePackage={deleteAssessmentPackage}
                            companies={companies}
                        />
                    </div>
                </section>
            </div>
        )
    }
}
