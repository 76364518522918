import { actionTypes as actionTypesAuth } from './actions'

const initState = {
    isAuthenticated: false,
    isLoading: false,
    isFetchedGrants: false,
    token: null,
    userPoseidonId: null,
    userAppId: null,
    username: null,
    userType: null,
    permissions: null,
    userWorkerPoseidonId: null,
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case actionTypesAuth.LOGIN_REQUEST:
            return { ...initState, isLoading: true }
        case actionTypesAuth.FETCH_GRANT_REQUEST:
            return { ...state, isLoading: true }

        case actionTypesAuth.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                token: action.token,
                username: action.userName,
            }
        case actionTypesAuth.FETCH_GRANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFetchedGrants: true,
                permissions: action.grants,
                userPoseidonId: action.userPoseidonId,
                userAppId: action.userAppId,
                userPoseidonWorkerId: action.userPoseidonWorkerId,
            }
        case actionTypesAuth.FETCH_GRANT_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFetchedGrants: true,
                permissions: null,
            }
        default:
            return state
    }
}

export const isLoadingAuth = (state) => {
    return state.auth.isLoading
}

export const isAuthenticated = (state) => {
    return state.auth.isAuthenticated
}

export const isFetchedGrants = (state) => {
    return state.auth.isFetchedGrants
}

export const getToken = (state) => {
    return state.auth.token
}

export const getUserName = (state) => {
    return state.auth.username
}

export const getUserId = (state) => {
    return state.auth.userPoseidonId
}

export const getUserRolId = (state) => {
    return state.auth.permissions[0].role_id
}

export const getPermissionByTool = (state, tool) => {
    if (!state.auth) return null
    const permissions = state.auth.permissions
    if (!permissions) return null
    const toolPermission = state.auth.permissions.filter((permission) => {
        if (permission.public_key === '0') return true
        if (permission.tool_id === 0 || permission.tool_code.toLowerCase() === tool.toLowerCase()) return true
        return false
    })
    return toolPermission
}

export const canDoInspection = (state) => {
    let permissions = getPermissionByTool(state, 'DO_INSPECTION')
    if (!permissions) return false

    return permissions.find(
        (permission) => permission.public_key === '0' || permission.tool_code === 'DO_INSPECTION' || (permission.public_key === 'INSPECTIONS_APP' && permission.tool_id === 0)
    )
}

export const canDoInspectionStructureAssessment = (state) => {
    let permissions = getPermissionByTool(state, 'DO_STRUCTURE_ASSESSMENT')
    if (!permissions) return false

    return permissions.find(
        (permission) =>
            permission.public_key === '0' || permission.tool_code === 'DO_STRUCTURE_ASSESSMENT' || (permission.public_key === 'INSPECTIONS_APP' && permission.tool_id === 0)
    )
}

export const canViewHistoryInspections = (state) => {
    let permissions = getPermissionByTool(state, 'VIEW_INSPECTION_EXECUTED')
    if (!permissions) return false

    return permissions.find(
        (permission) =>
            permission.public_key === '0' || permission.tool_code === 'VIEW_INSPECTION_EXECUTED' || (permission.public_key === 'INSPECTIONS_APP' && permission.tool_id === 0)
    )
}

export const canAdminQuestionnairePackages = (state) => {
    let permissions = getPermissionByTool(state, 'ADMIN_PACKAGES_QUESTIONNAIRES')
    if (!permissions) return false

    return permissions.find(
        (permission) =>
            permission.public_key === '0' || permission.tool_code === 'ADMIN_PACKAGES_QUESTIONNAIRES' || (permission.public_key === 'INSPECTIONS_APP' && permission.tool_id === 0)
    )
}

export const getUserWorkerId = (state) => {
    return state.auth.userPoseidonWorkerId
}

export default auth
