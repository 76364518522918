import { connect } from 'react-redux'
import AssessmentPackagesManagementScreen from './AssessmentPackagesManagementScreen'

import {
    createAssessmentPackage,
    updateAssessmentPackage,
    fetchAssessmentPackage,
    resetAssessmentPackageReducer,
    deleteAssessmentPackage,
} from '../../store/assessmentPackageManagement/actions'
import {
    getAssessmentPackageData,
    isLoadingAssessmentPackageManagement,
    getAssessmentPackagesCompanies,
    getAssessmentPackageErrors,
    getAssessmentPackageTypes,
} from '../../store/assessmentPackageManagement/reducers'
import { getCompanies } from '../../store/general/reducers'
import { fetchCompanies } from '../../store/general/actions'

const mapStateToProps = (state, { match }) => ({
    isLoading: isLoadingAssessmentPackageManagement(state),
    packageId: match.params.packageId,
    packageData: getAssessmentPackageData(state),
    packageCompanies: getAssessmentPackagesCompanies(state),
    assessmentPackageTypes: getAssessmentPackageTypes(state),
    companies: getCompanies(state),
    errors: getAssessmentPackageErrors(state),
})

const mapDispatchToProps = (dispatch) => ({
    createAssessmentPackage: (title, enabled, showColaboratorInputs, companies, assessmentPackageTypes) => {
        dispatch(createAssessmentPackage(title, enabled, showColaboratorInputs, companies, assessmentPackageTypes))
    },
    updateAssessmentPackage: (packageId, title, enabled, showColaboratorInputs, companies, assessmentPackageTypes) => {
        dispatch(updateAssessmentPackage(packageId, title, enabled, showColaboratorInputs,companies, assessmentPackageTypes))
    },
    deleteAssessmentPackage: (packageId) => {
        dispatch(deleteAssessmentPackage(packageId))
    },
    fetchAssessmentPackage: (packageId) => {
        dispatch(fetchAssessmentPackage(packageId))
    },
    resetAssessmentPackageReducer: () => {
        dispatch(resetAssessmentPackageReducer())
    },
    fetchCompanies: () => {
        dispatch(fetchCompanies())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentPackagesManagementScreen)
