import * as Api from './api'
import { errorHandler } from '../helperApi'
import { push } from 'react-router-redux'
import { routesConst, setVariableToRoute } from '../../Routes'
import { getAssessmentQuestionnaireItems, getAssessmentQuestionnaireScores } from './reducers'

export const actionsTypes = {
    RESET_ASSESSMENT_QUESTIONNAIRE: 'RESET_ASSESSMENT_QUESTIONNAIRE',
    CREATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST: 'CREATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST',
    CREATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS: 'CREATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS',
    CREATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE: 'CREATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE',

    UPDATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST: 'UPDATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST',
    UPDATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS: 'UPDATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS',
    UPDATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE: 'UPDATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE',

    DELETE_ASSESSMENT_QUESTIONNNAIRE_REQUEST: 'DELETE_ASSESSMENT_QUESTIONNNAIRE_REQUEST',
    DELETE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS: 'DELETE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS',
    DELETE_ASSESSMENT_QUESTIONNNAIRE_FAILURE: 'DELETE_ASSESSMENT_QUESTIONNNAIRE_FAILURE',

    FETCH_QUESTIONNAIRE_DATA_REQUEST: 'FETCH_QUESTIONNAIRE_DATA_REQUEST',
    FETCH_QUESTIONNAIRE_DATA_SUCCESS: 'FETCH_QUESTIONNAIRE_DATA_SUCCESS',
    FETCH_QUESTIONNAIRE_DATA_FAILURE: 'FETCH_QUESTIONNAIRE_DATA_FAILURE',

    ADD_QUESTIONNAIRE_ITEM_REQUEST: 'ADD_QUESTIONNAIRE_ITEM_REQUEST',
    EDIT_QUESTIONNAIRE_ITEM_REQUEST: 'EDIT_QUESTIONNAIRE_ITEM_REQUEST',
    EDIT_QUESTIONNAIRE_ITEM_POSITION_REQUEST: 'EDIT_QUESTIONNAIRE_ITEM_POSITION_REQUEST',

    SAVE_QUESTIONNNAIRE_ITEMS_REQUEST: 'SAVE_QUESTIONNNAIRE_ITEMS_REQUEST',
    SAVE_QUESTIONNNAIRE_ITEMS_SUCCESS: 'SAVE_QUESTIONNNAIRE_ITEMS_SUCCESS',
    SAVE_QUESTIONNNAIRE_ITEMS_FAILURE: 'SAVE_QUESTIONNNAIRE_ITEMS_FAILURE',

    DELETE_QUESTIONNNAIRE_ITEM_REQUEST: 'DELETE_QUESTIONNNAIRE_ITEM_REQUEST',
    DELETE_QUESTIONNNAIRE_ITEM_SUCCESS: 'DELETE_QUESTIONNNAIRE_ITEM_SUCCESS',
    DELETE_QUESTIONNNAIRE_ITEM_FAILURE: 'DELETE_QUESTIONNNAIRE_ITEM_FAILURE',

    ADD_QUESTIONNAIRE_ITEM_SCORE_REQUEST: 'ADD_QUESTIONNAIRE_ITEM_SCORE_REQUEST',
    EDIT_QUESTIONNAIRE_ITEM_SCORE_REQUEST: 'EDIT_QUESTIONNAIRE_ITEM_SCORE_REQUEST',
    EDIT_QUESTIONNAIRE_ITEM_SCORE_POSITION_REQUEST: 'EDIT_QUESTIONNAIRE_ITEM_SCORE_POSITION_REQUEST',

    SAVE_QUESTIONNNAIRE_ITEM_SCORES_REQUEST: 'SAVE_QUESTIONNNAIRE_ITEM_SCORES_REQUEST',
    SAVE_QUESTIONNNAIRE_ITEM_SCORES_SUCCESS: 'SAVE_QUESTIONNNAIRE_ITEM_SCORES_SUCCESS',
    SAVE_QUESTIONNNAIRE_ITEM_SCORES_FAILURE: 'SAVE_QUESTIONNNAIRE_ITEM_SCORES_FAILURE',

    DELETE_QUESTIONNNAIRE_ITEM_SCORE_REQUEST: 'DELETE_QUESTIONNNAIRE_ITEM_SCORE_REQUEST',
    DELETE_QUESTIONNNAIRE_ITEM_SCORE_SUCCESS: 'DELETE_QUESTIONNNAIRE_ITEM_SCORE_SUCCESS',
    DELETE_QUESTIONNNAIRE_ITEM_SCORE_FAILURE: 'DELETE_QUESTIONNNAIRE_ITEM_SCORE_FAILURE'
}

export const resetAssessmentQuestionnaireReducer = () => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.RESET_ASSESSMENT_QUESTIONNAIRE
    })
}

export const createAssessmentQuestionnaire = (title, description, minScoreSuccess, enabled, questionnaireType) => (dispatch, getState) => {
    const body = {
        title,
        description,
        minScoreSuccess,
        enabled,
        questionnaireType
    }
    dispatch({
        type: actionsTypes.CREATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST,
        body
    })

    return Api.createAssessmentQuestionnaire(body)
        .then(response => {
            const newQuestionnaireCreated = response.data.questionnaireCreated

            dispatch({
                type: actionsTypes.CREATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS,
                newQuestionnaireCreated
            })

            const editQuestionnaireRoute = setVariableToRoute(routesConst.assessmentQuestionaireManagementEdit, newQuestionnaireCreated.id)

            dispatch(push(editQuestionnaireRoute))
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.CREATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE, error: 'Revisa los campos', errors: errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const updateAssessmentQuestionnaire = (questionnaireId, title, description, minScoreSuccess, enabled, questionnaireType) => (dispatch, getState) => {
    const body = {
        title,
        description,
        minScoreSuccess,
        enabled,
        questionnaireType
    }
    dispatch({
        type: actionsTypes.UPDATE_ASSESSMENT_QUESTIONNNAIRE_REQUEST,
        questionnaireId,
        body
    })

    return Api.updateAssessmentQuestionnaire(questionnaireId, body)
        .then(response => {
            const questionnaire = response.data.questionnaire
            dispatch({
                type: actionsTypes.UPDATE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS,
                questionnaireData: questionnaire
            })
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.UPDATE_ASSESSMENT_QUESTIONNNAIRE_FAILURE, error: 'Revisa los campos', errors: errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const deleteAssessmentQuestionnaire = questionnaireId => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.DELETE_ASSESSMENT_QUESTIONNNAIRE_REQUEST,
        questionnaireId
    })

    return Api.deleteAssessmentQuestionnaire(questionnaireId)
        .then(response => {
            dispatch({
                type: actionsTypes.DELETE_ASSESSMENT_QUESTIONNNAIRE_SUCCESS
            })
            dispatch(push(routesConst.assessmentQuestionaires))
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.DELETE_ASSESSMENT_QUESTIONNNAIRE_FAILURE, error: 'No se pudo eliminar el cuestionario', errors: errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchAssessmentQuestionnaire = questionnaireId => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.FETCH_QUESTIONNAIRE_DATA_REQUEST,
        questionnaireId
    })

    return Api.fetchAssessmentQuestionnaireDataApi(questionnaireId)
        .then(response => {
            const questionnaire = response.data.questionnaire

            dispatch({
                type: actionsTypes.FETCH_QUESTIONNAIRE_DATA_SUCCESS,
                questionnaireData: questionnaire.data,
                items: questionnaire.items,
                scores: questionnaire.scores
            })
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.FETCH_QUESTIONNAIRE_DATA_FAILURE, error: 'Error al obtener el cuestionario' })
                dispatch(push(routesConst.assessmentQuestionaires))
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const addItem = (title, description) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.ADD_QUESTIONNAIRE_ITEM_REQUEST, title, description })
}

export const editItem = (indexItem, title, description) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.EDIT_QUESTIONNAIRE_ITEM_REQUEST, indexItem, title, description })
}

export const changeItemPosition = (oldPosition, newPosition) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.EDIT_QUESTIONNAIRE_ITEM_POSITION_REQUEST, oldPosition, newPosition })
}

export const saveAssessmentQuestionnaireItems = questionnaireId => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.SAVE_QUESTIONNNAIRE_ITEMS_REQUEST
    })
    const items = getAssessmentQuestionnaireItems(getState())

    return Api.saveAssessmentQuestionnaireItemsApi(questionnaireId, items)
        .then(response => {
            dispatch({
                type: actionsTypes.SAVE_QUESTIONNNAIRE_ITEMS_SUCCESS,
                items: response.data.updatedItems.items
            })
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.SAVE_QUESTIONNNAIRE_ITEMS_FAILURE, error: 'Error al guardar los items', errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const deleteAssessmentQuestionnaireItem = (questionnaireId, questionnaireIndex) => (dispatch, getState) => {
    let items = getAssessmentQuestionnaireItems(getState())

    const itemToDelete = items[questionnaireIndex]
    if (!itemToDelete) return
    if (!itemToDelete.id) {
        const newItems = items.filter((item, index) => index !== questionnaireIndex)

        dispatch({
            type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_SUCCESS,
            items: newItems
        })
        return
    }
    const itemId = itemToDelete.id
    dispatch({
        type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_REQUEST,
        itemId
    })

    return Api.deleteAssessmentQuestionnaireItemsApi(questionnaireId, itemId)
        .then(response => {
            dispatch({
                type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_SUCCESS,
                items: response.data.questionnaireItems.items
            })
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_FAILURE, error: 'Error al eliminar el item', errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const addItemScore = (title, description, score) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.ADD_QUESTIONNAIRE_ITEM_SCORE_REQUEST, title, score, description })
}

export const editItemScore = (indexItemScore, title, description, score) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.EDIT_QUESTIONNAIRE_ITEM_SCORE_REQUEST, indexItemScore, title, description, score })
}

export const changeItemScorePosition = (oldPosition, newPosition) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.EDIT_QUESTIONNAIRE_ITEM_SCORE_POSITION_REQUEST, oldPosition, newPosition })
}

export const saveAssessmentQuestionnaireScores = questionnaireId => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.SAVE_QUESTIONNNAIRE_ITEM_SCORES_REQUEST,
        questionnaireId
    })
    const scores = getAssessmentQuestionnaireScores(getState())
    console.log(scores);
    return Api.saveAssessmentQuestionnaireScoresApi(questionnaireId, scores)
        .then(response => {
            dispatch({
                type: actionsTypes.SAVE_QUESTIONNNAIRE_ITEM_SCORES_SUCCESS,
                scores: response.data.updatedScores.scores
            })
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.SAVE_QUESTIONNNAIRE_ITEM_SCORES_FAILURE, error: 'Error al guardar los items', errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const deleteAssessmentQuestionnaireScore = (questionnaireId, scoreIndex) => (dispatch, getState) => {
    let scores = getAssessmentQuestionnaireScores(getState())

    const scoreToDelete = scores[scoreIndex]

    if (!scoreToDelete) return
    if (!scoreToDelete.id) {
        const newScores = scores.filter((score, index) => index !== scoreIndex)

        dispatch({
            type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_SCORE_SUCCESS,
            scores: newScores
        })
        return
    }
    const scoreId = scoreToDelete.id
    dispatch({
        type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_SCORE_REQUEST,
        itemId: scoreId
    })

    return Api.deleteAssessmentQuestionnaireScoresApi(questionnaireId, scoreId)
        .then(response => {
            dispatch({
                type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_SCORE_SUCCESS,
                scores: response.data.questionnaireItems.scores
            })
        })
        .catch(err => {
            const actionError = errors => {
                dispatch({ type: actionsTypes.DELETE_QUESTIONNNAIRE_ITEM_SCORE_FAILURE, error: 'Error al eliminar la puntuación', errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}
