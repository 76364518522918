import React, {useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchInspectionAttachments} from '../../store/inspectionAttachments/actions'
import { getInspectionAttachments } from '../../store/inspectionAttachments/reducers'
import { getToken } from '../../store/auth/reducers'
import { setTokenAuthParam } from '../../store/helperApi'
import TableNormal from '../../shared/Table/TableNormal'

const mapStateToProps = (state, ownProps) => {
    return {
        props:ownProps,
        inspectionAttachments: getInspectionAttachments(state),
        token: getToken(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAttachments: inspectionId => {
            dispatch(fetchInspectionAttachments(inspectionId))
        },
    }
}

/**
 * Muestra los adjuntos pertenecientes a una evaluación
 */
export const InspectionAttachmentsList = ({props, fetchAttachments, inspectionAttachments, token}) => {

    const [inspectionId] = useState(props.inspectionId);

    useEffect(() => {
        fetchAttachments(inspectionId);
        // eslint-disable-next-line
    }, [])

    return (
        <div>
        {inspectionAttachments && (
            <TableNormal
                style={{width: "100%"}}
                data={inspectionAttachments}
                columns={[
                    { Header: 'Descripción', accessor: 'description' },
                    {
                        Header: '',
                        Cell: ({ original }) => {
                            return (
                                <div>
                                    <a className="button__mini" target="_blank" href={setTokenAuthParam(token, original.urlDownload)} rel="noopener noreferrer">
                                        <i className="fas fa-download" />
                                    </a>
                                </div>
                            )
                        }
                    }
                ]}
                defaultPageSize={5}
                defaultSorted={[{ id: 'description', asc: true }]}
            />

        )}
        </div>

    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionAttachmentsList)