import { runPost, fetchGet, runDelete } from '../helperApi'

export const createAssessmentPackage = body => {
    return runPost(`package`, body)
}

export const updateAssessmentPackage = (packageId, body) => {
    return runPost(`package/${packageId}`, body)
}

export const deleteAssessmentPackage = packageId => {
    return runDelete(`package/${packageId}`)
}

export const fetchAssessmentPackageDataApi = packageId => {
    return fetchGet(`package/${packageId}`)
}

export const saveAssessmentPackageQuestionnaireApi = (packageId, questionnaireId) => {
    return runPost(`package/${packageId}/questionnaire/${questionnaireId}`, { questionnaireId })
}

export const deleteAssessmentPackageQuestinnaireApi = (packageId, questionnaireId) => {
    return runDelete(`package/${packageId}/questionnaire/${questionnaireId}`)
}

export const getAvailablePackageQuestionnaires = packageId => {
    return fetchGet(`package/${packageId}/available-questionnaires`)
}

export const updatePackageQuestionnairesOrder = (packageId, questionnairesPosition) => {
    return runPost(`package/${packageId}/update-questionnaires-order`, { questionnairesPosition })
}
