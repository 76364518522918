import { connect } from 'react-redux'
import AssessmentQuestionnaireManagementScreen from './AssessmentQuestionnaireManagementScreen'
import {
    createAssessmentQuestionnaire,
    fetchAssessmentQuestionnaire,
    updateAssessmentQuestionnaire,
    resetAssessmentQuestionnaireReducer,
    deleteAssessmentQuestionnaire
} from '../../store/assessmentQuestionnaireManagement/actions'
import {
    isLoadingAssessmentQuestionnnaireManagement,
    getAssessmentQuestionnaireData,
    getAssessmentQuestionnaireItems,
    getAssessmentQuestionnaireScores,
    getAssessmentQuestionnaireErrors
} from '../../store/assessmentQuestionnaireManagement/reducers'

const mapStateToProps = (state, { match }) => ({
    isLoading: isLoadingAssessmentQuestionnnaireManagement(state),
    questionnaireId: match.params.questionnaireId,
    questionnaireData: getAssessmentQuestionnaireData(state),
    questionnaireItems: getAssessmentQuestionnaireItems(state),
    questionnnaireScores: getAssessmentQuestionnaireScores(state),
    errors: getAssessmentQuestionnaireErrors(state)
})

const mapDispatchToProps = dispatch => ({
    createAssessmentQuestionnaire: (title, description, minScore, enabled, questionnnaireType) => {
        dispatch(createAssessmentQuestionnaire(title, description, minScore, enabled, questionnnaireType))
    },
    updateAssessmentQuestionnaire: (questionnaireId, title, description, minScore, enabled, questionnnaireType) => {
        dispatch(updateAssessmentQuestionnaire(questionnaireId, title, description, minScore, enabled, questionnnaireType))
    },
    fetchAssessmentQuestionnaire: questionnaireId => {
        dispatch(fetchAssessmentQuestionnaire(questionnaireId))
    },
    deleteAssessmentQuestionnaire: questionnaireId => {
        dispatch(deleteAssessmentQuestionnaire(questionnaireId))
    },
    resetAssessmentQuestionnaireReducer: () => {
        dispatch(resetAssessmentQuestionnaireReducer())
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssessmentQuestionnaireManagementScreen)
