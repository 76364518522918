import React from 'react'

let ButtonTab = props => {
    let onClick = props.onClick
    let cssClasses = props.active ? 'actions__tab--active' : 'actions__tab'
    if (props.disabled) {
        cssClasses += ' actions__tab--disabled'
        onClick = e => {
            e.preventDefault()
            return null
        }
    }
    return (
        <button className={cssClasses} onClick={onClick} title={props.title}>
            {props.children}
        </button>
    )
}

export default ButtonTab
