import { actionsTypes as actionsTypesHistoryInspections } from './actions'

const initState = {
    loading: false,
    filter: null,
    inspections: [],
    failureMessage: null,
    pages: -1
}

const historyInspections = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesHistoryInspections.FETCH_HISTORY_INSPECTIONS_REQUEST:
            return { ...state, loading: true }
        case actionsTypesHistoryInspections.FETCH_HISTORY_INSPECTIONS_SUCCESS:
            return { ...state, loading: false, inspections: action.inspections, pages: action.pages, total: action.total }
        // case actionsTypesHistoryInspections.FETCH_HISTORY_INSPECTIONS_FAILURE:
        //     return { ...state, loading: false, failureMessage: action.failureMessage }

        default:
            return state
    }
}

export const getIsLoadingHistoryInspections = state => {
    return state.historyInspections.loading
}

export const getHistoryInspections = state => {
    return state.historyInspections.inspections
}

export const getHistoryInspectionsPages = state => {
    return state.historyInspections.pages
}

export const getHistoryInspectionsTotal = state => {
    return state.historyInspections.total
}

export default historyInspections
