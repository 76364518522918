import * as Api from './api'
import { errorHandler } from '../helperApi'
import { arrayToObject } from '../HelperActions'

export const actionsTypes = {
    FETCH_CUSTOMER_CONTRACTS_REQUEST: 'FETCH_CUSTOMER_CONTRACTS_REQUEST',
    FETCH_CUSTOMER_CONTRACTS_SUCCESS: 'FETCH_CUSTOMER_CONTRACTS_SUCCESS',
    FETCH_CUSTOMER_CONTRACTS_FAILURE: 'FETCH_CUSTOMER_CONTRACTS_FAILURE',

    FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_REQUEST: 'FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_REQUEST',
    FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_SUCCESS: 'FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_SUCCESS',
    FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_FAILURE: 'FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_FAILURE'
}

export const fetchCustomerContracts = (companyId, customerId, reactTableState) => (dispatch, getState) => {
    const filtered = reactTableState && reactTableState.filtered ? arrayToObject(reactTableState.filtered) : null
    const sorted = reactTableState && reactTableState.sorted ? reactTableState.sorted : null
    const pageSize = reactTableState && reactTableState.pageSize ? reactTableState.pageSize : 5
    const page = reactTableState ? parseInt(reactTableState.page) + 1 : 1

    const params = {
        page,
        sorted,
        ...filtered,
        pageSize
    }

    dispatch({ type: actionsTypes.FETCH_CUSTOMER_CONTRACTS_REQUEST, params })

    return Api.fetchCustomerContractsApi(companyId, customerId, params)
        .then(response => {
            dispatch({
                type: actionsTypes.FETCH_CUSTOMER_CONTRACTS_SUCCESS,
                customerContracts: response.data.customerContracts.data,
                pages: response.data.customerContracts.lastPage
            })
        })
        .catch(err => {
            dispatch({ type: actionsTypes.FETCH_CUSTOMER_CONTRACTS_FAILURE, error: 'No se pudieron obtener los contratos del cliente' })
            errorHandler(dispatch, err.response, err)
        })
}

export const fetchCustomerContractsLiveSearch = (companyId, customerId, contractName) => (dispatch, getState) => {
    const params = {
        companyId,
        customerId,
        contractName
    }
    dispatch({ type: actionsTypes.FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_REQUEST, params })

    return Api.fetchCustomerContractsLiveSearchApi(customerId, params)
        .then(response => {
            const customerContracts = response.data
            dispatch({
                type: actionsTypes.FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_SUCCESS,
                customerContracts
            })

            return Promise.resolve(customerContracts)
        })
        .catch(err => {
            dispatch({ type: actionsTypes.FETCH_CUSTOMER_CONTRACTS_LIVE_SEARCH_FAILURE, error: 'No se pudieron obtener los contratos' })
            errorHandler(dispatch, err.response, err)
            return Promise.reject([])
        })
}
