import React, { useEffect } from 'react'
import { getErrorMessage } from '../store/ui/reducers'
import { clearErrorMessage } from '../store/ui/actions'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
    errorMessage: getErrorMessage(state)
})

const mapDispatchToProps = dispatch => ({
    clearErrorMessage: () => {
        dispatch(clearErrorMessage())
    }
})

const ErrorScreeen = ({ errorMessage, clearErrorMessage }) => {
    const componentWillUnmounted = () => {
        clearErrorMessage()
    }

    useEffect(() => {
        return () => {
            console.log('desmontó')
            componentWillUnmounted()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <section className="admin__head">
                <div className="admin__profile">
                    <div className="profile__data">
                        <p className="data__title">Ocurrió un error</p>
                    </div>
                </div>
            </section>
            <section className="admin__body">
                <div className="admin__panel">
                    <h1 className="panel__title">Mensaje de error</h1>

                    <div className="panel__body">
                        <div className="panel__inner">{errorMessage}</div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorScreeen)
