import React from 'react'
const Form = props => (
    <form onSubmit={props.onSubmit} autoComplete="off">
        <section className="admin__body">
            <div className="admin__panel">
                <h1 className="panel__title">{props.title}</h1>
                <div className="panel__body">{props.children}</div>
            </div>
        </section>
    </form>
)
export default Form
