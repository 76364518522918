export const isMobile = () => {
    return window.innerWidth <= 768
}

export const scrollToRef = ref => {
    if (!ref) return

    window.scrollTo(0, ref.current.offsetTop)
}

export const parseOptionsListIdNameToReactSelect = options => {
    if (!options) return options
    return options.map(option => {
        return {
            label: option.name,
            value: option.id
        }
    })
}

export const deleteCookie = name => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}
