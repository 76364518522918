import React from 'react'
import QuestionnaireItems from './QuestionnaireItems'
import { connect } from 'react-redux'
import { getAssessmentQuestionnaireItems, getAssessmentQuestionnaireScores } from '../../store/assessmentQuestionnaireManagement/reducers'
import {
    addItem,
    editItem,
    changeItemPosition,
    saveAssessmentQuestionnaireItems,
    deleteAssessmentQuestionnaireItem,
    addItemScore,
    editItemScore,
    changeItemScorePosition,
    saveAssessmentQuestionnaireScores,
    deleteAssessmentQuestionnaireScore
} from '../../store/assessmentQuestionnaireManagement/actions'
import QuestionnaireItemScores from './QuestionnaireItemScores'

const mapStateToProps = state => ({
    items: getAssessmentQuestionnaireItems(state),
    scores: getAssessmentQuestionnaireScores(state)
})

const mapDispatchToProps = dispatch => ({
    addItem: (title, description) => {
        dispatch(addItem(title, description))
    },
    editItem: (itemId, title, description) => {
        dispatch(editItem(itemId, title, description))
    },
    changeItemPosition: (oldPosition, newPosition) => {
        dispatch(changeItemPosition(oldPosition, newPosition))
    },
    saveAssessmentQuestionnaireItems: questionnaireId => {
        dispatch(saveAssessmentQuestionnaireItems(questionnaireId))
    },
    deleteAssessmentQuestionnaireItem: (questionnaireId, itemIndex) => {
        dispatch(deleteAssessmentQuestionnaireItem(questionnaireId, itemIndex))
    },
    addItemScore: (title, description, score) => {
        dispatch(addItemScore(title, description, score))
    },
    editItemScore: (itemId, title, description, score) => {
        dispatch(editItemScore(itemId, title, description, score))
    },
    changeItemScorePosition: (oldPosition, newPosition) => {
        dispatch(changeItemScorePosition(oldPosition, newPosition))
    },

    saveAssessmentQuestionnaireScores: questionnaireId => {
        dispatch(saveAssessmentQuestionnaireScores(questionnaireId))
    },
    deleteAssessmentQuestionnaireScore: (questionnaireId, scoreIndex) => {
        dispatch(deleteAssessmentQuestionnaireScore(questionnaireId, scoreIndex))
    }
})

class AssessmentQuestionnaireItemsScoresTab extends React.Component {
    state = {
        questionnaireItems: [],
        questionnaireScores: []
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const {
            questionnaireId,
            items,
            scores,
            addItem,
            editItem,
            changeItemPosition,
            saveAssessmentQuestionnaireItems,
            deleteAssessmentQuestionnaireItem,
            addItemScore,
            editItemScore,
            changeItemScorePosition,
            saveAssessmentQuestionnaireScores,
            deleteAssessmentQuestionnaireScore
        } = this.props
        return (
            <div>
                <QuestionnaireItemScores
                    scores={scores}
                    addScore={addItemScore}
                    editScore={editItemScore}
                    changeItemScorePosition={changeItemScorePosition}
                    onSubmitQuestionnaireItemScore={() => {
                        saveAssessmentQuestionnaireScores(questionnaireId)
                    }}
                    deleteQuestionnaireItemScore={itemIndex => {
                        deleteAssessmentQuestionnaireScore(questionnaireId, itemIndex)
                    }}
                />

                <QuestionnaireItems
                    items={items}
                    addItem={addItem}
                    editItem={editItem}
                    changeItemPosition={changeItemPosition}
                    onSubmitQuestionnaireData={() => {
                        saveAssessmentQuestionnaireItems(questionnaireId)
                    }}
                    deleteQuestionnaireItem={itemIndex => {
                        deleteAssessmentQuestionnaireItem(questionnaireId, itemIndex)
                    }}
                />
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssessmentQuestionnaireItemsScoresTab)
