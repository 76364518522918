import { fetchGet } from '../helperApi'

export const fetchCompaniesApi = () => {
    return fetchGet('companies')
}

export const fetchCompanyPackages = (companyId) => {
    return fetchGet(`company/${companyId}/packages`)
}

export const fetchStructureAssessmentPackagesApi = () => {
    return fetchGet(`packages/structure-assessment`)
}
