import * as Api from './api'
import { errorHandler } from '../helperApi'
import { push } from 'react-router-redux'
import { routesConst, setVariableToRoute } from '../../Routes'
import { getAssessmentPackagesQuestionnaires } from './reducers'

export const actionsTypes = {
    RESET_ASSESSMENT_PACKAGE: 'RESET_ASSESSMENT_PACKAGE',
    CREATE_ASSESSMENT_PACKAGE_REQUEST: 'CREATE_ASSESSMENT_PACKAGE_REQUEST',
    CREATE_ASSESSMENT_PACKAGE_SUCCESS: 'CREATE_ASSESSMENT_PACKAGE_SUCCESS',
    CREATE_ASSESSMENT_PACKAGE_FAILURE: 'CREATE_ASSESSMENT_PACKAGE_FAILURE',

    UPDATE_ASSESSMENT_PACKAGE_REQUEST: 'UPDATE_ASSESSMENT_PACKAGE_REQUEST',
    UPDATE_ASSESSMENT_PACKAGE_SUCCESS: 'UPDATE_ASSESSMENT_PACKAGE_SUCCESS',
    UPDATE_ASSESSMENT_PACKAGE_FAILURE: 'UPDATE_ASSESSMENT_PACKAGE_FAILURE',

    DELETE_ASSESSMENT_PACKAGE_REQUEST: 'DELETE_ASSESSMENT_PACKAGE_REQUEST',
    DELETE_ASSESSMENT_PACKAGE_SUCCESS: 'DELETE_ASSESSMENT_PACKAGE_SUCCESS',
    DELETE_ASSESSMENT_PACKAGE_FAILURE: 'DELETE_ASSESSMENT_PACKAGE_FAILURE',

    FETCH_PACKAGE_DATA_REQUEST: 'FETCH_PACKAGE_DATA_REQUEST',
    FETCH_PACKAGE_DATA_SUCCESS: 'FETCH_PACKAGE_DATA_SUCCESS',
    FETCH_PACKAGE_DATA_FAILURE: 'FETCH_PACKAGE_DATA_FAILURE',

    SAVE_PACKAGE_QUESTIONNAIRE_REQUEST: 'SAVE_PACKAGE_QUESTIONNAIRE_REQUEST',
    SAVE_PACKAGE_QUESTIONNAIRE_SUCCESS: 'SAVE_PACKAGE_QUESTIONNAIRE_SUCCESS',
    SAVE_PACKAGE_QUESTIONNAIRE_FAILURE: 'SAVE_PACKAGE_QUESTIONNAIRE_FAILURE',

    DELETE_PACKAGE_QUESTIONNAIRE_REQUEST: 'DELETE_PACKAGE_QUESTIONNAIRE_REQUEST',
    DELETE_PACKAGE_QUESTIONNAIRE_SUCCESS: 'DELETE_PACKAGE_QUESTIONNAIRE_SUCCESS',
    DELETE_PACKAGE_QUESTIONNAIRE_FAILURE: 'DELETE_PACKAGE_QUESTIONNAIRE_FAILURE',

    FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_REQUEST: 'FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_REQUEST',
    FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_SUCCESS: 'FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_SUCCESS',
    FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_FAILURE: 'FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_FAILURE',

    EDIT_PACKAGE_QUESTIONNAIRE_POSITION_REQUEST: 'EDIT_PACKAGE_QUESTIONNAIRE_POSITION_REQUEST',

    UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_REQUEST: 'UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_REQUEST',
    UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_SUCCESS: 'UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_SUCCESS',
    UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_FAILURE: 'UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_FAILURE',
}

export const resetAssessmentPackageReducer = () => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.RESET_ASSESSMENT_PACKAGE,
    })
}

export const createAssessmentPackage = (title, enabled, showColaboratorInputs,companies, assessmentPackageTypes) => (dispatch, getState) => {
    const body = {
        title,
        enabled,
        showColaboratorInputs,
        companies,
        assessmentPackageTypes,
    }
    dispatch({
        type: actionsTypes.CREATE_ASSESSMENT_PACKAGE_REQUEST,
        body,
    })

    return Api.createAssessmentPackage(body)
        .then((response) => {
            const newPackageCreated = response.data.packageCreated

            dispatch({
                type: actionsTypes.CREATE_ASSESSMENT_PACKAGE_SUCCESS,
                packageData: newPackageCreated.data,
                companies: newPackageCreated.companies,
                assessmentPackageTypes: newPackageCreated.assessmentPackageTypes,
            })

            const editPackageRoute = setVariableToRoute(routesConst.assessmentPackagesEdit, newPackageCreated.packageId)

            dispatch(push(editPackageRoute))
            // dispatch(fetchAssessmentPackageQuestionnaireAvailables(newPackageCreated.id))
        })
        .catch((err) => {
            const actionError = (errors) => {
                dispatch({ type: actionsTypes.CREATE_ASSESSMENT_PACKAGE_FAILURE, error: 'Revisa los campos', errors: errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const updateAssessmentPackage = (packageId, title, enabled, showColaboratorInputs, companies, assessmentPackageTypes) => (dispatch, getState) => {
    const body = {
        title,
        enabled,
        showColaboratorInputs,
        companies,
        assessmentPackageTypes,
    }
    dispatch({
        type: actionsTypes.UPDATE_ASSESSMENT_PACKAGE_REQUEST,
        packageId,
        body,
    })

    return Api.updateAssessmentPackage(packageId, body)
        .then((response) => {
            const assesmentPackage = response.data.package
            dispatch({
                type: actionsTypes.UPDATE_ASSESSMENT_PACKAGE_SUCCESS,
                packageData: assesmentPackage.data,
                companies: assesmentPackage.companies,
                assessmentPackageTypes: assesmentPackage.assessmentPackageTypes,
            })
        })
        .catch((err) => {
            const actionError = (errors) => {
                dispatch({ type: actionsTypes.UPDATE_ASSESSMENT_PACKAGE_FAILURE, error: 'Revisa los campos', errors: errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const deleteAssessmentPackage = (packageId) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.DELETE_ASSESSMENT_PACKAGE_REQUEST,
        packageId,
    })

    return Api.deleteAssessmentPackage(packageId)
        .then((response) => {
            dispatch({
                type: actionsTypes.DELETE_ASSESSMENT_PACKAGE_SUCCESS,
            })
            dispatch(push(routesConst.assessmentPackages))
        })
        .catch((err) => {
            const actionError = (errors) => {
                dispatch({ type: actionsTypes.DELETE_ASSESSMENT_PACKAGE_FAILURE, error: 'No se pudo eliminar el paquete', errors: errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchAssessmentPackage = (packageId) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.FETCH_PACKAGE_DATA_REQUEST,
        packageId,
    })

    return Api.fetchAssessmentPackageDataApi(packageId)
        .then((response) => {
            const assesmentPackage = response.data.package
            dispatch({
                type: actionsTypes.FETCH_PACKAGE_DATA_SUCCESS,
                packageData: assesmentPackage.data,
                questionnaires: assesmentPackage.questionnaires,
                companies: assesmentPackage.companies,
                assessmentPackageTypes: assesmentPackage.assessmentPackageTypes,
            })
            dispatch(fetchAssessmentPackageQuestionnaireAvailables(packageId))
        })
        .catch((err) => {
            const actionError = () => {
                dispatch({ type: actionsTypes.FETCH_PACKAGE_DATA_FAILURE, error: 'Error al obtener el paquete' })
                dispatch(push(routesConst.assessmentPackages))
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const saveAssessmentPackageQuestionnaire = (packageId, questionnaireId) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.SAVE_PACKAGE_QUESTIONNAIRE_REQUEST,
    })

    return Api.saveAssessmentPackageQuestionnaireApi(packageId, questionnaireId)
        .then((response) => {
            dispatch({
                type: actionsTypes.SAVE_PACKAGE_QUESTIONNAIRE_SUCCESS,
                packageQuestionnaires: response.data.package.questionnaires,
            })
            dispatch(fetchAssessmentPackageQuestionnaireAvailables(packageId))
        })
        .catch((err) => {
            const actionError = (errors) => {
                dispatch({ type: actionsTypes.SAVE_PACKAGE_QUESTIONNAIRE_FAILURE, error: 'Error al asignar el cuestionario', errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const deleteAssessmentPackageQuestionnaire = (packageId, questionnaireId) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.DELETE_PACKAGE_QUESTIONNAIRE_REQUEST,
        packageId,
        questionnaireId,
    })

    return Api.deleteAssessmentPackageQuestinnaireApi(packageId, questionnaireId)
        .then((response) => {
            const assessmentPackage = response.data.package
            dispatch({
                type: actionsTypes.DELETE_PACKAGE_QUESTIONNAIRE_SUCCESS,
                packageQuestionnaires: assessmentPackage ? assessmentPackage.questionnaires : [],
            })
            dispatch(fetchAssessmentPackageQuestionnaireAvailables(packageId))
        })
        .catch((err) => {
            const actionError = (errors) => {
                dispatch({ type: actionsTypes.DELETE_ASSESSMENT_PACKAGE_FAILURE, error: 'Error al eliminar el paquete', errors })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const fetchAssessmentPackageQuestionnaireAvailables = (packageId) => (dispatch, getState) => {
    dispatch({
        type: actionsTypes.FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_REQUEST,
        packageId,
    })

    return Api.getAvailablePackageQuestionnaires(packageId)
        .then((response) => {
            dispatch({
                type: actionsTypes.FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_SUCCESS,
                questionnairesAvailables: response.data.availablePackageQuestionnaires,
            })
        })
        .catch((err) => {
            const actionError = (errors) => {
                dispatch({ type: actionsTypes.FETCH_AVAILABLES_PACKAGE_QUESTIONNAIRES_FAILURE, error: 'Error al obtener los cuestionarios del paquete disponibles' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}

export const changePackageQuestionnairePosition = (oldPosition, newPosition) => (dispatch, getState) => {
    dispatch({ type: actionsTypes.EDIT_PACKAGE_QUESTIONNAIRE_POSITION_REQUEST, oldPosition, newPosition })
}

export const updatePackageQuestionnairesOrder = (packageId) => (dispatch, getState) => {
    const packageQuestionnaires = getAssessmentPackagesQuestionnaires(getState())
    const packageQuestionnairesToSend = packageQuestionnaires.map((packageQuestionnaire) => {
        return {
            questionnaireId: packageQuestionnaire.assessment_block_id,
            order: packageQuestionnaire.order,
        }
    })

    dispatch({
        type: actionsTypes.UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_REQUEST,
        packageId,
        packageQuestionnairesToSend,
    })

    return Api.updatePackageQuestionnairesOrder(packageId, packageQuestionnairesToSend)
        .then((response) => {
            dispatch({
                type: actionsTypes.UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_SUCCESS,
                packageQuestionnaires: response.data.package ? response.data.package.questionnaires : [],
            })
        })
        .catch((err) => {
            const actionError = (errors) => {
                dispatch({ type: actionsTypes.UPDATE_ASSESSMENT_PACKAGE_QUESTIONNAIRES_ORDER_FAILURE, error: 'Error al actualizar el order de los cuestionarios' })
            }

            errorHandler(dispatch, err.response, err, actionError)
        })
}
