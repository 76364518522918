import React from 'react'
import Button from '../../shared/Button'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import Modal from '../../shared/Modal'
import { ScoreForm } from './ScoreForm'

const ScoresTable = SortableContainer(props => {
    const { scores, onHandleEditScore, onHandleDeleteScore, onHandleNewItem } = props
    return (
        <ul className="sortable__container">
            <section className="admin__body">
                <div className="items__container" style={{ position: 'relative' }}>
                    <div className="admin__panel">
                        <div className="panel__body">
                            <ul className="form__body">
                                <div className="form__body--row-start sortable__header">
                                    <span className="body__column header__tab header__tab--first">Título puntuación</span>
                                    <span className="body__column header__tab">Descripción</span>
                                    <span className="body__column header__tab">Puntuación</span>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {scores.map((score, index) => {
                return (
                    <SortableItem
                        key={index}
                        index={index}
                        indexPosition={index}
                        scoreData={score}
                        sortIndex={score.order}
                        onHandleEditScore={onHandleEditScore}
                        onHandleDeleteScore={onHandleDeleteScore}
                    />
                )
            })}
            <div className="panel__add">
                <span
                    title="Añade un nueva puntuación"
                    alt="Añade un nueva puntuación"
                    onClick={e => {
                        e.preventDefault()
                        onHandleNewItem()
                    }}
                    className="fa_plus--add"
                >
                    <i className="fas fa-plus-circle" />
                </span>
                <div className="line-horizontal--center-vertical" />
            </div>
        </ul>
    )
})

const DragHandle = SortableHandle(() => (
    <span>
        <i style={{ fontSize: '1.3em', lineHeight: '2.5em' }} className="row__column_handler fas fa-arrows-alt" />
    </span>
))

const SortableItem = SortableElement(props => {
    const { scoreData, indexPosition, onHandleEditScore, onHandleDeleteScore } = props
    return (
        <section
            className="admin__body--clickable"
            onClick={e => {
                e.preventDefault()
                onHandleEditScore(indexPosition)
            }}
        >
            <div className="items__container" style={{ position: 'relative' }}>
                <div className="admin__panel">
                    <div className="panel__body">
                        <ul className="form__body">
                            <div className="form__body--row-start sortable__row">
                                <DragHandle />
                                <span className="body__column row__column">{scoreData.title}</span>
                                <span className="body__column row__column">{scoreData.description}</span>
                                <span className="body__column row__column">{scoreData.score}</span>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <span
                className="button__delete--top"
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onHandleDeleteScore(indexPosition)
                }}
            >
                <i className="fas fa-times-circle" />
            </span>
        </section>
    )
})

export default class QuestionnaireItemScores extends React.Component {
    state = {
        showItemModal: false,
        itemIndexToEdit: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    closeModal = () => {
        this.setState({
            showItemModal: false,
            itemIndexToEdit: null
        })
    }

    onSortEnd = pos => {
        this.props.changeItemScorePosition(pos.oldIndex, pos.newIndex)
    }

    render() {
        const { scores, addScore, editScore, onSubmitQuestionnaireItemScore, deleteQuestionnaireItemScore } = this.props

        return (
            <div>
                <h2 style={{ marginLeft: 40, marginTop: 25 }}>Puntuaciones posibles</h2>
                {this.state.showItemModal && (
                    <Modal
                        title={this.state.itemIndexToEdit === null ? 'Añadir nueva puntuación' : `Edición de la puntuación ${scores[this.state.itemIndexToEdit].title}`}
                        footer=""
                        onClose={this.closeModal}
                    >
                        <ScoreForm
                            onSubmit={(title, description, score) => {
                                if (this.state.itemIndexToEdit !== undefined && this.state.itemIndexToEdit !== null) {
                                    editScore(this.state.itemIndexToEdit, title, description, score)
                                } else {
                                    addScore(title, description, score)
                                }
                                this.closeModal()
                            }}
                            editItem={editScore}
                            addItem={addScore}
                            // isLoading={this.props.isLoading}
                            score={this.state.itemIndexToEdit !== null ? scores[this.state.itemIndexToEdit] : null}
                        />
                    </Modal>
                )}
                <div>
                    <ScoresTable
                        scores={scores}
                        useDragHandle
                        onHandleEditScore={indexToEdit => {
                            this.setState({
                                itemIndexToEdit: indexToEdit,
                                showItemModal: true
                            })
                        }}
                        onHandleDeleteScore={itemIndexToDelete => {
                            deleteQuestionnaireItemScore(itemIndexToDelete)
                        }}
                        onSortEnd={this.onSortEnd}
                        onHandleNewItem={() => {
                            this.setState({
                                showItemModal: true,
                                itemIndexToEdit: null
                            })
                        }}
                    />
                    <Button
                        type="mini"
                        onClick={e => {
                            e.preventDefault()
                            onSubmitQuestionnaireItemScore()
                        }}
                    >
                        Guardar puntuaciones
                    </Button>
                </div>
            </div>
        )
    }
}
