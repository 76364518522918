import React from 'react'
import Button from '../../shared/Button'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { PackageQuestionnnaireForm } from './PackageQuestionnnaireForm'
import { translateWords } from '../../Messages'

const PackageQuestionnairesTable = SortableContainer(props => {
    const { packageQuestionnaires, onHandleDeleteItem } = props
    if (!packageQuestionnaires || !Array.isArray(packageQuestionnaires)) return <ul></ul>
    return (
        <ul className="sortable__container">
            <section className="admin__body">
                <div className="items__container" style={{ position: 'relative' }}>
                    <div className="admin__panel">
                        <div className="panel__body">
                            <ul className="form__body">
                                <div className="form__body--row-start sortable__header">
                                    <span className="body__column header__tab header__tab--first">Nombre del cuestionario</span>

                                    <span className="body__column header__tab">Tipo del cuestionario</span>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {packageQuestionnaires.map((item, index) => {
                return <SortableItem key={index} index={index} indexPosition={index} itemData={item} sortIndex={item.order} onHandleDeleteItem={onHandleDeleteItem} />
            })}
        </ul>
    )
})

const DragHandle = SortableHandle(() => (
    <span>
        <i style={{ fontSize: '1.3em', lineHeight: '2.5em' }} className="row__column_handler fas fa-arrows-alt" />
    </span>
))

const SortableItem = SortableElement(props => {
    const { itemData, onHandleDeleteItem } = props
    return (
        <section
            className="admin__body--clickable"
            onClick={e => {
                e.preventDefault()
            }}
        >
            <div className="items__container" style={{ position: 'relative' }}>
                <div className="admin__panel">
                    <div className="panel__body">
                        <ul className="form__body">
                            <div className="form__body--row-start sortable__row">
                                <DragHandle />
                                <span className="body__column row__column">{itemData.title}</span>

                                <span className="body__column row__column">{translateWords(itemData.type)}</span>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <span
                className="button__delete--top"
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onHandleDeleteItem(itemData.assessment_block_id)
                }}
            >
                <i className="fas fa-times-circle" />
            </span>
        </section>
    )
})

export default class PackageQuestionnaires extends React.Component {
    state = {
        showItemModal: false,
        itemIndexToEdit: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    closeModal = () => {
        this.setState({
            showItemModal: false,
            itemIndexToEdit: null
        })
    }

    onSortEnd = pos => {
        this.props.onHandleUpdatePackageQuestionnairePosition(pos.oldIndex, pos.newIndex)
    }

    render() {
        const {
            packageQuestionnaires,
            packageQuestionnnaireOptions,
            fetchQuestionnaireAvailables,
            onSubmitAddPackageQuestionnaire,
            onHandleUpdateQuestionnairesPosition,
            onDeletePackageQuestionnaire
        } = this.props

        return (
            <div style={{ marginTop: 35 }}>
                <PackageQuestionnnaireForm
                    fetchQuestionnaireAvailables={fetchQuestionnaireAvailables}
                    questionnaireOptions={packageQuestionnnaireOptions}
                    onSubmit={newQuestionnaireId => {
                        onSubmitAddPackageQuestionnaire(newQuestionnaireId)
                    }}
                />

                <div>
                    <PackageQuestionnairesTable
                        packageQuestionnaires={packageQuestionnaires}
                        useDragHandle
                        onHandleDeleteItem={questionnaireId => {
                            onDeletePackageQuestionnaire(questionnaireId)
                        }}
                        onSortEnd={this.onSortEnd}
                    />

                    <Button
                        type="mini"
                        onClick={e => {
                            e.preventDefault()
                            onHandleUpdateQuestionnairesPosition()
                        }}
                    >
                        Guardar posiciones
                    </Button>
                </div>
            </div>
        )
    }
}
