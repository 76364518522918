import React from 'react'
import * as Sentry from '@sentry/browser'
import { withRouter } from 'react-router'
import { getUserName, getUserId } from './store/auth/reducers'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
    currentUserName: getUserName(state),
    currentUserId: getUserId(state)
})

const mapDispatchToProps = dispatch => ({})

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null, eventId: null }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (this.hasError()) {
                this.setState({ error: null, errorInfo: null, eventId: null })
            }
        })
    }
    componentWillUnmount() {
        this.unlisten()
    }

    componentDidCatch(error, errorInfo) {
        this.setState(
            {
                error: error,
                errorInfo: errorInfo
            },
            () => {
                this.notifySentry(error, errorInfo)
            }
        )
    }

    notifySentry = (error, errorInfo) => {
        if (process.env.NODE_ENV === 'production') {
            Sentry.withScope(scope => {
                scope.setExtras(errorInfo)
                scope.setUser({ username: this.props.currentUserName, id: this.props.currentUserId })
                const eventId = Sentry.captureException(error)

                this.setState({
                    eventId
                })
            })
        }
    }

    hasError = () => {
        return this.state.error !== null
    }

    render() {
        if (this.hasError()) {
            return (
                <div>
                    <div className="container" style={{ minHeight: 'calc(100vh - 323px)' }}>
                        <div className="row">
                            <div className="col">
                                <h2>Ocurrió un problema, puedes ayudarnos pulsando en el botón de "Enviar feedback"</h2>
                                {this.state.eventId && <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Enviar feedback</button>}

                                {process.env.NODE_ENV !== 'production' && (
                                    <details style={{ whiteSpace: 'pre-wrap' }}>
                                        {this.state.error && this.state.error.toString()}
                                        <br />
                                        {this.state.errorInfo.componentStack}
                                    </details>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary))
