import React, { useState, useEffect } from 'react'
import Button from '../../shared/Button'

import TableServerSide from '../../shared/Table/TableServerSide'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import { connect } from 'react-redux'
import FormWithoutSection from '../../shared/FormWithoutSection'
import { getWorkers, getWorkersPages, isLoadingWorkers } from '../../store/watchmen/reducers'
import { fetchWorkers, resetWorkers } from '../../store/watchmen/actions'

const mapStateToProps = (state) => ({
    workers: getWorkers(state),
    isLoading: isLoadingWorkers(state),
    pages: getWorkersPages(state),
})

const mapDispatchToProps = (dispatch) => ({
    fetchWorkersFilter: (paramsReactTable = null) => {
        dispatch(fetchWorkers(paramsReactTable))
    },
    resetWorkersData: () => {
        dispatch(resetWorkers())
    },
})

const WatchmenFilter = ({ setFilters }) => {
    const [filterParams, setFilterParams] = useState({ n_identification: null, name: null })
    const updateFilterParam = (filterParamKey, filterParamValue) => {
        setFilterParams({ ...filterParams, [filterParamKey]: filterParamValue })
    }

    const hasSomeFilterParam = () => {
        const filterParamsValue = Object.values(filterParams)
        return filterParamsValue.find((filterParamValue) => filterParamValue !== undefined && filterParamValue !== null && filterParamValue !== '')
    }

    const onSubmitFilters = () => {
        const filters = [
            { accessor: 'n_identification', value: filterParams.n_identification },
            { accessor: 'name', value: filterParams.name },
        ]
        setFilters(filters)
    }

    return (
        <FormWithoutSection
            onSubmit={(e) => {
                e.preventDefault()
                onSubmitFilters()
            }}
            autoComplete="off"
            title="Filtrar colaborador"
        >
            <ul className="form__body">
                <li className="form__line">
                    <InputTextHTML5
                        name="name"
                        id="name"
                        label="Nombre"
                        type="text"
                        onChange={(value) => {
                            updateFilterParam('name', value)
                        }}
                    />

                    <InputTextHTML5
                        name="n_identification"
                        id="n_identification"
                        label="DNI/NIE"
                        type="text"
                        onChange={(value) => {
                            updateFilterParam('n_identification', value)
                        }}
                    />

                    <Button type="submit" disabled={!hasSomeFilterParam()}>
                        Buscar empleado
                    </Button>
                </li>
            </ul>
        </FormWithoutSection>
    )
}

const WorkerSelector = ({ handleSubmit, inspectionSelectors, workers, fetchWorkersFilter, resetWorkersData, isLoading, pages }) => {
    const myTableComponent = React.createRef()
    const myTable = React.createRef()

    const setAllFilter = (filters) => {
        if (myTableComponent && myTableComponent.current) {
            myTableComponent.current.setAllFilterValues(filters)
        }
    }

    useEffect(() => {
        resetWorkersData()
        // eslint-disable-next-line
    }, [])

    return (
        <section className="admin__body">
            <div className="admin__panel">
                <h1 className="panel__title">Seleccione al empleado</h1>
                <div className="panel__body">
                    <TableServerSide
                        ref={myTableComponent}
                        refReactTable={myTable}
                        data={workers}
                        manual={true}
                        clearFilters={false}
                        FiltersComponent={() => {
                            return <WatchmenFilter setFilters={setAllFilter} />
                        }}
                        pages={pages}
                        noFetchWhenEmptyFilters={true}
                        onFetchData={(reactableState) => {
                            fetchWorkersFilter(reactableState)
                        }}
                        onResetChange={() => {
                            // this.setState({ reset: true })
                        }}
                        filterable
                        columns={[
                            {
                                Header: 'Empleado',
                                filterable: false,
                                accessor: 'name',
                                id: 'name',
                            },
                            {
                                Header: 'DNI/NIE',
                                filterable: false,
                                accessor: 'nIdentification',
                                id: 'nIdentification',
                            },
                            {
                                Header: 'Empresa',
                                filterable: false,
                                accessor: 'company',
                                id: 'company',
                            },
                        ]}
                        loading={isLoading}
                        getTrProps={(state, rowInfo) => {
                            let cursor = rowInfo ? 'pointer' : 'default'
                            return {
                                onClick: () => {
                                    if (!rowInfo || !rowInfo.original) return
                                    handleSubmit({
                                        workerId: rowInfo.original.workerId,
                                        workerName: rowInfo.original.name,
                                    })
                                },
                                style: { cursor: cursor },
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerSelector)
