import React from 'react'
import { Switch, Route, Router, Redirect } from 'react-router'
import Header from './components/Header'
import HomeScreen from './screens/HomeScreen'
import SidebarMenu from './components/SidebarMenu'
import { isMobile } from './shared/Helper'
import CreateInspectionContainer from './screens/CreateInspection/CreateInspectionContainer'
import InspectionsHistory from './screens/InspectionsHistory'
import AlertContainer from './components/AlertContainer'
import { isAuthenticated, isLoadingAuth, getUserName, canDoInspection, canDoInspectionStructureAssessment, isFetchedGrants } from './store/auth/reducers'
import { executeLogin, logout } from './store/auth/actions'
import { connect } from 'react-redux'
import Spinner from './shared/Spinner'
import NoGrantsScreen from './screens/NoGrantsScreen'
import AssessmentQuestionnaireManagement from './screens/AssessmentQuestionnaireManagement'
import ErrorScreeen from './screens/ErrorScreen'
import ErrorBoundary from './ErrorBoundary'
import UpdateInspectionContainer from './screens/CreateInspection/UpdateInspectionContainer'
import AssessmentQuestionnaires from './screens/AssessmentQuestionnaires'
import AssessmentPackages from './screens/AssessmentPackages'
import AssessmentPackagesManagement from './screens/AssessmentPackagesManagement'
import CreateStructureAssessmentContainer from './screens/CreateStructureAssessment'
import UpdateStructureAssessmentContainer from './screens/CreateStructureAssessment/UpdateStructureAssessmentContainer'
import { resetInspection, resetStructureAssessment } from './store/inspection/actions'

const mapStateToProps = (state) => ({
    isAuthenticated: isAuthenticated(state),
    isFetchedGrants: isFetchedGrants(state),
    userCanDoInspection: canDoInspection(state),
    userCanDoInspectionStructureAssessment: canDoInspectionStructureAssessment(state),
    isLoadingLogin: isLoadingAuth(state),
    userName: getUserName(state),
})

const mapDispatchToProps = (dispatch) => ({
    login: () => {
        dispatch(executeLogin())
    },
    logout: () => {
        dispatch(logout())
    },
    resetInspection: () => {
        dispatch(resetInspection())
    },
    resetStructureAssessment: () => {
        dispatch(resetStructureAssessment())
    }
})

export const routesConst = {
    home: '/',
    createInspection: '/inspection/new',
    updateInspection: '/inspection/:inspectionId',
    createStructureAssessment: '/assessment/structure/new',
    updateStructureAssessment: '/assessment/structure/:assessmentStructureId',
    historyInspections: '/inspections/history',

    assessmentQuestionaires: '/questionnaires',
    assessmentQuestionaireManagementCreate: '/questionnaires/new',
    assessmentQuestionaireManagementEdit: '/questionnaires/:questionnaireId',

    assessmentPackages: '/packages',
    assessmentPackagesCreate: '/packages/new',
    assessmentPackagesEdit: '/packages/:packageId',
    noAuthorized: '/noAuthorized',
}

export const setVariableToRoute = (route, param) => {
    if (!route || !route.includes(':')) return route
    let routeSplitted = route.split(':')
    return routeSplitted[0] + param
}

const ProtectedRoute = ({ component: Component, isAuthenticated, login, ...rest }) => {
    if (!isAuthenticated) {
        login()
        return <Route {...rest} render={NoGrantsScreen} />
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />
}

const ProtectedRouteCanDo = ({ isFetchedGrants, isAuthenticated, canDo, login, ...rest }) => {
    if (!isAuthenticated) {
        login()
        return null
    }
    if (!isFetchedGrants) {
        return null
    }
    if (!isAuthenticated || !canDo) {
        console.log('no tiene permisos')
        return <Redirect to={routesConst.noAuthorized} />
    }
    return <ProtectedRoute login={login} isAuthenticated={isAuthenticated} {...rest} />
}

class Routes extends React.Component {
    state = { isOpenMenu: false }

    constructor(props) {
        super(props)
        window.document.title = 'Módulo de evaluaciones'
    }

    handleOpenMenu = () => {
        this.setState((prevState) => {
            return {
                isOpenMenu: !prevState.isOpenMenu,
            }
        })
    }

    handleOnClickMenuItem = () => {
        if (isMobile()) {
            this.handleOpenMenu()
        }
        this.props.resetInspection()
        this.props.resetStructureAssessment()
    }

    render() {
        const { login, logout, isAuthenticated, isFetchedGrants, isLoadingLogin, userName, userCanDoInspection, userCanDoInspectionStructureAssessment } = this.props

        return (
            <Router history={this.props.history}>
                <React.Fragment>
                    <Header isOpenMenu={this.state.isOpenMenu} handleOpenMenu={this.handleOpenMenu} isAuthenticated={isAuthenticated} userName={userName} logout={logout} />
                    {isLoadingLogin && <Spinner />}
                    <div className="layout">
                        <SidebarMenu isOpenMenu={this.state.isOpenMenu} handleOpenMenu={this.handleOnClickMenuItem} />

                        <main className="main">
                            <ErrorBoundary>
                                <Switch>
                                    <ProtectedRoute exact path={routesConst.home} component={HomeScreen} login={login} isAuthenticated={isAuthenticated} />

                                    <ProtectedRouteCanDo
                                        exact
                                        path={routesConst.createInspection}
                                        component={CreateInspectionContainer}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                        canDo={userCanDoInspection}
                                        isFetchedGrants={isFetchedGrants}
                                    />
                                    <ProtectedRoute exact path={routesConst.historyInspections} component={InspectionsHistory} login={login} isAuthenticated={isAuthenticated} />

                                    <ProtectedRouteCanDo
                                        exact
                                        path={routesConst.updateInspection}
                                        component={UpdateInspectionContainer}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                        canDo={userCanDoInspection}
                                        isFetchedGrants={isFetchedGrants}
                                    />

                                    <ProtectedRouteCanDo
                                        exact
                                        path={routesConst.createStructureAssessment}
                                        component={CreateStructureAssessmentContainer}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                        canDo={userCanDoInspectionStructureAssessment}
                                        isFetchedGrants={isFetchedGrants}
                                    />

                                    <ProtectedRouteCanDo
                                        exact
                                        path={routesConst.updateStructureAssessment}
                                        component={UpdateStructureAssessmentContainer}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                        canDo={userCanDoInspectionStructureAssessment}
                                        isFetchedGrants={isFetchedGrants}
                                    />

                                    <ProtectedRoute
                                        exact
                                        path={routesConst.assessmentQuestionaireManagementCreate}
                                        component={AssessmentQuestionnaireManagement}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                    />

                                    <ProtectedRoute
                                        exact
                                        path={routesConst.assessmentQuestionaireManagementEdit}
                                        component={AssessmentQuestionnaireManagement}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path={routesConst.assessmentQuestionaires}
                                        component={AssessmentQuestionnaires}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                    />

                                    <ProtectedRoute exact path={routesConst.assessmentPackages} component={AssessmentPackages} login={login} isAuthenticated={isAuthenticated} />
                                    <ProtectedRoute
                                        exact
                                        path={routesConst.assessmentPackagesCreate}
                                        component={AssessmentPackagesManagement}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path={routesConst.assessmentPackagesEdit}
                                        component={AssessmentPackagesManagement}
                                        login={login}
                                        isAuthenticated={isAuthenticated}
                                    />

                                    <ProtectedRoute exact path={routesConst.noAuthorized} component={NoGrantsScreen} login={login} isAuthenticated={isAuthenticated} />

                                    <Route component={ErrorScreeen} />
                                </Switch>
                            </ErrorBoundary>
                        </main>
                    </div>
                    <AlertContainer />
                </React.Fragment>
            </Router>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
