import React from 'react'

class SalesHeader extends React.Component {
    isActive = (path, match, location) => !!(match || path === location.pathname)

    render() {
        const { isOpenMenu, handleOpenMenu, isAuthenticated, userName, logout } = this.props
        return (
            <header className={isOpenMenu ? 'header__light' : 'header'}>
                <nav role="navigation" className="header__nav__mobile">
                    <div className={isOpenMenu ? 'wrapper-menu open' : 'wrapper-menu'} onClick={handleOpenMenu}>
                        <div className="line-menu half start" />
                        <div className="line-menu" />
                        <div className="line-menu half end" />
                    </div>
                </nav>
                {isAuthenticated && (
                    <div className="header__auth_actions">
                        <span>{`Hola, ${userName}`}</span>
                        <span
                            className="logount_button"
                            onClick={e => {
                                e.preventDefault()
                                logout()
                            }}
                        >
                            <i className="fas fa-sign-out-alt"></i>
                        </span>
                    </div>
                )}
            </header>
        )
    }
}

export default SalesHeader
