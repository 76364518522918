import React, { useEffect } from 'react'
import Select from 'react-select'
import debounce from 'lodash/debounce'
const debounced = debounce(fn => fn(), 550)

export const FormHtml5SelectLiveSearch = ({
    label,
    noMargin = false,
    disabled = false,
    id,
    name,
    value,
    errors,
    onChangeCallback,
    children,
    multi = false,
    simpleValue = false,
    required,
    options,
    labelKeyName = 'label',
    valueKeyName = 'value',
    fetchOptionsOnStart,
    onFilter = () => {},
    isLoading = false,
    ...others
}) => {
    useEffect(() => {
        if (fetchOptionsOnStart) {
            loadOptions()
        }
        // eslint-disable-next-line
    }, [])

    const loadOptions = inputValue => {
        onFilter(inputValue)
    }

    let selectValue = null
    if (value) {
        let valueSearched = options.find(option => option[valueKeyName] === value)
        selectValue = valueSearched
    }

    return (
        <div className={noMargin === true ? 'form__group--noMargin' : 'form__group'}>
            <label className="label__input">
                {label && (
                    <React.Fragment>
                        {label} {required ? '*' : ''}
                    </React.Fragment>
                )}
                {errors && errors[name] ? <span className="form__error"> {errors[name]}</span> : null}
            </label>

            <Select
                {...others}
                filterOption={option => {
                    return true
                }}
                searchable={true}
                value={selectValue}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                isClearable={true}
                // autoload={false}
                isLoading={isLoading}
                options={options}
                searchPromptText={'Introduzca para buscar'}
                placeholder={'Introduzca para buscar'}
                noResultsText={'No hay resultados'}
                noOptionsMessage={obj => {
                    if ((!obj.inputValue || obj.inputValue === '' || obj.inputValue.length < 2) && !fetchOptionsOnStart) {
                        return 'Introduzca para buscar, más de 2 letras'
                    }
                    return 'No hay resultados'
                }}
                loadingMessage={obj => {
                    if ((!obj.inputValue || obj.inputValue === '' || obj.inputValue.length < 2) && !fetchOptionsOnStart) {
                        return 'Introduzca para buscar, más de 2 letras'
                    }
                    return 'Buscando...'
                }}
                onChange={event => {
                    if (onChangeCallback) onChangeCallback(event)
                }}
                onInputChange={inputValue => {
                    if ((!inputValue || inputValue === '') && !fetchOptionsOnStart) {
                        return
                    }

                    debounced(() => {
                        if (!fetchOptionsOnStart && inputValue.length < 2) return
                        return loadOptions(inputValue)
                    })
                }}
            />
        </div>
    )
}
export default FormHtml5SelectLiveSearch
