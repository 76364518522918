import React from 'react'
import { connect } from 'react-redux'
import AssessmentPackage from './AssessmentPackage'
import { getAssessmentPackage, isLoadingAssessmentData } from '../../store/assessmentData/reducers'
import { fetchAssessmentDataByPackageId } from '../../store/assessmentData/actions'
import Spinner from '../../shared/Spinner'
import { createInspection, finishInspection, updateInspectionSelectors, saveInspection, submitInspection } from '../../store/inspection/actions'
import InspectionObservations from '../inspection/inspectionObservations/InspectionObservations'
import { getInspectionData, getInspectionResult, blockResultTypes, isLoadingInspection, canFinishInspection, canSendInspectionReport, isStructureAssessment, hideSaveButton, hideSubmitButton, disableSaveButton, disableSubmitButton, getInspectionStatus } from '../../store/inspection/reducers'
import InspectionAttachments from '../inspectionAttachments/InspectionAttachments'
import InspectionSignatures from '../inspectionSignatures/InspectionSignatures'
import Button from '../../shared/Button'
import { getIsLoadingInspectionAttachments } from '../../store/inspectionAttachments/reducers'
import { getShowEvaluationCannotBeFinishedWarning, isOperationalAssessment } from '../../store/inspection/reducers'
import InspectionSendReport from '../inspection/sendReport/InspectionSendReport'

const mapStateToProps = (state) => ({
    assessmentPackage: getAssessmentPackage(state),
    inspectionData: getInspectionData(state),
    isLoading: isLoadingAssessmentData(state) || isLoadingInspection(state) || getIsLoadingInspectionAttachments(state),
    inspectionResult: getInspectionResult(state),
    canFinishInspection: canFinishInspection(state),
    canSendInspectionReport: canSendInspectionReport(state),
    isStructureAssessment: isStructureAssessment(state),
    disableSaveButton: disableSaveButton(state),
    disableSubmitButton: disableSubmitButton(state),
    hideSaveButton: hideSaveButton(state),
    hideSubmitButton: hideSubmitButton(state),
    inspectionStatus: getInspectionStatus(state),
    showEvaluationCannotBeFinishedWarning: getShowEvaluationCannotBeFinishedWarning(state),
    isOperationalAssessment: isOperationalAssessment(state),
})

const mapDispatchToProps = (dispatch) => ({
    fetchAssessmentData: (assessmentPackageId) => {
        dispatch(fetchAssessmentDataByPackageId(assessmentPackageId))
    },
    createNewInpsection: (companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId) => {
        dispatch(createInspection(companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId))
    },
    updateInspectionSelectorsRequest: (inspectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId) => {
        dispatch(updateInspectionSelectors(inspectionId, companyId, customerId, contractId, delegationId, serviceId, watchmanId, packageId))
    },
    finishInspection: (inspectionId) => {
        return dispatch(finishInspection(inspectionId))
    },
    saveInspection: (inspectionId) => {
        return dispatch(saveInspection(inspectionId))
    },
    submitInspection: (inspectionId) => {
        return dispatch(submitInspection(inspectionId))
    }
})

class AssessmentModule extends React.Component {
    state = {
        includeAttachmentsInReport: true,
    }
    refSendReport = null

    constructor(props) {
        super(props)
        this.refSendReport = React.createRef()
    }

    componentDidMount() { }

    onHandleFinishInspection = () => {
        this.props.finishInspection(this.props.inspectionData.id)
    }

    onHandleSaveInspection = () => {
        this.props.saveInspection(this.props.inspectionData.id)
    }

    onHandleSubmitInspection = () => {
        this.props.submitInspection(this.props.inspectionData.id)
    }

    render() {
        const {
            assessmentPackage,
            inspectionData,
            inspectionResult,
            canFinishInspection,
            isLoading,
            canSendInspectionReport,
            disableSaveButton,
            disableSubmitButton,
            hideSubmitButton,
            hideSaveButton,
            showEvaluationCannotBeFinishedWarning,
            isOperationalAssessment,
        } = this.props
        const inspectionResultValid = [blockResultTypes.SUCCESSFUL, blockResultTypes.UNSUCCESSFUL]
        let inspectionFinished = this.props.inspectionStatus === 'FINISHED'
        let inspectionSubmitted = this.props.inspectionStatus === 'AWAITING_SIGNATURE'
        return (
            <div>
                {isLoading && <Spinner />}
                {canSendInspectionReport && (
                    <div ref={this.refSendReport}>
                        <InspectionSendReport inspectionId={inspectionData.id} />
                    </div>
                )}
                {assessmentPackage && <AssessmentPackage assessmentPackage={assessmentPackage} />}
                {inspectionData && inspectionData.id && inspectionResultValid.includes(inspectionResult) && (
                    <React.Fragment>
                        <InspectionObservations inpsectionResult={inspectionResult} />
                        <InspectionAttachments
                            inspectionId={inspectionData.id}
                            includeAttachmentsInReport={this.state.includeAttachmentsInReport}
                            setValueIncludeAttachmentsInReport={(value) => {
                                this.setState({
                                    includeAttachmentsInReport: value,
                                })
                            }}
                        />
                        <InspectionSignatures inspectionId={inspectionData.id} />
                        {(showEvaluationCannotBeFinishedWarning && isOperationalAssessment) && (
                            <div className="alert__div warning">Debe completar los campos de observaciones marcados con * antes de finalizar la evaluación.</div>
                        )}
                        <Button
                            type="submit"
                            disabled={!canFinishInspection}
                            onClick={() => {
                                this.onHandleFinishInspection()
                            }}
                        >
                            {inspectionFinished ? 'Evaluación Finalizada' : 'Finalizar evaluación'}
                        </Button>
                    </React.Fragment>
                )}
                {!inspectionFinished && !hideSaveButton && (
                    <Button
                        type="submit"
                        disabled={disableSaveButton}
                        onClick={() => {
                            this.onHandleSaveInspection()
                        }}
                    >
                        Guardar evaluación
                    </Button>
                )}
                {!hideSubmitButton && (
                    <Button
                        type="submit"
                        disabled={disableSubmitButton}
                        onClick={() => {
                            this.onHandleSubmitInspection()
                        }}
                    >
                        {inspectionSubmitted ? 'Enviada para firmar' : 'Enviar parma firmar'}
                    </Button>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentModule)
