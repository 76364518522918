import React from 'react'

const NoGrantsScreen = () => {
    return (
        <div>
            <section className="admin__head">
                <div className="admin__profile">
                    <div className="profile__data">
                        <p className="data__title">No tienes acceso a esta herramienta, por favor contacte con soporte técnico</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default NoGrantsScreen
