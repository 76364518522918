import { actionsTypes as actionsTypesCustomerServices } from './actions'
import { actionsTypes as actionsTypesCustomers } from '../customers/actions'

const initState = {
    loading: false,
    filter: null,
    customerServices: [],
    failureMessage: null,
    pages: -1
}

const customerServices = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesCustomers.RESET_CUSTOMERS:
            return { ...initState }
        case actionsTypesCustomerServices.FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_REQUEST:
            return { ...state, loading: true }
        case actionsTypesCustomerServices.FETCH_CUSTOMER_SERVICES_REQUEST:
            return { ...state, loading: true, customerServices: action.page > 1 ? state.customerServices : [] }
        case actionsTypesCustomerServices.FETCH_CUSTOMER_SERVICES_SUCCESS:
            return { ...state, loading: false, customerServices: action.customerServices, pages: action.pages }
        case actionsTypesCustomerServices.FETCH_CUSTOMER_SERVICES_FAILURE:
            return { ...state, loading: false, failureMessage: action.failureMessage }
        case actionsTypesCustomerServices.FETCH_CUSTOMER_SERVICES_LIVE_SEARCH_SUCCESS:
            return { ...state, loading: false, customerServices: action.services }
        default:
            return state
    }
}

export const isLoadingCustomerServices = state => {
    return state.customerServices.loading
}

export const getCustomerServices = state => {
    return state.customerServices.customerServices
}

export const getCustomerServicesPages = state => {
    return state.customerServices.pages
}

export default customerServices
