import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    isLoadingHistoryInspectionFilters,
    getHistoryInspectionsFilterInspectors,
    getHistoryInspectionsFilterCompanies,
    getHistoryInspectionsFilterDelegations,
    getHistoryInspectionsFilterPackages,
} from '../../store/historyInspectionFilters/reducers'

import Button from '../../shared/Button'
import FormWithoutSection from '../../shared/FormWithoutSection'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es' // the locale you want
import { fetchHistoryInspectionFilters } from '../../store/historyInspectionFilters/actions'

import FormHtml5Select from '../../shared/Form/html5/FormHtml5Select'
import { parseOptionsListIdNameToReactSelect } from '../../shared/Helper'
import { FormHtml5SelectLiveSearch } from '../../shared/Form/html5/FormHtml5SelectLiveSearch'
import { fetchWatchmenLiveSearch } from '../../store/watchmen/actions'
import { Checkbox } from '../../shared/Form/InputCheckboxHtml5'
import { formatDateDb } from '../../shared/HelperDate'
import {
    clearCustomerLiveSearchResults,
    fetchCustomerLiveSearch,
} from '../../store/customers/actions'
import { fetchCustomerContractsLiveSearch } from '../../store/customerContracts/actions'
import { fetchContractsServicesLiveSearch } from '../../store/customerServices/actions'
import {
    getCustomers,
    isLoadingCustomers,
} from '../../store/customers/reducers'
import {
    getCustomerContracts,
    isLoadingCustomerContracts,
} from '../../store/customerContracts/reducers'
import {
    getCustomerServices,
    isLoadingCustomerServices,
} from '../../store/customerServices/reducers'
import { getWatchmen, isLoadingWatchmen } from '../../store/watchmen/reducers'
import CsvExport from '../CsvExport'
import {
    inspectionAssessmentTypes,
    inspectionStatusTypes,
} from '../../store/inspection/reducers'
import { translateWords } from '../../Messages'

registerLocale('es', es) // register it with the name you want

const mapStateToProps = (state) => ({
    isLoadingHistoryInspectionFilters: isLoadingHistoryInspectionFilters(state),
    isLoadingCustomersFilters: isLoadingCustomers(state),
    isLoadingCustomerContractsFilters: isLoadingCustomerContracts(state),
    isLoadingCustomerContractServicesFilters: isLoadingCustomerServices(state),
    isLoadingWatchmenFilters: isLoadingWatchmen(state),
    inspectors: getHistoryInspectionsFilterInspectors(state),
    companies: getHistoryInspectionsFilterCompanies(state),
    packages: getHistoryInspectionsFilterPackages(state),
    customerOptions: getCustomers(state),
    customerContractsOptions: getCustomerContracts(state),
    customerContractServicesOptions: getCustomerServices(state),
    watchmenOptions: getWatchmen(state),
    delegationOptions: getHistoryInspectionsFilterDelegations(state),
})

const mapDispatchToProps = (dispatch) => ({
    fetchHistoryInspectionFilters: (
        companyId,
        customerId,
        contractId,
        serviceId,
        inspectedId,
        inspectionDateFrom,
        inspectionDateUntil,
        assessmentType,
        assessmentPackage
    ) => {
        return dispatch(
            fetchHistoryInspectionFilters(
                companyId,
                customerId,
                contractId,
                serviceId,
                inspectedId,
                inspectionDateFrom,
                inspectionDateUntil,
                assessmentType,
                assessmentPackage
            )
        )
    },
    fetchWatchmenLiveSearchFilter: (watchmenFilter) => {
        return dispatch(fetchWatchmenLiveSearch(watchmenFilter))
    },
    fetchCustomersLiveSearchFilter: (companyId, customerName) => {
        return dispatch(fetchCustomerLiveSearch(companyId, customerName))
    },
    fetchCustomerContractsLiveSearchFilter: (
        companyId,
        customerId,
        contractName
    ) => {
        return dispatch(
            fetchCustomerContractsLiveSearch(
                companyId,
                customerId,
                contractName
            )
        )
    },
    fetchContractsServicesLiveSearchFilter: (contractId, filterValue) => {
        return dispatch(
            fetchContractsServicesLiveSearch(contractId, filterValue)
        )
    },
    clearCustomerLiveSearchResults: () => {
        return dispatch(clearCustomerLiveSearchResults())
    },
})

const DateInputButton = ({ onClick, value, ref }) => {
    return (
        <input
            ref={ref}
            value={value}
            className={'form__input'}
            onClick={(e) => {
                e.preventDefault()
                onClick(e)
            }}
            onKeyDown={(e) => {
                e.preventDefault()
            }}
            placeholder='Introduzca la fecha'
        />
    )
}

const defaultState = {
    companyId: null,
    delegationId: null,
    customerId: null,
    contractId: null,
    serviceId: null,
    inspectedId: null,
    inspectorId: null,
    inspectionDateFrom: new Date(),
    inspectionDateUntil: new Date(),
    inspectionResultSuccessful: true,
    inspectionResultUnsuccessful: true,
    inspectionResultPending: true,
    assessmentType: null,
    assessmentPackage: null,
}

const InspectionsHistoryFilter = ({
    setFilters,
    fetchHistoryInspectionFilters,
    fetchWatchmenLiveSearchFilter,
    fetchCustomersLiveSearchFilter,
    fetchCustomerContractsLiveSearchFilter,
    fetchContractsServicesLiveSearchFilter,
    companies,
    delegationOptions,
    inspectors,
    customerOptions,
    customerContractsOptions,
    customerContractServicesOptions,
    watchmenOptions,
    isLoadingCustomersFilters,
    isLoadingCustomerContractsFilters,
    isLoadingCustomerContractServicesFilters,
    isLoadingWatchmenFilters,
    packages,
    clearCustomerLiveSearchResults,
}) => {
    const [filterParams, setFilterParams] = useState({ ...defaultState })

    useEffect(() => {
        fireFetchFilters()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (JSON.stringify(filterParams) !== JSON.stringify(defaultState)) {
            fireFetchFilters()
        }
        // eslint-disable-next-line
    }, [filterParams])

    const fireFetchFilters = () => {
        const {
            companyId,
            customerId,
            contractId,
            serviceId,
            inspectedId,
            inspectionDateFrom,
            inspectionDateUntil,
            assessmentType,
            assessmentPackage,
        } = filterParams
        fetchHistoryInspectionFilters(
            companyId,
            customerId,
            contractId,
            serviceId,
            inspectedId,
            formatDateDb(inspectionDateFrom),
            formatDateDb(inspectionDateUntil),
            assessmentType,
            assessmentPackage
        )
    }

    const updateFilterParam = (filterParamKey, filterParamValue) => {
        if (filterParams && filterParams[filterParamKey] === filterParamValue) {
            return
        }
        setFilterParams({ ...filterParams, [filterParamKey]: filterParamValue })
    }

    const hasSomeFilterParam = () => {
        const filterParamsValue = Object.values(filterParams)
        return filterParamsValue.find(
            (filterParamValue) =>
                filterParamValue !== undefined &&
                filterParamValue !== null &&
                filterParamValue !== ''
        )
    }

    const resetCompanyCustomerContractAndService = () => {
        setFilterParams({
            ...filterParams,
            companyId: null,
            customerId: null,
            contractId: null,
            serviceId: null,
        })
    }

    const resetCustomerContractAndService = () => {
        setFilterParams({
            ...filterParams,
            customerId: null,
            contractId: null,
            serviceId: null,
        })
    }

    const resetContractAndService = () => {
        setFilterParams({
            ...filterParams,
            contractId: null,
            serviceId: null,
        })
    }

    const onSubmitFilters = () => {
        const filters = [
            { accessor: 'companyId', value: filterParams.companyId },
            { accessor: 'delegationId', value: filterParams.delegationId },
            { accessor: 'customerId', value: filterParams.customerId },

            { accessor: 'contractId', value: filterParams.contractId },
            { accessor: 'serviceId', value: filterParams.serviceId },
            { accessor: 'inspectedId', value: filterParams.inspectedId },

            { accessor: 'inspectorId', value: filterParams.inspectorId },
            {
                accessor: 'inspectionDateFrom',
                value: filterParams.inspectionDateFrom
                    ? formatDateDb(filterParams.inspectionDateFrom)
                    : null,
            },
            {
                accessor: 'inspectionDateUntil',
                value: filterParams.inspectionDateUntil
                    ? formatDateDb(filterParams.inspectionDateUntil)
                    : null,
            },
            {
                accessor: 'inspectionResultSuccessful',
                value: filterParams.inspectionResultSuccessful,
            },
            {
                accessor: 'inspectionResultUnsuccessful',
                value: filterParams.inspectionResultUnsuccessful,
            },
            {
                accessor: 'inspectionResultPending',
                value: filterParams.inspectionResultPending,
            },
            { accessor: 'assessmentType', value: filterParams.assessmentType },
            {
                accessor: 'assessmentStatus',
                value: filterParams.assessmentStatus,
            },
            {
                accessor: 'assessmentPackage',
                value: filterParams.assessmentPackage,
            },
        ]
        setFilters(filters)
        const {
            companyId,
            customerId,
            contractId,
            serviceId,
            inspectedId,
            inspectionDateFrom,
            inspectionDateUntil,
            assessmentType,
            assessmentPackage,
        } = filterParams
        fetchHistoryInspectionFilters(
            companyId,
            customerId,
            contractId,
            serviceId,
            inspectedId,
            formatDateDb(inspectionDateFrom),
            formatDateDb(inspectionDateUntil),
            assessmentType,
            assessmentPackage
        )
    }

    const clearFilters = () => {
        setFilterParams({ ...defaultState })
    }

    const companiesMapped = parseOptionsListIdNameToReactSelect(companies)
    const inspectorsMapped = parseOptionsListIdNameToReactSelect(inspectors)
    const assessmentPackagesMapped =
        parseOptionsListIdNameToReactSelect(packages)
    const assessmentTypesMapped = [
        {
            label: translateWords(inspectionAssessmentTypes.OPERATIONAL),
            value: inspectionAssessmentTypes.OPERATIONAL,
        },
        {
            label: translateWords(inspectionAssessmentTypes.STRUCTURE),
            value: inspectionAssessmentTypes.STRUCTURE,
        },
    ]

    const assessmentStatusMapped = [
        {
            label: translateWords(inspectionStatusTypes.PENDING),
            value: inspectionStatusTypes.PENDING,
        },
        {
            label: translateWords(inspectionStatusTypes.SAVED),
            value: inspectionStatusTypes.SAVED,
        },
        {
            label: translateWords(inspectionStatusTypes.FINISHED),
            value: inspectionStatusTypes.FINISHED,
        },
        {
            label: translateWords(inspectionStatusTypes.DISCARDED),
            value: inspectionStatusTypes.DISCARDED,
        },
        {
            label: translateWords(inspectionStatusTypes.AWAITING_SIGNATURE),
            value: inspectionStatusTypes.AWAITING_SIGNATURE,
        },
    ]

    return (
        <FormWithoutSection
            onSubmit={(e) => {
                e.preventDefault()
                onSubmitFilters()
            }}
            autoComplete='off'
            title='Filtrar colabrodar'
        >
            <ul className='form__body'>
                <li className='form__line'>
                    <FormHtml5Select
                        id={'assessmentTypes'}
                        name={'assessmentTypes'}
                        label={'Tipo de evaluación'}
                        value={filterParams.assessmentType}
                        required={false}
                        options={assessmentTypesMapped}
                        onChangeCallback={(newPackageTyepSelectedValue) => {
                            if (newPackageTyepSelectedValue) {
                                updateFilterParam(
                                    'assessmentType',
                                    newPackageTyepSelectedValue.value
                                )
                            } else {
                                updateFilterParam('assessmentType', null)
                            }
                        }}
                    />
                </li>
                <li className='form__line'>
                    <FormHtml5Select
                        id={'assessmentPackages'}
                        name={'assessmentPackages'}
                        label={'Paquete evaluado'}
                        value={filterParams.assessmentPackage}
                        required={false}
                        options={assessmentPackagesMapped}
                        onChangeCallback={(newPackageSelectedValue) => {
                            if (newPackageSelectedValue) {
                                updateFilterParam(
                                    'assessmentPackage',
                                    newPackageSelectedValue.value
                                )
                            } else {
                                updateFilterParam('assessmentPackage', null)
                            }
                        }}
                    />
                </li>
                <li className='form__line'>
                    <FormHtml5Select
                        id={'assessmentStatus'}
                        name={'assessmentStatus'}
                        label={'Estado de evaluación'}
                        value={filterParams.assessmentStatus}
                        required={false}
                        options={assessmentStatusMapped}
                        onChangeCallback={(
                            newAssessmentStatusSelectedValue
                        ) => {
                            if (newAssessmentStatusSelectedValue) {
                                updateFilterParam(
                                    'assessmentStatus',
                                    newAssessmentStatusSelectedValue.value
                                )
                            } else {
                                updateFilterParam('assessmentStatus', null)
                            }
                        }}
                    />
                </li>

                <li className='form__line'>
                    <div className='form__group'>
                        <label className='label__input'>Fecha desde</label>

                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            locale='es'
                            selected={filterParams.inspectionDateFrom}
                            selectsStart={true}
                            startDate={filterParams.inspectionDateFrom}
                            endDate={filterParams.inspectionDateUntil}
                            maxDate={new Date()}
                            onChange={(selectedOption) =>
                                updateFilterParam(
                                    'inspectionDateFrom',
                                    selectedOption
                                )
                            }
                            // onFocus={() => this.setState({ endDate: null })}
                            autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            customInput={<DateInputButton />}
                        />
                    </div>

                    <div className='form__group'>
                        <label className='label__input'>Fecha hasta</label>
                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            locale='es'
                            selected={filterParams.inspectionDateUntil}
                            selectsEnd
                            startDate={filterParams.inspectionDateFrom}
                            endDate={filterParams.inspectionDateUntil}
                            minDate={filterParams.inspectionDateFrom}
                            // maxDate={
                            //     moment() < moment(this.state.startDate).add(3, 'month') ? moment() : moment(this.state.startDate).add(3, 'month')
                            // }
                            onChange={(selectedOption) =>
                                updateFilterParam(
                                    'inspectionDateUntil',
                                    selectedOption
                                )
                            }
                            autoComplete='off'
                            onKeyDown={(e) => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            customInput={<DateInputButton />}
                        />
                        <span className='focus-border' />
                    </div>
                </li>

                <li className='form__line'>
                    <FormHtml5Select
                        label='Empresa'
                        options={companiesMapped}
                        value={filterParams.companyId}
                        onChangeCallback={(newCompanySelectedValue) => {
                            if (newCompanySelectedValue) {
                                updateFilterParam(
                                    'companyId',
                                    newCompanySelectedValue.value
                                )
                            } else {
                                clearCustomerLiveSearchResults()
                                resetCompanyCustomerContractAndService()
                            }
                        }}
                    />
                </li>

                {filterParams.companyId && (
                    <li className='form__line'>
                        <FormHtml5SelectLiveSearch
                            label='Cliente'
                            isLoading={isLoadingCustomersFilters}
                            options={customerOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            valueKeyName={'id'}
                            labelKeyName={'name'}
                            value={filterParams.customerId}
                            onFilter={(filterValue) => {
                                return fetchCustomersLiveSearchFilter(
                                    filterParams.companyId,
                                    filterValue
                                )
                            }}
                            onChangeCallback={(newCustomerValue) => {
                                if (newCustomerValue) {
                                    updateFilterParam(
                                        'customerId',
                                        newCustomerValue.id
                                    )
                                } else {
                                    resetCustomerContractAndService()
                                }
                                clearCustomerLiveSearchResults()
                            }}
                        />
                    </li>
                )}

                {filterParams.companyId && filterParams.customerId && (
                    <li className='form__line'>
                        <FormHtml5SelectLiveSearch
                            label='Contrato'
                            isLoading={isLoadingCustomerContractsFilters}
                            options={customerContractsOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            valueKeyName={'id'}
                            labelKeyName={'name'}
                            fetchOptionsOnStart={true}
                            value={filterParams.customerId}
                            onFilter={(filterValue) => {
                                return fetchCustomerContractsLiveSearchFilter(
                                    filterParams.companyId,
                                    filterParams.customerId,
                                    filterValue
                                )
                            }}
                            onChangeCallback={(newContractValue) => {
                                if (newContractValue) {
                                    updateFilterParam(
                                        'contractId',
                                        newContractValue.id
                                    )
                                    fetchContractsServicesLiveSearchFilter(
                                        newContractValue.id,
                                        null
                                    )
                                } else {
                                    resetContractAndService()
                                }
                            }}
                        />
                    </li>
                )}
                {filterParams.companyId && filterParams.customerId && (
                    <li className='form__line'>
                        <FormHtml5Select
                            label='Delegación'
                            options={delegationOptions}
                            value={filterParams.delegationId}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            valueKeyName={'id'}
                            labelKeyName={'name'}
                            onChangeCallback={(newCompanySelectedValue) => {
                                if (newCompanySelectedValue) {
                                    updateFilterParam(
                                        'delegationId',
                                        newCompanySelectedValue.id
                                    )
                                } else {
                                    updateFilterParam('delegationId', null)
                                }
                            }}
                        />
                    </li>
                )}
                {filterParams.companyId &&
                    filterParams.customerId &&
                    filterParams.contractId && (
                        <li className='form__line'>
                            <FormHtml5SelectLiveSearch
                                label='Servicio'
                                options={customerContractServicesOptions}
                                isLoading={
                                    isLoadingCustomerContractServicesFilters
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionKey={(option) => option.id}
                                fetchOptionsOnStart={true}
                                value={filterParams.serviceId}
                                onFilter={(filterValue) => {
                                    return fetchContractsServicesLiveSearchFilter(
                                        filterParams.contractId,
                                        filterValue
                                    )
                                }}
                                onChangeCallback={(newInspectedValue) => {
                                    if (newInspectedValue) {
                                        updateFilterParam(
                                            'serviceId',
                                            newInspectedValue.id
                                        )
                                    } else {
                                        updateFilterParam('serviceId', null)
                                    }
                                }}
                            />
                        </li>
                    )}

                <li className='form__line'>
                    <FormHtml5Select
                        label='Responsable'
                        options={inspectorsMapped}
                        value={filterParams.inspectorId}
                        onChangeCallback={(newInspectorSelectedValue) => {
                            if (newInspectorSelectedValue) {
                                updateFilterParam(
                                    'inspectorId',
                                    newInspectorSelectedValue.value
                                )
                            } else {
                                updateFilterParam('inspectorId', null)
                            }
                        }}
                    />
                </li>

                <li className='form__line'>
                    <FormHtml5SelectLiveSearch
                        label='Colaborador'
                        options={watchmenOptions}
                        isLoading={isLoadingWatchmenFilters}
                        value={filterParams.inspectedId}
                        getOptionLabel={(option) => option.name}
                        getOptionKey={(option) => option.id}
                        onFilter={(filterValue) => {
                            return fetchWatchmenLiveSearchFilter(filterValue)
                        }}
                        onChangeCallback={(newInspectedValue) => {
                            if (newInspectedValue) {
                                updateFilterParam(
                                    'inspectedId',
                                    newInspectedValue.id
                                )
                            } else {
                                updateFilterParam('inspectedId', null)
                            }
                        }}
                    />
                </li>

                <li className='form__line--column'>
                    <label className='label__input'>
                        Resultado de evaluación:
                    </label>
                    <div className='form__line_body'>
                        <Checkbox
                            label='Satisfactoria'
                            name='inspectionSuccessful'
                            value={filterParams.inspectionResultSuccessful}
                            defaultChecked={true}
                            onChecked={() => {
                                updateFilterParam(
                                    'inspectionResultSuccessful',
                                    true
                                )
                            }}
                            onNoChecked={() => {
                                updateFilterParam(
                                    'inspectionResultSuccessful',
                                    false
                                )
                            }}
                        />
                        <Checkbox
                            label='No satisfactoria'
                            name='inspectionUnsuccessful'
                            value={filterParams.inspectionResultUnsuccessful}
                            defaultChecked={true}
                            onChecked={() => {
                                updateFilterParam(
                                    'inspectionResultUnsuccessful',
                                    true
                                )
                            }}
                            onNoChecked={() => {
                                updateFilterParam(
                                    'inspectionResultUnsuccessful',
                                    false
                                )
                            }}
                        />
                        <Checkbox
                            label='Pendiente'
                            name='inspectionPending'
                            value={filterParams.inspectionResultPending}
                            defaultChecked={true}
                            onChecked={() => {
                                updateFilterParam(
                                    'inspectionResultPending',
                                    true
                                )
                            }}
                            onNoChecked={() => {
                                updateFilterParam(
                                    'inspectionResultPending',
                                    false
                                )
                            }}
                        />
                    </div>
                </li>

                <Button type='submit' disabled={!hasSomeFilterParam()}>
                    Buscar evaluaciones
                </Button>
                {filterParams && <CsvExport filterParams={filterParams} />}
                <Button type='cancel' onClick={clearFilters}>
                    Limpiar filtros
                </Button>
            </ul>
        </FormWithoutSection>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InspectionsHistoryFilter)
