import { actionsTypes as actionsTypesAssessmentPackages } from './actions'

const initState = {
    loading: false,
    assessmentPackages: [],
    failureMessage: null
}

const assessmentPackages = (state = initState, action) => {
    switch (action.type) {
        case actionsTypesAssessmentPackages.FETCH_ASSESSMENT_PACKAGES_REQUEST:
            return { ...state, loading: true }
        case actionsTypesAssessmentPackages.FETCH_ASSESSMENT_PACKAGES_SUCCESS:
            return { ...state, loading: false, assessmentPackages: action.assessmentPackages }
        case actionsTypesAssessmentPackages.FETCH_ASSESSMENT_PACKAGES_FAILURE:
            return { ...state, loading: false }
        default:
            return state
    }
}

export const getIsLoadingAssessmentPackages = state => {
    return state.assessmentPackages.loading
}

export const getAssessmentPackages = state => {
    return state.assessmentPackages.assessmentPackages
}

export default assessmentPackages
