import { fetchGet, runPost } from '../helperApi'

export const createInspectionApi = (body) => {
    return runPost(`inspections/create`, {
        ...body,
    })
}

export const updateInspectionSelectors = (inspectionId, body) => {
    return runPost(`inspections/${inspectionId}/update-selectors`, {
        ...body,
    })
}

export const saveSignature = (inspectionId, signatureType, body) => {
    return runPost(`inspections/${inspectionId}/${signatureType}-signature`, body)
}

export const finishInspection = (
    inspectionId,
    inspectionBlocksScores,
    observations,
    showImagesInReport,
    inspectionTicket,
    roleIdPoseidon,
    workerIdPoseidon,
    workerNamePoseidon,
    endLatitude,
    endLongitude
) => {
    const body = {
        blocksResponse: inspectionBlocksScores,
        observations,
        showImagesInReport,
        tasks: inspectionTicket,
        roleIdPoseidon: roleIdPoseidon,
        workerIdPoseidon: workerIdPoseidon,
        workerNamePoseidon: workerNamePoseidon,
        endLatitude: endLatitude,
        endLongitude: endLongitude,
        userAgent: navigator.userAgent
    }

    return runPost(`inspections/${inspectionId}/finish`, body)
}

export const saveInspection = (
    inspectionId,
    inspectionBlocksScores,
    observations,
    showImagesInReport,
    inspectionTicket,
    roleIdPoseidon,
    workerIdPoseidon,
    workerNamePoseidon
) => {
    const body = {
        blocksResponse: inspectionBlocksScores,
        observations,
        showImagesInReport,
        tasks: inspectionTicket,
        roleIdPoseidon: roleIdPoseidon,
        workerIdPoseidon: workerIdPoseidon,
        workerNamePoseidon: workerNamePoseidon,
    }

    return runPost(`inspections/${inspectionId}/save`, body)
}

export const sendInspectionReport = (inspectionId, emails) => {
    const body = {
        emails,
    }
    return runPost(`inspections/${inspectionId}/report`, body)
}

export const discardInspection = (inspectionId) => {
    return runPost(`inspections/${inspectionId}/discard`)
}

export const getInspectionData = (inspectionId) => {
    return fetchGet(`inspection/${inspectionId}`)
}

export const submitInspection = (
    inspectionId,
    inspectionBlocksScores,
    observations,
    showImagesInReport,
    inspectionTicket,
    roleIdPoseidon,
    workerIdPoseidon,
    workerNamePoseidon
) => {
    const body = {
        blocksResponse: inspectionBlocksScores,
        observations,
        showImagesInReport,
        tasks: inspectionTicket,
        roleIdPoseidon: roleIdPoseidon,
        workerIdPoseidon: workerIdPoseidon,
        workerNamePoseidon: workerNamePoseidon,
    }
    return runPost(`inspections/${inspectionId}/submit`, body)
}

export const fetchWorkerDataApi = inspectionId => {
    return fetchGet(`inspections/packages-worker/${inspectionId}`)
}

export const saveInspectionBlockScores = (inspectionId, inspectionBlocksScores) => {
    return runPost(`inspections/${inspectionId}/save-block-scores`, {
        blocksResponse: inspectionBlocksScores,
    })
}
