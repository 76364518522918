import React from 'react'
import FormWithoutSection from '../../shared/FormWithoutSection'
import Button from '../../shared/Button'
import InputTextHTML5 from '../../shared/Form/InputTextHTML5'
import InputRadioGroup from '../../shared/Form/InputRadioGroup'
import FormHtml5Select from '../../shared/Form/html5/FormHtml5Select'
import { parseOptionsListIdNameToReactSelect } from '../../shared/Helper'
import { inspectionAssessmentTypes } from '../../store/inspection/reducers'
import { translateWords } from '../../Messages'

export default class AssessmentPackageGeneralTab extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const {
            errors,
            packageData,
            companies,
            packageCompanies,
            assessmentPackageTypes,
            packageId,
            onHandleChangePackageCompanyInput,
            onHandleChangeAssessmentPackageTypeInput,
            onHandleChangePackageDataInput,
            onSubmitPackageData,
            onHandleDeletePackage,
        } = this.props

        const companiesMapped = parseOptionsListIdNameToReactSelect(companies)
        const assessmentPackageTypesMapped = [
            { label: translateWords(inspectionAssessmentTypes.OPERATIONAL), value: inspectionAssessmentTypes.OPERATIONAL },
            { label: translateWords(inspectionAssessmentTypes.STRUCTURE), value: inspectionAssessmentTypes.STRUCTURE },
        ]
        return (
            <FormWithoutSection
                onSubmit={(e) => {
                    e.preventDefault()
                    onSubmitPackageData()
                }}
                autoComplete="off"
                title="Nuevo Paquete"
            >
                <ul className="form__body">
                    <li className="form__line">
                        <InputTextHTML5
                            type="text"
                            label="Título"
                            required={true}
                            errors={errors}
                            placeholder="Título del paquete"
                            id="packageName"
                            name="packageName"
                            defaultValue={packageData.title}
                            max="255"
                            onChange={(newTitleValue) => {
                                onHandleChangePackageDataInput('title', newTitleValue)
                            }}
                        />
                    </li>

                    <li className="form__line">
                        <InputRadioGroup
                            label="Habilitado"
                            errors={errors}
                            required={true}
                            name="packageEnabled"
                            value={packageData.enabled}
                            options={[
                                { label: 'Sí', value: true },
                                { label: 'No', value: false },
                            ]}
                            onChange={(newOptionsChecked) => {
                                const parseValueToBoolean = JSON.parse(newOptionsChecked)
                                onHandleChangePackageDataInput('enabled', parseValueToBoolean)
                            }}
                        />
                    </li>
                    <li className="form__line">
                        <InputRadioGroup
                            label="Mostrar observaciones y firma del colaborador"
                            errors={errors}
                            required={true}
                            name="showColaboratorInputs"
                            value={packageData.showColaboratorInputs}
                            options={[
                                { label: 'Sí', value: true },
                                { label: 'No', value: false },
                            ]}
                            onChange={(newOptionsChecked) => {
                                const parseValueToBoolean = JSON.parse(newOptionsChecked)
                                onHandleChangePackageDataInput('showColaboratorInputs', parseValueToBoolean)
                            }}
                        />
                    </li>

                    <li className="form__line">
                        <FormHtml5Select
                            id={'companies'}
                            name={'companies'}
                            label={'Empresas'}
                            value={packageCompanies}
                            errors={errors}
                            required={true}
                            options={companiesMapped}
                            isMulti={true}
                            simpleValue={true}
                            onChange={(newCompanySelected) => {
                                let companiesSelected = null

                                if (newCompanySelected) {
                                    companiesSelected = newCompanySelected.map((company) => company.value)
                                }

                                onHandleChangePackageCompanyInput(companiesSelected)
                            }}
                        />
                    </li>

                    <li className="form__line">
                        <FormHtml5Select
                            id={'assessmentPackageTypes'}
                            name={'assessmentPackageTypes'}
                            label={'Tipo/s de evaluación'}
                            value={assessmentPackageTypes}
                            errors={errors}
                            required={true}
                            options={assessmentPackageTypesMapped}
                            isMulti={true}
                            simpleValue={true}
                            onChange={(newAssesmentPackageTypesSelected) => {
                                let assesmentPackageTypesSelected = null

                                if (newAssesmentPackageTypesSelected) {
                                    assesmentPackageTypesSelected = newAssesmentPackageTypesSelected.map((packageType) => packageType.value)
                                }

                                onHandleChangeAssessmentPackageTypeInput(assesmentPackageTypesSelected)
                            }}
                        />
                    </li>

                    <li className="form__line">
                        <Button type="submit">Guardar</Button>
                        {packageId && (
                            <Button
                                type="cancel"
                                onClick={(e) => {
                                    e.preventDefault()
                                    onHandleDeletePackage(packageId)
                                }}
                            >
                                Eliminar paquete
                            </Button>
                        )}
                    </li>
                </ul>
            </FormWithoutSection>
        )
    }
}
